import { useLocation, useParams } from 'react-router-dom';
import { Box, Grid, IconButton, Paper, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Chart from '../components/Charts/GraphChart';
import ProcedureStream from '../components/stream/ProcedureStream';
import Forbidden from '../components/Login/Forbidden';
import AvgVMCard from '../components/report/AvgVMCard';
import BucketDownloadButton from '../components/report/BucketDownloadButton';
import CommentSection from '../components/comments/CommentSection';
import FrameGrid from '../components/Charts/timeline/FrameGrid';
import ProcedureLoading from '../components/report/ProcedureLoading';
import useFrames from '../components/report/useFrames';
import ProcedureCard from '../components/report/ProcedureCard';
import ProcedureTitle from '../components/report/ProcedureTitle';
import DetectionStatusStats from '../components/report/DetectionStatusStats';
import useDebugOptions from '../components/report/useDebugOptions';
import { types } from '../components/summary/hooks/useSummaryOptions';
import CaddieStream from '../components/stream/CaddieStream';
import PaperFloatingCard from '../components/report/PaperFloatingCard';
import PaperCard from '../components/report/PaperCard';
import RequestDiagnosisButton from '../components/mouse/RequestDiagnosisButton';
import useMouse from '../components/mouse/useMouse';
import useAuthorized from '../aceapi/hooks/useAuthorized';
import useProcTime from '../aceapi/hooks/useProcTime';
import useShow from '../aceapi/hooks/useShow';
import CaecumCard from '../components/report/CaecumCard';
import useProcOngoing from '../aceapi/hooks/useProcOngoing';
import EventsCard from '../components/report/EventsCard';
import useVideoSeeker from '../components/stream/useVideoSeeker';
import EventTimeline from '../components/Charts/EventTimeline';
import CollapsibleStack from '../components/report/CollapsibleStack';
import ReportPaper from '../components/report/ReportPaper';
import ExtraLegendCard from '../components/report/ExtraLegendCard';
import { TokenContext } from '../config/contexts';
import ReportTimeline from '../components/Charts/ReportTimeline';
import ClientLogsCard from '../components/report/ClientLogsCard';
import PolypStats from '../components/report/PolypStats';
import useVideoKeyNav from '../components/shortcuts/useVideoKeyNav';
import ShortcutCard from '../components/shortcuts/ShortcutCard';
import CatUploadCard from '../components/Annotations/CatUploadCard';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import { useProceduresRead } from '../aceapi/aceComponents';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import VideoUploadProgressCard from '../components/stream/VideoUploadProgressCard';
import FalseAlarmCard from '../components/report/FalseAlarmCard';
import AnnotationXMLDownload from '../components/celery/AnnotationXMLDownload';

export default function ProcedureDetail(props) {
    const authorized = useAuthorized();
    const { app } = useAceApp();
    const { uuid } = useParams();
    const { pathname, hash, key } = useLocation();

    const { data: procedureReadData, isSuccess: isProcedureReadSuccess } = useProceduresRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { suspense: false },
    );
    const product = isProcedureReadSuccess ? procedureReadData.product?.toUpperCase() : null;

    useEffect(() => {
        if (hash === '') window.scrollTo(0, 0);
        else
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element)
                    element.scrollIntoView({
                        behavior: 'auto',
                        block: 'center',
                        inline: 'center',
                    });
            }, 0);
    }, [pathname, hash, key]);

    const { pin } = props;

    const token = useContext(TokenContext);
    const user = token.parse().user;
    const isStaff = user.is_active && user.is_staff;
    const userInfo = { username: user.username, isStaff };

    const show = useShow();

    const [unavailable, setUnavailable] = useState({});
    const [toggleBars, setToggleBars] = useState(true);
    const [hideUncertain, setHideUncertain] = useState(show.hide_uncertain);
    const [loading, setLoading] = useState({
        timeline: show.timeline,
        visible_mucosa: show.visible_mucosa && product === 'CADDIE',
        network_level: show.network_level,
    });
    const [update, setUpdate] = useState(false);
    const seeker = useVideoSeeker();
    useVideoKeyNav(seeker);
    const mouse = useMouse({ timestamp: seeker.timestamp.state });

    const loadedCallback = (name) => () =>
        loading[name] && setLoading((prevState) => ({ ...prevState, [name]: false }));

    const time = useProcTime(app, uuid, false);

    const debug = useDebugOptions(show.debug, [
        { key: 'rawVM', type: types.SWITCH, name: 'Raw VM data points', defaultValue: false },
        { key: 'rawNet', type: types.SWITCH, name: 'Raw Net data points', defaultValue: false },
        { key: 'graphDots', type: types.SWITCH, name: 'Show dots', defaultValue: false },
        { key: 'showLostBoxes', type: types.SWITCH, name: 'Show lost boxes', defaultValue: false },
        { key: 'debugCaecum', type: types.SWITCH, name: 'Debug Caecum', defaultValue: false },
        { key: 'showFreezeFrames', type: types.SWITCH, name: 'Show freeze frames', defaultValue: false },
        {
            key: 'vmAgg',
            type: types.STRING_SELECTOR,
            name: '',
            defaultValue: 'mean',
            extraProps: {
                options: [
                    { key: 'min', name: 'min', value: 'min' },
                    { key: 'mean', name: 'mean', value: 'mean' },
                    { key: 'max', name: 'max', value: 'max' },
                    { key: 'median', name: 'median', value: 'median' },
                    { key: 'sum', name: 'sum', value: 'sum' },
                    { key: 'std', name: 'std', value: 'std' },
                    { key: 'var', name: 'var', value: 'var' },
                ],
                extraInputProps: { label: 'VM resample aggregate func' },
            },
        },
        {
            key: 'netAgg',
            type: types.STRING_SELECTOR,
            name: '',
            defaultValue: 'min',
            extraProps: {
                options: [
                    { key: 'min', name: 'min', value: 'min' },
                    { key: 'mean', name: 'mean', value: 'mean' },
                    { key: 'max', name: 'max', value: 'max' },
                    { key: 'median', name: 'median', value: 'median' },
                    { key: 'sum', name: 'sum', value: 'sum' },
                    { key: 'std', name: 'std', value: 'std' },
                    { key: 'var', name: 'var', value: 'var' },
                ],
                extraInputProps: { label: 'Network resample aggregate func' },
            },
        },
    ]);

    const { streaming } = useProcOngoing();

    useEffect(() => {
        setUpdate(Object.values(loading).some((x) => x));
    }, [loading]);

    const frameProps = useFrames({
        uuid,
        setUnavailable,
        authorized,
        hideUncertain,
    });

    useEffect(() => {
        if (mouse.mode.state === 'draw')
            document.getElementById('procedure-video').scrollIntoView({ behavior: 'smooth' });
    }, [mouse.mode.state]);

    const [zoomedStream, setZoomedStream] = useState(false);

    return (
        <>
            {!authorized ? (
                <Forbidden />
            ) : (
                <Grid container spacing={2}>
                    {update && <ProcedureLoading update={update} />}
                    {time?.status === 'STARTED' && show.stream && (
                        <PaperFloatingCard
                            top='5rem'
                            right='1rem'
                            height={zoomedStream ? 'calc(100vh - 10rem)' : '16rem'}
                            width={zoomedStream ? 'calc(100vh - 10rem)' : '16rem'}
                        >
                            <CaddieStream maxHeight={zoomedStream ? 'calc(100vh - 12rem)' : '14rem'} />
                            <IconButton
                                variant='contained'
                                onClick={() => setZoomedStream((prevState) => !prevState)}
                                sx={{ position: 'absolute', bottom: -8, left: -8, zIndex: 2 }}
                            >
                                <Tooltip title={zoomedStream ? 'Minimize' : 'Maximize'}>
                                    {zoomedStream ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                                </Tooltip>
                            </IconButton>
                        </PaperFloatingCard>
                    )}
                    <ProcedureTitle pin={pin} />
                    <CollapsibleStack collapsibleSize={3.5}>
                        {show.video && (
                            <ProcedureStream
                                seeker={seeker}
                                setUnavailable={setUnavailable}
                                showLostBoxes={debug.state.showLostBoxes}
                                mouse={mouse}
                                showNavButtons
                            />
                        )}
                        {show.timeline && (
                            <ReportPaper p={0}>
                                <EventTimeline
                                    frames={frameProps.frames}
                                    maxHeight='35.75rem'
                                    seeker={seeker}
                                    startExpanded
                                    fixedState
                                />
                            </ReportPaper>
                        )}
                    </CollapsibleStack>
                    <Grid item xs={12}>
                        {/*TODO: remove hardcoded width*/}
                        <Paper sx={{ p: 2, margin: 'auto', flexGrow: 1 }}>
                            {show.timeline && (
                                <Box>
                                    <ReportTimeline
                                        setUnavailable={setUnavailable}
                                        loaded={loadedCallback('timeline')}
                                        seeker={seeker}
                                        toggleBars={toggleBars}
                                        stepGrowing={streaming}
                                        debugCaecum={debug.state.debugCaecum}
                                        showFreezeFrames={debug.state.showFreezeFrames}
                                        {...frameProps}
                                    />
                                    <FrameGrid {...frameProps} style={{ marginTop: 10, paddingLeft: 100 }} />
                                </Box>
                            )}
                            {show.visible_mucosa && product === 'CADDIE' && (
                                <Chart
                                    name='Visible Mucosa'
                                    plot='visible_mucosa'
                                    sampling={100}
                                    aggregate={debug.state.vmAgg}
                                    setUnavailable={setUnavailable}
                                    loaded={loadedCallback('visible_mucosa')}
                                    showCaecum
                                    debug={debug.state.rawVM}
                                    dot={debug.state.graphDots}
                                    unit='%'
                                    stream={streaming}
                                />
                            )}
                            {show.network_level && (
                                <Chart
                                    name='Network Quality'
                                    plot='network_level'
                                    sampling={500}
                                    aggregate={debug.state.netAgg}
                                    setUnavailable={setUnavailable}
                                    loaded={loadedCallback('network_level')}
                                    debug={debug.state.rawNet}
                                    dot={debug.state.graphDots}
                                    showLostBoxes={debug.state.showLostBoxes}
                                    stream={streaming}
                                />
                            )}
                        </Paper>
                    </Grid>
                    {show.procedure_card && <ProcedureCard unavailable={unavailable} />}
                    {show.video && 'No video' in unavailable && !time.ongoing && <VideoUploadProgressCard />}
                    {show.timeline && product === 'CADDIE' && <ExtraLegendCard />}
                    {show.caecum_card && product === 'CADDIE' && <CaecumCard />}
                    {show.vm_card && product === 'CADDIE' && <AvgVMCard uuid={uuid} />}
                    {show.polyp_stats && product === 'CADDIE' && <PolypStats uuid={uuid} />}
                    {show.detection_stats && product === 'CADDIE' && <DetectionStatusStats uuid={uuid} />}
                    {show.option_card && (
                        <PaperCard title='Options'>
                            <Typography align='left'>
                                <Switch checked={toggleBars} onChange={(e) => setToggleBars(e.target.checked)} /> show
                                diagnosis bars
                            </Typography>
                            <Typography align='left'>
                                <Switch checked={hideUncertain} onChange={(e) => setHideUncertain(e.target.checked)} />{' '}
                                hide uncertain diagnoses
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item>
                                    {seeker.timestamp.state > 0 && <RequestDiagnosisButton mouse={mouse} />}
                                </Grid>
                            </Grid>
                        </PaperCard>
                    )}
                    {show.debug && <PaperCard title='Debug'>{debug.render()}</PaperCard>}
                    {show.debug && <FalseAlarmCard />}
                    {show.client_logs && <ClientLogsCard />}
                    <ShortcutCard showAnnotation={false} xs={8} />
                    {show.events_card && <EventsCard />}
                    {show.cat_upload && <CatUploadCard uuid={uuid} />}
                    {show.download && (
                        <PaperCard title='Download data'>
                            <Stack spacing={2} direction='row'>
                                <AnnotationXMLDownload />
                                {show.deprecated && (
                                    <BucketDownloadButton resourceID={0} uuid={uuid} filename='annotation_video.xml'>
                                        Annotation Video XML
                                    </BucketDownloadButton>
                                )}
                                <BucketDownloadButton resourceID={1} uuid={uuid} filename='metadata'>
                                    Metadata
                                </BucketDownloadButton>
                            </Stack>
                        </PaperCard>
                    )}
                    {show.comments && (
                        <PaperCard xs={12} title='Comments' align='center'>
                            <CommentSection {...userInfo} />
                        </PaperCard>
                    )}
                </Grid>
            )}
        </>
    );
}
