import { useState } from 'react';
import { Box, Checkbox, Chip, MenuItem, TextField } from '@mui/material';

export default function StringMultiSelector({ onChange, options, value }) {
    const [curOptions, setCurOptions] = useState(value.map((x) => options.find((y) => y.value === x).key));

    const handleChange = (e) => {
        const val = e.target.value;
        setCurOptions(val);
        return onChange(options.filter((x) => val.includes(x.key)).map((x) => x.value));
    };

    return (
        <>
            <TextField
                select
                SelectProps={{
                    multiple: true,
                    value: curOptions,
                    onChange: handleChange,
                    renderValue: (selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected
                                .map((key) => options.find((x) => x.key === key).name)
                                .map((name) => (
                                    <Chip key={name} label={name} />
                                ))}
                        </Box>
                    ),
                }}
                sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minWidth: '25ch' }}
            >
                {options.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        <Checkbox checked={curOptions.indexOf(option.key) > -1} />
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
}
