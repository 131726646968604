import { useEffect, useMemo, useState } from 'react';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useFramesRead, useProceduresStatus } from '../../aceapi/aceComponents';

export default function useFrames({ uuid, setUnavailable, authorized, hideUncertain = false }) {
    const { app } = useAceApp();
    const [frames, setFrames] = useState();
    const [hoveredFrame, setHoveredFrame] = useState(null);
    const [ctrlClickedFrame, setCtrlClickedFrame] = useState(null);

    const { data: framesRaw, error: frameError } = useFramesRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        {
            suspense: false,
            enabled: authorized,
        },
    );
    const { data: detStat, error: detError } = useProceduresStatus(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        {
            suspense: false,
            enabled: authorized,
        },
    );
    const error = frameError || detError;

    useEffect(() => {
        if (!Array.isArray(frames) || ctrlClickedFrame === null) return;
        const newFrames = [...frames];
        const toToggleFrame = newFrames.find((x) => x.frame_id === ctrlClickedFrame.frame_id);
        toToggleFrame.selected = !toToggleFrame.selected;
        setFrames(newFrames);
        setCtrlClickedFrame(null);
    }, [ctrlClickedFrame, frames]);

    useEffect(() => {
        if (setFrames && detStat && framesRaw?.frames) {
            // Slicing to copy the array since reverse is destructive
            const rev_stat = detStat.slice(0).reverse();
            let frames = framesRaw.frames;
            frames = frames
                .filter((frame) => {
                    /* Checking the status array in reverse allows to find the latest element of that
                array where the frame's timestamp is bigger than the status switch timestamp, so
                effectively finding the detection status period the frame belongs to, then we just
                have to retrieve if that was ON or OFF. If the frame is out of bound of these status
                changes or there are no status changes at all, it defaults to ON. */
                    return (
                        (rev_stat.find((s) => frame.timestamp > s.timestamp)?.attributes?.[0]?.value ?? 'ON') === 'ON'
                    );
                })
                .map((frame) => {
                    return { ...frame, selected: false };
                });
            if (frames.length === 0)
                setUnavailable((prevState) => ({ ...prevState, 'No polyp frames': 'no diagnoses recorded' }));
            if (hideUncertain) frames = frames.filter((x) => x.diagnosis.toLowerCase() !== 'uncertain');
            setFrames(frames);
        }
    }, [detStat, framesRaw?.frames, hideUncertain, setFrames, setUnavailable]);

    return useMemo(
        () => ({
            frames,
            error,
            hoveredFrame: {
                state: hoveredFrame,
                setState: setHoveredFrame,
            },
            ctrlClickedFrame: {
                state: ctrlClickedFrame,
                setState: setCtrlClickedFrame,
            },
        }),
        [frames, error, hoveredFrame, ctrlClickedFrame],
    );
}
