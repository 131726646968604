import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from 'react';
import { all, create } from 'mathjs';
import { useEditsTimelineList, useExtraModelsList } from '../../aceapi/aceComponents';

const config = {};
const math = create(all, config);

const failIsNull = (f) => {
    try {
        return f();
    } catch (e) {
        return null;
    }
};

export default function SummaryViewDump(props) {
    const { data, type } = props;

    const { refetch: fetchExtraModels } = useExtraModelsList({}, { enabled: false, suspense: false });
    const { refetch: fetchTimelineEdits } = useEditsTimelineList({}, { enabled: false, suspense: false });

    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
        setLoading(true);

        const [{ data: extraModels }, { data: allEdits }] = await Promise.all([
            fetchExtraModels(),
            fetchTimelineEdits(),
        ]);

        const manualAnns = extraModels.filter((model) => model.manual_annotation).map((model) => model.id);
        const edits = allEdits.filter((edit) => manualAnns.includes(edit.model));

        const newpolyp_id = extraModels.find((model) => model.key === 'newpolyp_annotation')?.id ?? 0;
        const resection_id = extraModels.find((model) => model.key === 'resection_annotation')?.id ?? 0;

        const polypEdits = edits.filter((edit) => edit.model === newpolyp_id);
        const resectionEdits = edits.filter((edit) => edit.model === resection_id);

        const processed = data.map((row) => {
            const stats = row.procs
                .filter((proc) => edits.map((x) => x.procedure_id).includes(proc.procedure_id))
                .map((proc) => {
                    const peds = polypEdits.filter((edit) => edit.procedure_id === proc.procedure_id);
                    const reds = resectionEdits.filter((edit) => edit.procedure_id === proc.procedure_id);
                    return {
                        procedure_id: proc.procedure_id,
                        polyps: peds.length,
                        resections: reds.length,
                        ratio: reds.length / peds.length,
                    };
                });
            const annotatedCount = stats.length;
            const noPolypsCount = stats.filter((stat) => stat.polyps === 0).length;
            const noResectionsCount = stats.filter((stat) => stat.resections === 0).length;
            const polyps = stats.map((stat) => stat.polyps);
            const resections = stats.map((stat) => stat.resections);
            const ratios = stats.map((stat) => stat.ratio).filter((x) => !isNaN(x));

            return {
                group_name: row.name,
                group_type: row.type,
                group_user_count: row.users.length,
                group_short_name: row.short,
                procedure_count: row.count,
                total_annotated: annotatedCount,
                total_zero_polyps: noPolypsCount,
                total_zero_resections: noResectionsCount,
                mean_polyps: failIsNull(() => math.mean(polyps).toFixed(2)),
                mean_resections: failIsNull(() => math.mean(resections).toFixed(2)),
                mean_ratio: failIsNull(() => math.mean(ratios).toFixed(2)),
                median_polyps: failIsNull(() => math.median(polyps)),
                median_resections: failIsNull(() => math.median(resections)),
                median_ratio: failIsNull(() => math.median(ratios).toFixed(2)),
                std_polyps: failIsNull(() => math.std(polyps).toFixed(2)),
                std_resections: failIsNull(() => math.std(resections).toFixed(2)),
                std_ratio: failIsNull(() => math.std(ratios).toFixed(2)),
                var_polyps: failIsNull(() => math.variance(polyps).toFixed(2)),
                var_resections: failIsNull(() => math.variance(resections).toFixed(2)),
                var_ratio: failIsNull(() => math.variance(ratios).toFixed(2)),
                min_polyps: failIsNull(() => math.min(polyps)),
                min_resections: failIsNull(() => math.min(resections)),
                min_ratio: failIsNull(() => math.min(ratios).toFixed(2)),
                max_polyps: failIsNull(() => math.max(polyps)),
                max_resections: failIsNull(() => math.max(resections)),
                max_ratio: failIsNull(() => math.max(ratios).toFixed(2)),
                sum_polyps: failIsNull(() => math.sum(polyps)),
                sum_resections: failIsNull(() => math.sum(resections)),
                sum_ratio: failIsNull(() => math.sum(ratios).toFixed(2)),
            };
        });

        const fields = Object.keys(processed?.[0] ?? {});
        const replacer = (key, value) => (value === null ? '' : value);
        let csv = processed.map((row) => fields.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(fields.join(','));
        csv = csv.join('\r\n');
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const date_str = new Date().toISOString().slice(0, 10);
        const hash = (localStorage.getItem('summaryOptionsHash') || '').slice(0, 8);
        link.download = `polyp_stats_per_${type}_${date_str}_${hash}.csv`;
        setLoading(false);
        link.click();
    };

    return (
        <LoadingButton
            variant='outlined'
            color='primary'
            onClick={handleDownload}
            loading={loading}
            startIcon={<DownloadIcon />}
            loadingPosition='start'
        >
            Dump polyp statistics
        </LoadingButton>
    );
}
