const CHART_COLORS = [
    '#e6194B',
    '#3cb44b',
    '#ffe119',
    '#4363d8',
    '#f58231',
    '#911eb4',
    '#42d4f4',
    '#f032e6',
    '#bfef45',
    '#fabed4',
    '#469990',
    '#dcbeff',
    '#9A6324',
    '#837b4b',
    '#800000',
    '#80c595',
    '#808000',
    '#ffd8b1',
    '#000075',
    '#a9a9a9',
];

export function chartColor(index) {
    return CHART_COLORS[index % CHART_COLORS.length];
}
