import useAuthorized from '../aceapi/hooks/useAuthorized';
import Forbidden from '../components/Login/Forbidden';
import Grid from '@mui/material/Grid';
import PaperCard from '../components/report/PaperCard';
import { AsyncBoundary } from '../aceapi/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, MenuItem, Select, Snackbar, Stack, TextField, Typography } from '@mui/material';
import ProcedureTitle from '../components/report/ProcedureTitle';
import { DataGrid } from '@mui/x-data-grid';
import useShow from '../aceapi/hooks/useShow';
import CommentSection from '../components/comments/CommentSection';
import { TokenContext } from '../config/contexts';
import ConfirmButton from '../components/dialogs/ConfirmButton';
import { Alert } from '@mui/lab';
import useHospitalNames from '../components/clinical/useHospitalNames';
import { caseStatuses, normalizeString } from '../components/clinical/utils';
import { useReportAnomalyAnalysis } from '../components/clinical/anomalyDetection';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import {
    useClinicalreportGgpatientList,
    useClinicalreportGgpatientPartialUpdate,
    useCommentsCreate,
    useCommentsDelete,
    useProceduresRead,
} from '../aceapi/aceComponents';
import { useQueryClient } from '@tanstack/react-query';

const ldist = (s, t) => {
    if (!s.length) return t.length;
    if (!t.length) return s.length;
    const arr = [];
    for (let i = 0; i <= t.length; i++) {
        arr[i] = [i];
        for (let j = 1; j <= s.length; j++) {
            arr[i][j] =
                i === 0
                    ? j
                    : Math.min(
                          arr[i - 1][j] + 1,
                          arr[i][j - 1] + 1,
                          arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1),
                      );
        }
    }
    return arr[t.length][s.length];
};

function GGPatientData({ setIssueCount }) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { data: report } = useClinicalreportGgpatientList({ queryParams: { app } });
    const { data: procedure } = useProceduresRead({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });

    const { mutateAsync: partialUpdateReport } = useClinicalreportGgpatientPartialUpdate();
    const { mutateAsync: createComment } = useCommentsCreate();
    const { mutateAsync: deleteComment } = useCommentsDelete();

    const { getHospitalName, getHospitalAcronym } = useHospitalNames();

    const matchStrategies = useMemo(
        () => [
            {
                name: 'Hospital Name',
                score: 50,
                eval: (report) => {
                    const procHospitalName = getHospitalName(procedure.username);
                    if (!procHospitalName) {
                        return 0;
                    }
                    return normalizeString(report.site_name).includes(normalizeString(procHospitalName)) ? 1 : 0;
                },
            },
            {
                name: 'Hospital Acronym',
                score: 25,
                eval: (report) => {
                    const procHospitalAcronym = getHospitalAcronym(procedure.username);
                    if (!procHospitalAcronym) {
                        return 0;
                    }
                    return normalizeString(report.site_name).includes(normalizeString(procHospitalAcronym)) ? 1 : 0;
                },
            },
            {
                name: 'Hospital Name by number of words in common',
                score: 10,
                eval: (report) => {
                    const procHospitalName = getHospitalName(procedure.username);
                    if (!procHospitalName) {
                        return 0;
                    }
                    const procHospitalNameWords = normalizeString(procHospitalName).split(' ');
                    const reportHospitalNameWords = normalizeString(report.site_name).split(' ');
                    const commonWords = procHospitalNameWords.filter((x) => reportHospitalNameWords.includes(x));
                    return commonWords.length / procHospitalNameWords.length;
                },
            },
            {
                name: 'Subject ID',
                score: 50,
                eval: (report) => {
                    const procPatientId = normalizeString(procedure.patient_id ?? '');
                    const reportPatientId = normalizeString(report.subject_id);
                    if (procPatientId === reportPatientId) {
                        return 1;
                    }
                    // check if only using digits
                    const procPatientIdDigits = procPatientId.replace(/\D/g, '');
                    const reportPatientIdDigits = reportPatientId.replace(/\D/g, '');
                    return procPatientIdDigits === reportPatientIdDigits ? 0.5 : 0;
                },
            },
            {
                name: 'Patient ID by levenshtein distance',
                score: 10,
                eval: (report) => {
                    const procPatientId = normalizeString(procedure.patient_id ?? '');
                    const reportPatientId = normalizeString(report.subject_id);
                    return (
                        1 -
                        ldist(procPatientId, reportPatientId) / Math.max(procPatientId.length, reportPatientId.length)
                    );
                },
            },
        ],
        [procedure, getHospitalName, getHospitalAcronym],
    );

    const scoresPerReport = useMemo(
        () =>
            report.map((r) => {
                return matchStrategies.reduce((acc, strategy) => {
                    try {
                        acc += strategy.score * strategy.eval(r);
                    } catch (e) {
                        console.error(e);
                    }
                    return acc;
                }, 0);
            }),
        [matchStrategies, report],
    );

    const reportByScore = useMemo(
        () =>
            report
                .map((r, i) => {
                    return {
                        score: scoresPerReport[i],
                        ...r,
                    };
                })
                .sort((a, b) => b.score - a.score),
        [report, scoresPerReport],
    );

    const reportWithCurrentProcedureID = useMemo(
        () => reportByScore.find((x) => x.procedure_id === uuid),
        [reportByScore, uuid],
    );

    const [selectedReportIndex, setSelectedReportIndex] = useState(() => {
        if (reportWithCurrentProcedureID) {
            return reportByScore.findIndex((x) => x.id === reportWithCurrentProcedureID.id);
        }
        return 0;
    });
    const selectedReport = reportByScore[selectedReportIndex] ?? {};

    const [procedureIDUpdateSuccess, setProcedureIDUpdateSuccess] = useState(false);
    const [procedureIDUpdateError, setProcedureIDUpdateError] = useState(false);

    const updatePatientID = async (reportID, procedureID) =>
        partialUpdateReport({
            pathParams: { id: reportID },
            body: { procedure_id: procedureID },
            queryParams: { app },
        });

    const handleReportMatchPersistentChange = () => {
        async function updateReport() {
            let updatedCurrentReport = true;
            if (reportWithCurrentProcedureID) {
                updatedCurrentReport = await updatePatientID(reportWithCurrentProcedureID.id, null);
            }
            if (updatedCurrentReport) {
                const updatedNewReport = await updatePatientID(selectedReport.id, uuid);
                if (updatedNewReport) {
                    setProcedureIDUpdateSuccess(true);
                    setProcedureIDUpdateError(false);
                    return;
                }
            }
            setProcedureIDUpdateSuccess(false);
            setProcedureIDUpdateError(true);
        }

        updateReport().then(() => {
            queryClient.invalidateQueries({ queryKey: ['clinicalreport', 'ggpatient'] });
        });
    };

    const handleReportMatchPersistentDelete = () => {
        updatePatientID(selectedReport.id, null)
            .then(() => {
                setProcedureIDUpdateSuccess(true);
                setProcedureIDUpdateError(false);
                queryClient.invalidateQueries({ queryKey: ['clinicalreport', 'ggpatient'] });
            })
            .catch(() => {
                setProcedureIDUpdateSuccess(false);
                setProcedureIDUpdateError(true);
            });
    };

    const excludedFields = ['app', 'score', 'id', 'accessToken', 'last_edited_by'];

    const reportRows = Object.entries(selectedReport).filter((x) => !excludedFields.includes(x[0]));

    const { issues, issuesCount, aceValues } = useReportAnomalyAnalysis({ uuid, reportID: selectedReport.id });

    useEffect(() => {
        setIssueCount(issuesCount);
    }, [issuesCount, setIssueCount]);

    const sortedRows = reportRows.sort((a, b) => {
        // sort rows with issues to the top
        const aHasIssue = issues.some((x) => x.field === a[0]);
        const bHasIssue = issues.some((x) => x.field === b[0]);
        if (aHasIssue && !bHasIssue) return -1;
        if (!aHasIssue && bHasIssue) return 1;
        // put procedure_id at the top
        if (a[0] === 'procedure_id') return -1;
        if (b[0] === 'procedure_id') return 1;
        return 0;
    });

    const reportColumns = [
        {
            field: 'field',
            headerName: 'Field',
            flex: 0.4,
            minWidth: 200,
            valueGetter: (params) => params.row[0],
        },
        {
            field: 'greenlight_guru',
            headerName: 'Greenlight Guru',
            flex: 0.4,
            minWidth: 200,
            valueGetter: (params) => params.row[1],
        },
        {
            field: 'ace',
            headerName: 'ACE',
            flex: 0.4,
            minWidth: 200,
            valueGetter: (params) => aceValues[params.row[0]],
        },
        {
            field: 'issues',
            headerName: 'Issues',
            flex: 0.4,
            minWidth: 200,
            valueGetter: (params) =>
                issues
                    .filter((x) => x.field === params.row[0])
                    .map((x) => x.name)
                    .join(', '),
            renderCell: (params) => {
                const issuesForField = issues.filter((x) => x.field === params.row[0]);
                if (issuesForField.length === 0) {
                    return '';
                }
                return issuesForField.every((x) => x.ignored) ? (
                    <Stack spacing={2} direction='row' alignItems='center' justifyContent='space-between' width='100%'>
                        <s>{params.value}</s>
                        <ConfirmButton
                            variant='outlined'
                            color='error'
                            action='mark this previously ignored issue as an issue again'
                            onConfirm={() => {
                                const to_delete = issuesForField.map((x) => x.ignored_comment_id);
                                Promise.all(to_delete.map((x) => deleteComment({ pathParams: { id: x } })))
                                    .then(async () => {
                                        await queryClient.invalidateQueries({
                                            queryKey: ['procedures', uuid, 'comments'],
                                        });
                                    })
                                    ?.catch((err) => {
                                        console.log(err);
                                        alert('An unexpected error occurred');
                                    });
                            }}
                        >
                            Cancel Ignore
                        </ConfirmButton>
                    </Stack>
                ) : (
                    <Stack spacing={2} direction='row' alignItems='center' justifyContent='space-between' width='100%'>
                        <b>{params.value}</b>
                        <ConfirmButton
                            variant='outlined'
                            color='primary'
                            action='ignore this issue'
                            onConfirm={() => {
                                Promise.all(
                                    issuesForField.map((x) =>
                                        createComment({
                                            body: {
                                                body: '(action performed by on report action)',
                                                name: `Ignore: '${x.name}'`,
                                                parent: null,
                                                procedure_id: uuid,
                                            },
                                        }),
                                    ),
                                )
                                    .then(async () => {
                                        await queryClient.invalidateQueries({
                                            queryKey: ['procedures', uuid, 'comments'],
                                        });
                                    })
                                    ?.catch((err) => {
                                        console.log(err);
                                        alert('An unexpected error occurred');
                                    });
                            }}
                        >
                            Ignore
                        </ConfirmButton>
                    </Stack>
                );
            },
        },
    ];

    return (
        <Grid container spacing={2} alignItems='center'>
            <Grid item xs={2}>
                <Typography variant='h5'>Smart Match:</Typography>
            </Grid>
            <Grid item xs={10}>
                <TextField
                    fullWidth
                    select
                    label='Selected Report'
                    value={selectedReportIndex}
                    onChange={(e) => setSelectedReportIndex(e.target.value)}
                    disabled={reportByScore.length === 0}
                    helperText={reportByScore.length === 0 ? `No reports found on ${app}` : undefined}
                >
                    {reportByScore.map((r, i) => (
                        <MenuItem key={i} value={i}>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    {r.subject_id ?? ''} - {r.site_name}
                                </Grid>
                                <Grid item>{r.score.toFixed(2)}pts</Grid>
                            </Grid>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} container spacing={2}>
                <Grid item>
                    <ConfirmButton
                        variant='contained'
                        color='primary'
                        action='confirm that the selected report matches the current procedure'
                        onConfirm={handleReportMatchPersistentChange}
                        disabled={reportByScore.length === 0}
                    >
                        {reportWithCurrentProcedureID ? 'Update' : 'Confirm'} Match
                    </ConfirmButton>
                </Grid>
                {reportWithCurrentProcedureID && (
                    <Grid item>
                        <ConfirmButton
                            variant='contained'
                            color='secondary'
                            action='confirm that you want to clear the report match for this procedure'
                            onConfirm={handleReportMatchPersistentDelete}
                        >
                            Clear Match
                        </ConfirmButton>
                    </Grid>
                )}
                <Grid item>
                    <Button variant='outlined' onClick={() => navigate(`/procedures/${uuid}`)}>
                        Go back to procedure
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    rows={sortedRows}
                    columns={reportColumns}
                    getRowId={(row) => row[0]}
                    autoHeight
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    getRowClassName={(params) => {
                        const issue = issues.find((x) => x.field === params.id);
                        if (issue?.ignored) return 'ignored-data-grid-row';
                        return issue ? 'highlighted-data-grid-row' : '';
                    }}
                    sx={{
                        '& .highlighted-data-grid-row': {
                            backgroundColor: 'hsl(0, 100%, 75%)',
                            '&:hover': {
                                backgroundColor: 'hsl(0, 100%, 85%)',
                            },
                            '&.Mui-selected': {
                                backgroundColor: 'hsl(0, 100%, 65%)',
                                '&:hover': {
                                    backgroundColor: 'hsl(0, 100%, 70%)',
                                },
                            },
                        },
                        '& .ignored-data-grid-row': {
                            backgroundColor: 'hsl(0, 0%, 75%)',
                            '&:hover': {
                                backgroundColor: 'hsl(0, 0%, 85%)',
                            },
                            '&.Mui-selected': {
                                backgroundColor: 'hsl(0, 0%, 65%)',
                                '&:hover': {
                                    backgroundColor: 'hsl(0, 0%, 70%)',
                                },
                            },
                        },
                    }}
                />
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={procedureIDUpdateSuccess}
                autoHideDuration={6000}
                onClose={() => setProcedureIDUpdateSuccess(false)}
            >
                <Alert severity='success' onClose={() => setProcedureIDUpdateSuccess(false)}>
                    Procedure ID successfully updated!
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={procedureIDUpdateError}
                autoHideDuration={6000}
                onClose={() => setProcedureIDUpdateError(false)}
            >
                <Alert severity='error' onClose={() => setProcedureIDUpdateError(false)}>
                    Procedure ID update failed...
                </Alert>
            </Snackbar>
        </Grid>
    );
}

function QuickIssues() {
    const [issueStatus, setIssueStatus] = useState(caseStatuses.unassigned);
    const { uuid } = useParams();

    const { mutateAsync: createComment } = useCommentsCreate();
    const queryClient = useQueryClient();

    const handleButtonClick = () => {
        createComment({
            body: {
                body: '.',
                name: issueStatus,
                parent: null,
                procedure_id: uuid,
            },
        }).then(() => {
            queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'comments'] });
        });
    };

    return (
        <PaperCard xs={12} title='Set Quick Issues' align='center'>
            <Stack spacing={1}>
                <Select
                    onChange={(e) => {
                        setIssueStatus(e.target.value);
                    }}
                    value={issueStatus}
                >
                    {Object.values(caseStatuses).map((x) => (
                        <MenuItem key={x} value={x}>
                            {x}
                        </MenuItem>
                    ))}
                </Select>
                <Button variant='contained' onClick={handleButtonClick}>
                    Quick Comment
                </Button>
            </Stack>
        </PaperCard>
    );
}

export default function GGPatientReport({ pin }) {
    const authorized = useAuthorized();
    const show = useShow();
    const token = useContext(TokenContext);
    const user = token.parse().user;
    const isStaff = user.is_active && user.is_staff;
    const userInfo = { username: user.username, isStaff };

    const [issueCount, setIssueCount] = useState(0);

    return !authorized || !show.clinical_reports ? (
        <Forbidden />
    ) : (
        <Grid container spacing={3}>
            <PaperCard xs={12}>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={4}>
                        <Typography variant='h4' align='center'>
                            Greenlight Guru Patient Report
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        {issueCount > 0 ? (
                            <Typography variant='h6' color='error' align='center'>
                                Pending issues: {issueCount}
                            </Typography>
                        ) : (
                            <Typography variant='h6' align='center' sx={{ color: 'success.main' }}>
                                No issues found
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </PaperCard>
            <ProcedureTitle pin={pin} />
            <PaperCard xs={12}>
                <AsyncBoundary>
                    <GGPatientData setIssueCount={setIssueCount} />
                </AsyncBoundary>
            </PaperCard>
            <QuickIssues />
            {show.comments && (
                <PaperCard xs={12} title='Comments' align='center'>
                    <CommentSection {...userInfo} />
                </PaperCard>
            )}
        </Grid>
    );
}
