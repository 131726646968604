import * as d3 from 'd3';
import { useEffect, useRef } from 'react';

export default function useD3(renderChartFn, dependencies) {
    const ref = useRef();
    useEffect(() => {
        return renderChartFn(d3.select(ref.current)) ?? (() => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
    return ref;
}
