import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Box, Divider, Typography } from '@mui/material';
import SearchForm from '../components/search/SearchForm';
import ForceSelectApp from '../components/report/ForceSelectApp';

export default function Search(props) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper>
                    <Typography variant='h3' align='center'>
                        Search
                    </Typography>
                    <Divider />
                    <Box m={2} pt={3} pb={3}>
                        <ForceSelectApp>
                            <SearchForm {...props} />
                        </ForceSelectApp>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
}
