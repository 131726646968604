import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProcedureDataGrid from '../../search/ProcedureDataGrid';
import format from 'format-duration';
import { useAceApp } from '../../Menu/ReportAppSelector';

export default function GroupedProcedureTables({ data, tableOpt, extraColumnId, pin }) {
    const { app } = useAceApp();

    const extraColumns = [
        {
            field: 'duration',
            headerName: 'Duration',
            flex: 0.2,
            minWidth: 50,
            valueFormatter: (x) => format(x.value),
        },
        {
            field: 'bad_net',
            headerName: 'Network Disruption Time',
            flex: 0.2,
            minWidth: 50,
            valueFormatter: (x) => format(x.value ?? Infinity),
        },
        {
            field: 'det_off',
            headerName: 'Detection Off Time',
            flex: 0.2,
            minWidth: 50,
            valueFormatter: (x) => format(x.value ?? Infinity),
        },
        {
            field: 'bad_vm',
            headerName: 'Bad VM Time',
            flex: 0.2,
            minWidth: 50,
            valueFormatter: (x) => format(x.value ?? Infinity),
        },
        {
            field: 'time',
            headerName: 'Time',
            flex: 0.3,
            minWidth: 70,
        },
    ];

    return data
        .filter((group) => group.count >= 1)
        .map((group) => {
            /* This key allows keeping track of the state of all different tables, however if were just using
        group name as a key for the tableOpt states, it could cause conflicts when changing in the options
        the extra column type with some tables open, e.g. if this extra column is used in the sort model
        thus why I append the column id to the key */
            const tableKey = `${group.name}_#${extraColumnId}`;

            return (
                <div key={group.id}>
                    <Accordion
                        expanded={tableOpt?.expanded.state?.[tableKey] ?? false}
                        onChange={(e, v) =>
                            tableOpt?.expanded.setState((prevState) => ({
                                ...prevState,
                                [tableKey]: v,
                            }))
                        }
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {group.name} ({group.count})
                        </AccordionSummary>
                        <AccordionDetails>
                            <ProcedureDataGrid
                                rows={group.procs.map((x) => ({ ...x, database: app }))}
                                pageSize={5}
                                extraColumns={[extraColumns[extraColumnId]]}
                                sortModel={tableOpt?.sortModel.state?.[tableKey]}
                                onSortModelChange={(v) =>
                                    tableOpt?.sortModel.setState((prevState) => ({
                                        ...prevState,
                                        [tableKey]: v,
                                    }))
                                }
                                page={tableOpt?.page.state?.[tableKey]}
                                onPageChange={(v) =>
                                    tableOpt?.page.setState((prevState) => ({
                                        ...prevState,
                                        [tableKey]: v,
                                    }))
                                }
                                pin={pin}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            );
        });
}
