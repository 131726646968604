import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    List,
    ListItemText,
    Skeleton,
    Stack,
    Tooltip,
} from '@mui/material';
import { AsyncBoundary } from '../../aceapi/utils';
import { useParams } from 'react-router-dom';
import PaperCard from './PaperCard';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import React, { useState } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useProceduresLogs } from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

const LOG_LEVELS = [
    { name: 'debug', color: 'gray' },
    { name: 'info', color: 'info.dark' },
    { name: 'warning', color: 'warning.dark' },
    { name: 'error', color: 'error.dark' },
    { name: 'exception', color: 'fuchsia' },
    { name: 'unknown', color: 'purple' },
];

function AsyncClientLogsCard() {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const { data: events } = useProceduresLogs({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });

    const [expanded, setExpanded] = useState(false);
    const [showLevels, setShowLevels] = useState(LOG_LEVELS.map(() => true));

    const dumpCSV = () => {
        let csv = events.map(
            (log) =>
                `${log.timestamp},${new Date(log.timestamp)
                    .toLocaleString('en-GB')
                    .replace(
                        ' ',
                        '',
                    )},${log.level},${LOG_LEVELS[Math.min(log.level, LOG_LEVELS.length - 1)].name},"${log.content}"`,
        );
        csv.unshift('timestamp,date,time,level,level_name,content');
        csv = csv.join('\n');
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `client_logs_${uuid}.csv`;
        link.click();
    };

    const SelectLevels = ({ compact = false, ...props }) => (
        <FormGroup row {...props}>
            {LOG_LEVELS.map((level, code) => {
                const label = `${level.name} (${events.filter((log) => log.level === code).length})`;
                return (
                    <Tooltip key={level.name} title={compact ? label : ''}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showLevels[code]}
                                    onChange={(e) =>
                                        setShowLevels(showLevels.map((_, i) => (i === code ? e.target.checked : _)))
                                    }
                                    sx={{ color: level.color, '&.Mui-checked': { color: level.color } }}
                                />
                            }
                            label={!compact && label}
                        />
                    </Tooltip>
                );
            })}
        </FormGroup>
    );

    return (
        <Stack>
            <List dense sx={{ overflowY: 'auto', maxHeight: '12rem' }}>
                {events.map(
                    (log) =>
                        showLevels[Math.min(log.level, LOG_LEVELS.length - 1)] && (
                            <ListItemText
                                key={log.id}
                                sx={{ wordBreak: 'break-word' }}
                                primaryTypographyProps={{
                                    color: LOG_LEVELS[Math.min(log.level, LOG_LEVELS.length - 1)].color,
                                }}
                            >
                                <b>{new Date(log.timestamp).toLocaleString('en-GB')}</b>: {log.content}
                            </ListItemText>
                        ),
                )}
                {events.length === 0 && <ListItemText>No logs recorded</ListItemText>}
            </List>
            <Stack direction='row'>
                <Tooltip title='Show in full screen'>
                    <IconButton color='primary' onClick={() => setExpanded(true)}>
                        <FullscreenIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Dump as CSV'>
                    <IconButton color='primary' onClick={dumpCSV}>
                        <SaveAltIcon />
                    </IconButton>
                </Tooltip>
                <SelectLevels compact sx={{ ml: 3 }} />
            </Stack>
            <Dialog open={expanded} onClose={() => setExpanded(false)} maxWidth='xl' fullWidth>
                <DialogTitle variant='h4'>Client Logs</DialogTitle>
                <DialogContent>
                    <List dense sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        {events.map(
                            (log, i) =>
                                showLevels[Math.min(log.level, LOG_LEVELS.length - 1)] && (
                                    <ListItemText
                                        key={log.id}
                                        sx={{ wordBreak: 'break-word' }}
                                        primaryTypographyProps={{
                                            fontFamily: 'Roboto Mono,Monospace',
                                            color: LOG_LEVELS[Math.min(log.level, LOG_LEVELS.length - 1)].color,
                                        }}
                                    >
                                        [{`${i}`.padStart(`${events.length}`.length, '0')}]
                                        <b>[{new Date(log.timestamp).toLocaleString('en-GB')}]</b> - {log.content}
                                    </ListItemText>
                                ),
                        )}
                        {events.length === 0 && <ListItemText>No logs recorded</ListItemText>}
                    </List>
                    <Divider />
                    <DialogActions>
                        <SelectLevels />
                        <Button variant='contained' color='secondary' onClick={dumpCSV} endIcon={<SaveAltIcon />}>
                            Dump as CSV
                        </Button>
                        <Button variant='contained' color='primary' onClick={() => setExpanded(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </Stack>
    );
}

function ClientLogsCard(props) {
    return (
        <PaperCard title='Client Logs'>
            <AsyncBoundary
                fallback={
                    <List dense>
                        {Array(7)
                            .fill()
                            .map((_, i) => (
                                <ListItemText key={i}>
                                    <Skeleton animation='wave' />
                                </ListItemText>
                            ))}
                    </List>
                }
            >
                <AsyncClientLogsCard {...props} />
            </AsyncBoundary>
        </PaperCard>
    );
}

export default React.memo(ClientLogsCard);
