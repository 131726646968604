import { ThemeOptions } from '@mui/material';

export const themeOptions: ThemeOptions = {
    shape: {
        borderRadius: 15,
    },
    palette: {
        type: 'light',
        primary: {
            main: '#6587c7',
        },
        secondary: {
            main: '#7792a2',
        },
        background: {
            default: '#cfd4d9',
            paper: '#f8f8f6',
        },
        warning: {
            main: '#ffa841',
        },
        info: {
            main: '#574eda',
        },
        error: {
            main: '#f36e6e',
        },
        success: {
            main: '#60ad60',
        },
    },
    custom: {
        elevation: 4,
    },
} as ThemeOptions;
