import { Paper, Typography } from '@mui/material';

export default function PaperFloatingCard({ children, title, textAlign = 'center', ...props }) {
    const { left, right, top, bottom, width, height, transform } = props;

    return (
        <Paper
            sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                position: 'absolute',
                left: left,
                right: right,
                top: top,
                bottom: bottom,
                width: width,
                height: height,
                textAlign: textAlign,
                transform: transform,
                zIndex: 100,
            }}
        >
            {title && (
                <Typography variant='h5' align='left' {...props}>
                    {title}
                </Typography>
            )}
            {children}
        </Paper>
    );
}
