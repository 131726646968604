import { MenuItem, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

export default function SelectWidget({ field, control }) {
    return (
        <Controller
            name={field.name}
            control={control}
            render={(props) => (
                <TextField select label={field.label} type='search' variant='outlined' {...props.field}>
                    {!field.required && (
                        <MenuItem value=''>
                            <em>Any</em>
                        </MenuItem>
                    )}
                    {field.choices.map((e) => (
                        <MenuItem key={e} value={e}>
                            {e}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        />
    );
}
