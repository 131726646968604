import { useEffect, useState } from 'react';
import { timedelta } from '../utils';
import { useAceApp } from '../../Menu/ReportAppSelector';
import { useSummaryProceduresWithAdenoma } from '../../../aceapi/aceComponents';

export default function useCommonSummaryFilters({ groups, procs, registerOption, renameOption, options, optionTypes }) {
    const { app } = useAceApp();
    const [filteredProcs, setFilteredProcs] = useState(procs);
    const [filteredGroups, setFilteredGroups] = useState(groups);

    const { data: pAde, isLoading: isLoadingPAde } = useSummaryProceduresWithAdenoma(
        { queryParams: { app } },
        { suspense: false },
    );

    // const [pDet, setPDet] = useState(null);
    const max_duration = timedelta.HOUR * 3;

    useEffect(() => {
        registerOption('test', 'Hide test users and groups', optionTypes.SWITCH, true);
        // registerOption("detection", "Loading data for this option (detection)...");
        registerOption(
            'detection',
            'Option to filter out procedures without detections is temporarily disabled for performance issues',
            optionTypes.DISABLED,
        );
        registerOption('adenoma', 'Loading data for this option (adenoma)...');
        registerOption('duration', 'Procedure duration range', optionTypes.INT_SLIDER, [0, max_duration], {
            min: 0,
            max: max_duration,
            timeSlider: true,
        });
        registerOption('status', 'Procedure status', optionTypes.STRING_MULTISELECTOR, ['COMPLETED', 'INTERRUPTED'], {
            options: [
                { key: 'completed', name: 'Completed', value: 'COMPLETED' },
                { key: 'interrupted', name: 'Interrupted', value: 'INTERRUPTED' },
            ],
        });
        registerOption(
            'modes',
            'Workflow modes',
            optionTypes.STRING_MULTISELECTOR,
            ['AUDIT', 'COMPUTER_AIDED', 'COMPUTER_AIDED_TRIAL', 'NORMAL_ENDOSCOPY', 'NORMAL_ENDOSCOPY_TRIAL', 'DEMO'],
            {
                options: [
                    { key: 'audit', name: 'Audit', value: 'AUDIT' },
                    { key: 'aided', name: 'Computer Aided', value: 'COMPUTER_AIDED' },
                    { key: 'aided_trial', name: 'Computer Aided Trial', value: 'COMPUTER_AIDED_TRIAL' },
                    { key: 'endoscopy', name: 'Normal Endoscopy', value: 'NORMAL_ENDOSCOPY' },
                    { key: 'endoscopy_trial', name: 'Normal Endoscopy Trial', value: 'NORMAL_ENDOSCOPY_TRIAL' },
                    { key: 'demo', name: 'Demo', value: 'DEMO' },
                ],
            },
        );
    }, [max_duration, optionTypes, registerOption]);

    /*
    useEffect(() => {
        fetch(ProcedureWithDetectionResourceBase.product(product).list(), {})
            ?.then(data => setPDet(data));
    }, [fetch, product]);

    useEffect(() => {
        if (pDet === null)
            return;
        renameOption("detection", "Remove procedure without any detection");
    }, [pDet, renameOption]);
    */

    useEffect(() => {
        if (isLoadingPAde) return;
        renameOption('adenoma', 'Remove procedure without any Adenoma characterisation');
    }, [isLoadingPAde, pAde, renameOption]);

    useEffect(() => {
        let q = procs;
        const duration_range = options?.['duration']?.value;
        if (duration_range?.length === 2)
            q = q.filter(
                (p) =>
                    p.duration >= duration_range[0] &&
                    (p.duration <= duration_range[1] || duration_range[1] === max_duration),
            );
        const statuses = options?.['status']?.value;
        if (Array.isArray(statuses)) q = q.filter((p) => statuses.includes(p.status));
        const modes = options?.['modes']?.value;
        if (Array.isArray(modes)) q = q.filter((p) => modes.includes(p.mode));
        // if (options?.["detection"]?.value && pDet)
        //     q = q.filter(p => pDet.map(x => x.procedure_id).includes(p.procedure_id));
        if (options?.['adenoma']?.value && !isLoadingPAde && pAde)
            q = q.filter((p) => pAde.map((x) => x.procedure_id).includes(p.procedure_id));
        setFilteredProcs(q);
    }, [isLoadingPAde, max_duration, options, pAde, procs]);

    useEffect(() => {
        let g = groups;
        if (options?.['test']?.value) g = g.filter((x) => !x.test);
        setFilteredGroups(g);
    }, [groups, options]);

    return {
        filteredProcs,
        filteredGroups,
    };
}
