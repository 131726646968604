import format from 'format-duration';
import ConfirmButton from '../dialogs/ConfirmButton';
import SplitButton from '../report/SplitButton';
import useProcTime from '../../aceapi/hooks/useProcTime';
import { AsyncBoundary } from '../../aceapi/utils';

function AsyncRequestDiagnosisButton({ mouse }) {
    const { start } = useProcTime();
    const procTime = format(mouse.timestamp - start);

    return (
        <SplitButton variant='contained'>
            <ConfirmButton
                action={`request a mouse diagnosis on the current video timestamp: (${procTime}), using the closest existing detection box`}
                onConfirm={() => mouse.request('diagnosis', { box: null })}
            >
                Request normal diagnosis
            </ConfirmButton>
            <ConfirmButton
                action={`start draw mode to request a custom box mouse diagnosis on the current video timestamp: (${procTime})`}
                onConfirm={() => {
                    if (mouse.mode.state === 'draw')
                        alert('Draw mode already active, scroll to the top and draw a box on the video!');
                    mouse.mode.setState('draw');
                }}
            >
                Request custom box diagnosis
            </ConfirmButton>
            <ConfirmButton
                action={`request a polyp detection on the current video timestamp: (${procTime})`}
                onConfirm={() => mouse.request('detection', {})}
            >
                Request polyp detection
            </ConfirmButton>
            <ConfirmButton
                action={`request freeze frame detection on the whole video`}
                onConfirm={() => mouse.request('freeze_frame', {}, false)}
            >
                Request freeze frame detection
            </ConfirmButton>
            <ConfirmButton
                action={`request nbi detection on the whole video`}
                onConfirm={() => mouse.request('nbi_detection', {}, false)}
            >
                Request nbi detection
            </ConfirmButton>
        </SplitButton>
    );
}

export default function RequestDiagnosisButton(props) {
    return (
        <AsyncBoundary>
            <AsyncRequestDiagnosisButton {...props} />
        </AsyncBoundary>
    );
}
