import { IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';

const ellipsis = {
    style: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
};

export default function CommentNotification({ notif, icon, clear }) {
    const navigate = useNavigate();

    return (
        <MenuItem
            onClick={() =>
                navigate(`/${notif.app}/procedures/${notif.comment.procedure_id}#comment-${notif.comment.id}`)
            }
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
                primaryTypographyProps={ellipsis}
                secondaryTypographyProps={ellipsis}
                secondary={notif.comment.body}
            >
                <b>{new Date(notif.created_at).toLocaleString()}</b>: <i>{notif.reason}</i> from{' '}
                <b>{notif.comment.user.username}</b> in <i>{notif.comment.procedure_id}</i>
            </ListItemText>
            <IconButton onClick={clear}>
                <ClearIcon />
            </IconButton>
        </MenuItem>
    );
}
