import BoundedFrame from './BoundedFrame';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';

export default function FrameBox({ width = '5rem', height = '5rem', ...props }) {
    const { frame, borderThickness, setHovered, setClicked, setCtrlClicked, fullframe, resolution, others } = props;

    const { hash } = useLocation();
    const selected = frame.dagrun && hash.replace('#', '') === `dagrun-${frame.dagrun}`;
    const borderColor = selected ? 'red' : props.borderColor;

    const imgHovered = () => {
        if (!setHovered) return;
        setHovered(frame);
    };

    const imgLeave = () => {
        if (!setHovered) return;
        setHovered(null);
    };

    const imgClick = (e) => {
        if (e.ctrlKey) {
            if (!setCtrlClicked) return;
            setCtrlClicked(frame);
        } else {
            if (!setClicked) return;
            setClicked(frame);
        }
    };

    const show_box = frame.full_frame && frame.detection;
    const show_polygon =
        Array.isArray(frame.detection_polygon?.coordinates) && frame.detection_polygon.coordinates.length > 0;
    const url = show_polygon ? frame.url : fullframe ? frame.full_frame?.url : frame.url;

    let polypID = frame?.full_frame?.value ?? 'ERR';
    if (polypID.length !== 3) polypID = 'N/A';

    return (
        <div
            style={{
                height,
                width,
                border: borderThickness + ' solid ' + borderColor,
                backgroundColor: 'black',
                position: 'relative',
            }}
            onMouseEnter={imgHovered}
            onMouseLeave={imgLeave}
            onClick={imgClick}
        >
            {url ? (
                <img
                    src={url}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        position: 'absolute',
                    }}
                    alt='polyp_grid'
                />
            ) : (
                <ImageNotSupportedOutlinedIcon
                    color='info'
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            )}
            {fullframe && (
                <Typography
                    color='background.paper'
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        textShadow: '1px 1px 1px black',
                    }}
                >
                    {polypID}
                </Typography>
            )}
            {fullframe && show_box && <BoundedFrame frame={frame} others={others} resolution={resolution} />}
            {show_polygon && <BoundedFrame frame={frame} resolution={resolution} polygon />}
        </div>
    );
}
