import { useParams } from 'react-router-dom';
import {
    useProceduresGenerateAnnotationsXmlCreate,
    useProceduresGenerateAnnotationsXmlRead,
    useVideoRead,
} from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { AsyncBoundary } from '../../aceapi/utils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

function AsyncAnnotationXMLDownload() {
    const { app } = useAceApp();
    const { uuid } = useParams();

    const [job, setJob] = useState(null);

    const { data: video } = useVideoRead({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });

    const { mutateAsync: genXML } = useProceduresGenerateAnnotationsXmlCreate({
        onSuccess: (res) => {
            setJob(res.job);
        },
    });

    const { data: result } = useProceduresGenerateAnnotationsXmlRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app, job: job },
        },
        {
            enabled: !!job,
            refetchInterval: 2000,
        },
    );

    useEffect(() => {
        if (result?.state === 'SUCCESS') {
            setJob(null);
            const xml_str = result?.xml;
            const blob = new Blob([xml_str], { type: 'text/xml' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${uuid}_annotations.xml`;
            link.click();
        } else if (result?.state === 'ERROR') {
            setJob(null);
            alert('Unexpected error occurred while generating XML');
        }
    }, [result, uuid]);

    return (
        <LoadingButton
            onClick={() => genXML({ pathParams: { procedureId: uuid }, queryParams: { app } })}
            loading={!!job}
            loadingPosition='start'
            endIcon={<FileDownloadIcon />}
            variant='contained'
            disabled={!video?.video?.url}
        >
            Generate Annotations XML
        </LoadingButton>
    );
}

export default function AnnotationXMLDownload() {
    return (
        <AsyncBoundary>
            <AsyncAnnotationXMLDownload />
        </AsyncBoundary>
    );
}
