import { colorIsLight } from './utils';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const chipStyle = {
    borderRadius: '4px',
    padding: '0 4px',
    margin: '2px',
};

export default function ExtraDataLegend({ extraModels }) {
    return (
        <Box whiteSpace='noWrap' display='flex' flexWrap='wrap'>
            <Typography
                variant='caption'
                color='white'
                style={{
                    background: 'linear-gradient(to right, #0f0, #0f0, #ff0, #f00, #f00)',
                    textShadow: '1px 1px 0 #000',
                    ...chipStyle,
                }}
            >
                Visible Mucosa
            </Typography>
            {Array.isArray(extraModels) &&
                extraModels.map((model, i) => (
                    <Typography
                        key={i}
                        variant='caption'
                        color={colorIsLight(model.color) ? 'black' : 'white'}
                        bgcolor={model.color}
                        sx={chipStyle}
                    >
                        {model.name}
                    </Typography>
                ))}
        </Box>
    );
}
