import { useState } from 'react';
import { TextField } from '@mui/material';

export default function useVideoSeeker() {
    const [timestamp, setTimestamp] = useState(null);
    const [frame, setFrame] = useState(0);
    const [timelineWidth, setTimelineWidth] = useState(window.innerWidth);
    const [playerRef, setPlayerRef] = useState(null);
    const [videoMetadata, setVideoMetadata] = useState({});

    const start = videoMetadata.start ?? 0;
    const fps = videoMetadata.fps ?? 25;

    const seekTo = (timestamp) => {
        const seconds = (timestamp - start) / 1000;
        playerRef?.seekTo(seconds < 0 ? 0 : seconds, 'seconds');
    };

    const frameJump = (frames) => {
        playerRef?.seekTo((playerRef?.getCurrentTime() ?? 0) + frames / fps, 'seconds');
    };

    const ts2frame = (timestamp, relative = false) => {
        const sub = relative ? 0 : start;
        return Math.round(((timestamp - sub) / 1000) * fps);
    };

    const frame2ts = (frame, relative = false) => {
        const sub = relative ? 0 : start;
        return Math.round((frame / fps) * 1000) + sub;
    };

    const parseFrame = (value) => {
        const frame = Math.min(Math.max(0, parseInt(value)), videoMetadata?.frame_count ?? 0);
        return isNaN(frame) ? 0 : frame;
    };

    const frameInput = (props) => {
        return (
            <TextField
                sx={{ width: '8ch' }}
                variant='standard'
                value={frame}
                onChange={(e) => {
                    const frame = parseFrame(e.target.value);
                    setFrame(frame);
                    seekTo(frame2ts(frame));
                }}
                {...props}
            />
        );
    };

    return {
        timestamp: {
            state: timestamp,
            setState: setTimestamp,
        },
        frame: {
            state: frame,
            setState: setFrame,
        },
        timelineWidth: {
            state: timelineWidth,
            setState: setTimelineWidth,
        },
        playerRef: {
            state: playerRef,
            setState: setPlayerRef,
        },
        videoMetadata: {
            state: videoMetadata,
            setState: setVideoMetadata,
        },
        seekTo,
        frameJump,
        ts2frame,
        frame2ts,
        parseFrame,
        frameInput,
        video_start: start,
    };
}
