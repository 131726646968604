import { Grid, Modal, Skeleton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import FrameBox from './FrameBox';
import FrameZoom from './FrameZoom';
import { useState } from 'react';

export default function FrameGrid({ maxHeight = '15rem', ...props }) {
    const { frames, hoveredFrame, ctrlClickedFrame, error, style } = props;

    const theme = useTheme();
    const [clickedFrame, setClickedFrame] = useState(null);

    if (error) {
        return (
            <Typography color='error' variant='body2'>
                Could not fetch frames: Error {error.status} ({error.response?.statusText ?? 'Unknown details'}).
            </Typography>
        );
    }

    return (
        <div style={style}>
            <Grid container maxHeight={maxHeight} overflow='auto'>
                <Grid container rowSpacing={0.5} columnSpacing={0.5} width='100%'>
                    {Array.isArray(frames)
                        ? frames.map((frame, idx) => (
                              <Grid key={idx} item xs='auto'>
                                  <FrameBox
                                      frame={frame}
                                      borderThickness={frame.selected ? '3px' : '2px'}
                                      borderColor={frame.selected ? 'blue' : 'black'}
                                      setHovered={hoveredFrame.setState}
                                      setClicked={setClickedFrame}
                                      setCtrlClicked={ctrlClickedFrame.setState}
                                  />
                                  <Tooltip title={frame.dagrun ? 'via MOUSE' : ''}>
                                      <figcaption
                                          style={{
                                              background: frame.dagrun
                                                  ? theme.palette.primary.main
                                                  : theme.palette.secondary.dark,
                                              color: 'white',
                                              width: '5rem',
                                              fontSize: '12px',
                                              textAlign: 'center',
                                          }}
                                      >
                                          {frame.diagnosis}
                                      </figcaption>
                                  </Tooltip>
                              </Grid>
                          ))
                        : Array(5)
                              .fill()
                              .map((_, i) => <PlaceHolderFrame key={i} />)}
                </Grid>
            </Grid>
            <Modal open={clickedFrame !== null} onClose={() => setClickedFrame(null)}>
                <div>{Array.isArray(frames) && <FrameZoom frame={clickedFrame} frames={frames} />}</div>
            </Modal>
        </div>
    );
}

export function PlaceHolderFrame() {
    return (
        <Grid item xs='auto'>
            <Stack spacing={0.5}>
                <Skeleton variant='rounded' width={80} height={78} animation='wave' />
                <Skeleton variant='rounded' width={80} height={16} animation='wave' />
            </Stack>
        </Grid>
    );
}
