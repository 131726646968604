import { Grid } from '@mui/material';
import ReportPaper from './ReportPaper';

export default function PaperCard({ xs = 4, ...props }) {
    return (
        <Grid item xs={xs}>
            <ReportPaper {...props} />
        </Grid>
    );
}
