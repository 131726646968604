import { Box, Button, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { caseStatuses } from '../clinical/utils';
import CollapsiblePaperCard from '../report/CollapsiblePaperCard';
import {
    useClinicalreportEagleList,
    useCommentsCreate,
    useFindWithCaseStatus,
    useHospitalsList,
} from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AsyncBoundary } from '../../aceapi/utils';
import PaperCard from '../report/PaperCard';
import FakeProgressBar from '../placeholders/FakeProgressBar';

const daysSinceTimestamp = (unixTimestamp) => {
    const date = new Date(unixTimestamp);
    const today = new Date();
    const diff = today.getTime() - date.getTime();
    const daysSince = Math.floor(diff / 86400000);
    return daysSince;
};

function CaseList({ status }) {
    const { app } = useAceApp();
    const { mutateAsync: createComment } = useCommentsCreate();

    const [page, setPage] = useState(0);
    const [sortModel, setSortModel] = useState([{ field: 'age', sort: 'asc' }]);

    const fixedSortModel = useMemo(() => {
        return sortModel.map((x) => {
            if (x.field === 'age') {
                return { field: 'start', sort: x.sort === 'asc' ? 'desc' : 'asc' };
            }
            if (x.field === 'hospital_name') {
                return { field: 'username', sort: x.sort };
            }
            return x;
        });
    }, [sortModel]);

    const {
        data: trialProcedures,
        isLoading,
        isSuccess,
    } = useFindWithCaseStatus(
        {
            queryParams: {
                app,
                case_status: status,
                page: page + 1,
                page_size: 10,
                recordings__patient_id__isnull: false,
                ordering: fixedSortModel.map((x) => `${x.sort === 'asc' ? '' : '-'}${x.field}`).join(','),
            },
        },
        { suspense: false },
    );

    const [lastProcedureFetch, setLastProcedureFetch] = useState({
        results: [],
        count: 0,
    });
    useEffect(() => {
        if (!isLoading && isSuccess) {
            setLastProcedureFetch(trialProcedures);
        }
    }, [trialProcedures, isLoading, isSuccess]);

    const { data: hospitals } = useHospitalsList({ queryParams: { app } });

    const queryClient = useQueryClient();

    const getHospitalName = useCallback(
        (username) => {
            const match = username.match(/^\w+-(\w+)-\d+$/);
            if (match) {
                const acronym = match[1];
                const hospital = hospitals.find((x) => x.acronym === acronym);
                if (hospital) {
                    return hospital.name;
                }
            }
            return null;
        },
        [hospitals],
    );

    const columns = useMemo(
        () => [
            {
                field: 'hospital_name',
                headerName: 'Hospital Name',
                flex: 0.5,
                minWidth: 150,
                valueGetter: (x) => `${x.row.username}`,
                renderCell: (x) => (
                    <Stack direction='column' spacing={0}>
                        <Typography variant='body2'>{x.row.username}</Typography>
                        <Typography variant='caption'>{getHospitalName(x.row.username)}</Typography>
                    </Stack>
                ),
            },
            { field: 'patient_id', headerName: 'Patient ID', flex: 0.7, minWidth: 100 },
            { field: 'mode', headerName: 'Mode', flex: 0.5, minWidth: 100 },
            {
                field: 'age',
                headerName: 'Age (Days)',
                flex: 0.3,
                minWidth: 50,
                type: 'number',
                valueGetter: (x) => daysSinceTimestamp(x.row.start),
            },
            {
                field: 'procedure',
                headerName: 'Procedure',
                flex: 0.3,
                minWidth: 80,
                renderCell: (x) => (
                    <Button variant='contained' href={`/procedures/${x.row.procedure_id}`}>
                        Open
                    </Button>
                ),
            },
            {
                field: 'report',
                headerName: 'Report',
                flex: 0.3,
                minWidth: 80,
                renderCell: (x) => (
                    <Button variant='contained' href={`/patient/${x.row.procedure_id}`}>
                        Report
                    </Button>
                ),
            },
            {
                field: 'not_relevant_case',
                headerName: 'Set Not Clinical',
                flex: 0.5,
                minWidth: 130,
                renderCell: (x) => (
                    <Button
                        variant='contained'
                        onClick={() =>
                            createComment({
                                body: {
                                    body: '.',
                                    name: caseStatuses.notClinicalCase,
                                    parent: null,
                                    procedure_id: x.row.procedure_id,
                                },
                            }).then(() =>
                                queryClient.invalidateQueries({
                                    queryKey: ['find', 'with_case_status'],
                                }),
                            )
                        }
                    >
                        Set Not Clinical
                    </Button>
                ),
            },
        ],
        [createComment, getHospitalName, queryClient],
    );

    return (
        <CollapsiblePaperCard title={`ACE Cases: ${status}`} xs={12}>
            <Box sx={{ width: '100%' }}>
                <DataGrid
                    getRowId={(row) => row.procedure_id}
                    rows={lastProcedureFetch?.results ?? []}
                    columns={columns}
                    disableSelectionOnClick
                    pageSize={10}
                    autoHeight
                    pagination
                    page={page}
                    onPageChange={(page) => setPage(page)}
                    rowCount={lastProcedureFetch?.count}
                    rowsPerPageOptions={[10]}
                    paginationMode='server'
                    sortModel={sortModel}
                    onSortModelChange={(sortModel) => setSortModel(sortModel)}
                    sortingMode='server'
                    loading={isLoading}
                />
            </Box>
        </CollapsiblePaperCard>
    );
}

function AsyncACECaseListSummary() {
    const { app } = useAceApp();
    const { data: report } = useClinicalreportEagleList({ queryParams: { app } });

    return Object.values(caseStatuses).map((status) => <CaseList key={status} status={status} report={report} />);
}

export default function ACECaseListSummary() {
    return (
        <AsyncBoundary
            fallback={
                <PaperCard xs={12} title='Cases per status'>
                    <FakeProgressBar />
                </PaperCard>
            }
        >
            <AsyncACECaseListSummary />
        </AsyncBoundary>
    );
}
