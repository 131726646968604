import { useMemo, useState } from 'react';
import { modelToTimeline } from '../Charts/timeline/utils';
import { useParams } from 'react-router-dom';
import useProcTime from '../../aceapi/hooks/useProcTime';
import useAuthorized from '../../aceapi/hooks/useAuthorized';
import {
    useExtraModelsList,
    useProceduresExtraDetections,
    useProceduresTimelineEdits,
} from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

// Time in milliseconds of chunks of timeline drawn.
const dataLength = 2e3;

export default function useExtraData({ show, overrideProduct = null, overrideUuid = null }) {
    const ace = useAceApp();
    const params = useParams();
    const app = overrideProduct ?? ace.app;
    const uuid = overrideUuid ?? params.uuid;
    const authorized = useAuthorized(app, uuid);
    const time = useProcTime(app, uuid);

    const { data: extraModels } = useExtraModelsList(
        {
            queryParams: { app },
        },
        { enabled: show && authorized },
    );
    const { data: extraDetections } = useProceduresExtraDetections(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: show && authorized },
    );
    const { data: timeline_edits } = useProceduresTimelineEdits(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: show && authorized },
    );
    const [edits, setEdits] = useState(timeline_edits ?? []);

    // Get counts of unique keys in extra detections data.
    const keyCounts = useMemo(() => {
        if (!show || !authorized) return null;
        return extraDetections?.reduce((r, o) => {
            r[o.key] = (r[o.key] ?? 0) + 1;
            return r;
        }, {});
    }, [authorized, extraDetections, show]);

    const extraRow = useMemo(() => {
        if (!show || !authorized) return show ? null : [];

        const timespan = time.end - time.start;
        const periodLength = timespan / dataLength;
        const data = new Array(dataLength)
            .fill()
            .map(() => Object.fromEntries(extraModels?.map((x) => [x.key, 0]) ?? []));
        for (const attr of extraDetections ?? []) {
            const index = Math.floor((attr.timestamp - time.start) / periodLength);
            if (index < 0 || index >= dataLength) {
                console.log('Warning: extra detection outside of timeline range');
                continue;
            }
            data[index][attr.key] += 1;
        }

        // Apply timeline edits to data.
        const frame = (x) => Math.trunc((x / timespan) * dataLength);
        for (const edit of edits.filter((x) => x.type !== 'single')) {
            const edit_key = extraModels.find((x) => x.id === edit.model)?.key ?? 'unknown';
            const start = Math.max(0, frame(edit.start));
            const end = Math.min(dataLength, frame(edit.end));
            for (let i = start; i < end; i++) data[i][edit_key] = edit.type === 'add' ? Infinity : 0;
        }

        // Map data to get the keys which have the highest value. If there are multiple keys with the same count,
        // return the one with the lowest global count (i.e. favor the minorities).
        const maxes = data.map((x) => {
            const max = Object.entries(x).reduce(
                (r, [k, v]) => {
                    if (v > r.value || (v === r.value && keyCounts[k] < keyCounts[r.key])) {
                        r.key = k;
                        r.value = v;
                    }
                    return r;
                },
                { key: null, value: 0 },
            );
            return max.key ? extraModels.findIndex((x) => x.key === max.key) + 1 : 0;
        });

        return modelToTimeline(
            maxes,
            time,
            extraModels?.map((o) => o.name),
            extraModels?.map((o) => o.color),
            4,
        );
    }, [authorized, edits, extraDetections, extraModels, keyCounts, show, time]);

    const annotations = useMemo(() => {
        return edits
            .map((x) => ({ ...x, model: extraModels.find((y) => y.id === x.model) }))
            .filter((x) => x.model.manual_annotation && ['add', 'single'].includes(x.type));
    }, [edits, extraModels]);

    const extraBars = useMemo(() => {
        return edits
            .map((x) => ({ ...x, model: extraModels.find((y) => y.id === x.model) }))
            .filter((x) => x.type === 'single')
            .map((x) => ({
                label: x.model.name,
                data: [x.start / (time.end - time.start)],
                color: x.model.color,
                showLabel: true,
            }));
    }, [edits, extraModels, time.end, time.start]);

    return {
        extraRow,
        extraModels,
        extraBars,
        edits: {
            state: edits,
            setState: setEdits,
        },
        annotations,
    };
}
