import { Skeleton, Typography } from '@mui/material';
import { AsyncBoundary } from '../../aceapi/utils';
import PaperCard from './PaperCard';
import { useAvgRead } from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

function AsyncAvgVM({ uuid, descr = 'Average' }) {
    const { app } = useAceApp();

    const { data: avgVM } = useAvgRead({
        pathParams: { procedureId: uuid },
        queryParams: { app, plot: 'visible_mucosa' },
    });
    const avg = avgVM?.average ? Math.trunc(avgVM.average * 100) / 100 + '%' : 'Unknown (no VM data)';

    return (
        <Typography align='left'>
            <b>{descr}</b>: {avg}
        </Typography>
    );
}

export default function AvgVMCard({ noPaper = false, ...props }) {
    const content = (
        <AsyncBoundary
            fallback={
                <Typography>
                    <Skeleton animation='wave' />
                </Typography>
            }
        >
            <AsyncAvgVM {...props} />
        </AsyncBoundary>
    );

    return noPaper ? { content } : <PaperCard title='Visible Mucosa'>{content}</PaperCard>;
}
