import { IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import format from 'format-duration';
import useProcTime from '../../aceapi/hooks/useProcTime';

const ellipsis = {
    style: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
};

export default function MouseNotification({ notif, icon, clear }) {
    const navigate = useNavigate();

    const app = notif?.app ?? 'auto';
    const procedure_id = notif.dagrun.procedure_id;
    const timestamp = notif.dagrun.timestamp;
    const diagnosis = notif.dagrun.result?.diagnosis ?? 'Unavailable';
    const initiator = notif.dagrun.user?.username ?? 'Unknown';

    const time = useProcTime(app, procedure_id);
    const time_str = format(timestamp - time.start);

    return (
        <MenuItem onClick={() => navigate(`/${app}/procedures/${procedure_id}#dagrun-${notif.dagrun.id}`)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
                primaryTypographyProps={ellipsis}
                secondaryTypographyProps={ellipsis}
                secondary={`Status: '${notif.dagrun.state}', result: '${diagnosis ?? 'Unknown'}', click to navigate (highlighted in red)`}
            >
                <b>{new Date(notif.created_at).toLocaleString()}</b>: {notif.reason} ➡ <i>timestamp=</i>
                {time_str} <i>initiator=</i>
                {initiator} <i>procedure=</i>
                {procedure_id}
            </ListItemText>
            <IconButton onClick={clear}>
                <ClearIcon />
            </IconButton>
        </MenuItem>
    );
}
