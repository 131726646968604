import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Divider, Typography } from '@mui/material';
import LoginForm from '../components/Login/LoginForm';
import Box from '@mui/material/Box';

export default function Login(props) {
    return (
        <Grid container spacing={3} justifyContent='center'>
            <Grid item xs={10}>
                <Paper sx={{ p: 2, margin: 'auto', maxWidth: 500, flexGrow: 1 }}>
                    <Typography variant='h3' align='center'>
                        Login
                    </Typography>
                    <Divider />
                    <Box m={2} pt={3}>
                        <LoginForm {...props} />
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
}
