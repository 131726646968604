import { FormControl, FormControlLabel, Switch } from '@mui/material';
import { Controller } from 'react-hook-form';

export default function SwitchWidget({ field, control }) {
    return (
        <Controller
            name={field.name}
            control={control}
            render={(props) => (
                <FormControl component='fieldset'>
                    <FormControlLabel
                        control={<Switch {...props.field} checked={Boolean(props.field.value)} />}
                        label={field.label}
                    />
                </FormControl>
            )}
        />
    );
}
