import { useParams } from 'react-router-dom';
import useAuthorized from './useAuthorized';
import useProcTime from './useProcTime';
import format from 'format-duration';
import { useAceApp } from '../../components/Menu/ReportAppSelector';
import { useProceduresCaecum, useProceduresCaecumDebug } from '../aceComponents';
import { useMemo } from 'react';

export default function useProcCaecum(enabled = true, debug = false) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const authorized = useAuthorized();
    const { data: caecum } = useProceduresCaecum(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: enabled && authorized },
    );
    const { data: caecumDebug } = useProceduresCaecumDebug(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: enabled && debug && authorized },
    );
    const time = useProcTime();

    const timestamp = useMemo(() => caecum?.timestamp ?? null, [caecum]);
    const reached = useMemo(() => timestamp !== null, [timestamp]);
    const withdrawalTime = useMemo(
        () => (reached ? format(time.internalEnd - timestamp) : null),
        [reached, time.internalEnd, timestamp],
    );
    const relativeTs = useMemo(() => (caecum?.timestamp ?? time.start) - time.start, [caecum, time.start]);

    return useMemo(
        () =>
            enabled
                ? {
                      reached,
                      timestamp,
                      relativeTs,
                      withdrawalTime,
                      debug: (debug && caecumDebug) ?? null,
                  }
                : null,
        [enabled, reached, timestamp, relativeTs, withdrawalTime, debug, caecumDebug],
    );
}
