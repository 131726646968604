import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExploreList } from '../../../../aceapi/aceComponents';
import { useMemo, useState } from 'react';
import { MetricsGrid } from './OpticalDiagnosisUtils';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Slider, Stack } from '@mui/material';

const POLYP_FILTERS = [
    { name: 'Patient Id', get: (polyp) => polyp.id },
    { name: 'In-situ', get: (polyp) => polyp.in_situ },
    { name: 'Caliper', get: (polyp) => polyp.ex_situ },
    {
        name: 'Histology',
        get: (polyp) => polyp.histo_size,
    },
];
const METRICS_COLS = [
    { field: 'Patient Id', headerMetric: 'Patient Id', flex: 0.5 },
    { field: 'In-situ', headerName: 'In-situ', flex: 0.5 },
    { field: 'Caliper', headerName: 'Caliper', flex: 0.5 },
    { field: 'Histology', headerName: 'Histology', flex: 0.5 },
];
const isCloseEnough = (sizeA, sizeB, sizeC, tolerance) => {
    // Tolerance: 2mm: each size in sizes should be within 2mm of all others
    const sizes = [sizeA, sizeB, sizeC];
    const minSize = Math.min(...sizes);
    const maxSize = Math.max(...sizes);
    return Math.abs(maxSize - minSize) <= tolerance;
};
/**
 * Compare In-situ vs Caliper vs Histology
 * Work in progress
 */
export function SizingComparison() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });

    const [tolerance, setTolerance] = useState(2);

    const [rows, disagRows] = useMemo(() => {
        const rows = [];
        const disagRows = [];
        let i = 0,
            j = 0;
        explore.diag_histo.forEach((polyp) => {
            if (isCloseEnough(polyp.in_situ, polyp.ex_situ, polyp.histo_size, tolerance)) {
                rows[i] = { id: i };
                POLYP_FILTERS.forEach(({ name, get }) => {
                    rows[i][name] = get(polyp);
                });
                i++;
            } else {
                disagRows[j] = { id: j };
                POLYP_FILTERS.forEach(({ name, get }) => {
                    disagRows[j][name] = get(polyp);
                });
                j++;
            }
        });
        return [rows, disagRows];
    }, [explore.diag_histo, tolerance]);
    return (
        <>
            <Stack direction='column'>
                <Typography variant='h4'>Sizing Comparison</Typography>
                <Typography>Tolerance: </Typography>
                <Slider
                    aria-label='Tolerance'
                    value={tolerance}
                    defaultValue={2}
                    onChange={(_, value) => setTolerance(value)}
                    step={1}
                    min={0}
                    max={10}
                    valueLabelDisplay='on'
                />
            </Stack>
            <Stack direction='row' spacing={2} width='100%' height='100%'>
                <Box alignItems='center' width='50%' height='100%'>
                    <Typography variant='h6'>Agreement</Typography>
                    <MetricsGrid name='SizeMatch' rows={rows} columns={METRICS_COLS} />
                </Box>
                <Box alignItems='center' width='50%' height='100%'>
                    <Typography variant='h6'>Disagreement</Typography>
                    <MetricsGrid name='SizeMismatches' rows={disagRows} columns={METRICS_COLS} />
                </Box>
            </Stack>
        </>
    );
}
