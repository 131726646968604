import useD3 from '../d3/useD3';
import useShow from '../../../aceapi/hooks/useShow';
import useVMGradient from '../../report/useVMGradient';
import { useCallback } from 'react';

export default function TimelineVMGradient(props) {
    const { thickness, layout, height, ...rest } = props;

    const { width, padding } = layout;

    const { top: pt, right: pr, bottom: pb, left: pl } = padding;

    const show = useShow();
    const vmgradient = useVMGradient({ show: show.vm_card });

    const drawGradient = useCallback(
        (chart) => {
            chart
                .attr('viewBox', [0, 0, width + pl + pr, height + pt + pb + 8])
                .attr('pointer-events', 'none')
                .append('defs')
                .append('linearGradient')
                .attr('id', 'gradient')
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%')
                .selectAll('stop')
                .data(vmgradient)
                .join('stop')
                .attr('offset', (d, i) => `${(i * 100) / (vmgradient.length - 1)}%`)
                .attr('stop-color', (d) => d);
            chart
                .append('rect')
                .attr('x', pl)
                .attr('y', height + pt)
                .attr('width', width)
                .attr('height', thickness)
                .attr('fill', 'url(#gradient)');
        },
        [height, pb, pl, pr, pt, thickness, vmgradient, width],
    );

    const ref = useD3(
        (chart) => {
            if (Array.isArray(vmgradient) && vmgradient.length > 0) drawGradient(chart);
            return () => chart.selectAll('*').remove();
        },
        [drawGradient],
    );

    return show.vm_card && <svg ref={ref} {...rest} />;
}
