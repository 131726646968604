import { Button } from '@mui/material';
import { AsyncBoundary } from '../../aceapi/utils';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useDownloadAnnotationRead, useDownloadMetadataRead } from '../../aceapi/aceComponents';

function AsyncBucketDownloadButton({ resourceID, uuid, filename, children }) {
    const { app } = useAceApp();

    const { data: annotation } = useDownloadAnnotationRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: resourceID === 0 },
    );

    const { data: metadata } = useDownloadMetadataRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: resourceID === 1 },
    );

    const file = resourceID === 0 ? annotation : metadata;
    const notfound = !file?.file_url;

    return (
        <Button
            disabled={notfound}
            variant='contained'
            download={`${uuid}_${filename}`}
            href={file.file_url}
            target='_blank'
        >
            {notfound ? <strike>{children}</strike> : children}
        </Button>
    );
}

export default function BucketDownloadButton(props) {
    return (
        <AsyncBoundary
            fallback={
                <LoadingButton loading variant='contained' startIcon={<SaveIcon />} loadingPosition='start'>
                    Generating URL
                </LoadingButton>
            }
        >
            <AsyncBucketDownloadButton {...props} />
        </AsyncBoundary>
    );
}
