import { MenuItem, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import Box from '@mui/material/Box';
import { timedelta } from '../utils';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function DateSelector({ value, onChange, options, disableCustom }) {
    const [curOption, setCurOption] = useState(
        options.find((x) => x.value?.name === value?.name)?.key ?? (value?.name === 'custom' ? 'custom' : 'lifetime'),
    );
    const [custom, setCustom] = useState(value?.name === 'custom');
    const [from, setFrom] = useState(value?.name === 'custom' ? value.from : Date.now() - timedelta.YEAR);
    const [to, setTo] = useState(value?.name === 'custom' ? value.to : Date.now());

    const handleChange = (e) => {
        const value = e.target.value;
        setCurOption(value);
        if (value === 'custom') {
            setCustom(true);
            return onChange({ name: 'custom', from, to });
        }
        setCustom(false);
        return onChange(options.find((x) => x.key === value)?.value ?? {});
    };

    const handleFromChange = (date) => {
        const fdate = new Date(date);
        fdate.setHours(0, 0, 0, 0);
        const ts = fdate.getTime();
        setFrom(ts);
        onChange({ name: 'custom', from: ts, to });
    };

    const handleToChange = (date) => {
        const fdate = new Date(date);
        fdate.setHours(23, 59, 59, 999);
        const ts = fdate.getTime();
        setTo(ts);
        onChange({ name: 'custom', from, to: ts });
    };

    return (
        <>
            <TextField
                select
                value={curOption}
                onChange={handleChange}
                sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minWidth: '25ch' }}
            >
                {options.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.name}
                    </MenuItem>
                ))}
                {!disableCustom && <MenuItem value='custom'>Custom</MenuItem>}
            </TextField>
            {custom && (
                <Box mt={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack direction='row' spacing={2}>
                            <DesktopDatePicker
                                label='From'
                                inputFormat='dd/MM/yyyy'
                                value={from}
                                onChange={handleFromChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            <DesktopDatePicker
                                label='To'
                                inputFormat='dd/MM/yyyy'
                                value={to}
                                onChange={handleToChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Box>
            )}
        </>
    );
}
