import { Autocomplete, Button, Grid, Stack, TextField } from '@mui/material';
import PaperCard from '../components/report/PaperCard';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useVideoHashesCaddieSummary } from '../aceapi/aceComponents';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import ForceSelectApp from '../components/report/ForceSelectApp';
import Box from '@mui/material/Box';

export default function CatUploadList() {
    const { app } = useAceApp();
    const { data: hashes } = useVideoHashesCaddieSummary({ queryParams: { app } }, { enabled: app !== 'all' });

    const [search, setSearch] = useState(null);
    const filteredRows = useMemo(
        () =>
            search === null || !Array.isArray(hashes)
                ? hashes
                : hashes.filter(
                      (x) =>
                          x.display_name.toLowerCase().includes(search) ||
                          x.patient_id.toLowerCase().includes(search) ||
                          x.hospital.toLowerCase().includes(search) ||
                          x.procedure_id.toLowerCase().includes(search),
                  ),
        [hashes, search],
    );

    const searchOptions = useMemo(
        () =>
            Array.isArray(hashes)
                ? hashes
                      .map((x) => [x.display_name, x.patient_id, x.hospital, x.procedure_id])
                      .flat()
                      .filter((v, i, a) => a.indexOf(v) === i)
                      .sort()
                : [],
        [hashes],
    );

    const columns = [
        {
            field: 'display_name',
            headerName: 'CAT PID',
            flex: 0.2,
        },
        {
            field: 'patient_id',
            headerName: 'Patient ID',
            flex: 0.2,
        },
        {
            field: 'hospital',
            headerName: 'Hospital/User',
            flex: 0.4,
        },
        {
            field: 'procedure_id',
            headerName: 'Open',
            flex: 0.1,
            minWidth: 80,
            renderCell: (x) => (
                <Button variant='contained' href={`/${app}/procedures/${x.value}`}>
                    Open
                </Button>
            ),
            sortable: false,
        },
    ];

    return (
        <Grid container spacing={3}>
            <PaperCard xs={12} title='CAT Uploads' variant='h2' align='center'>
                <ForceSelectApp>
                    <Stack m={2} spacing={2}>
                        <Autocomplete
                            freeSolo
                            options={searchOptions}
                            onChange={(_, value) => setSearch(value?.toLowerCase() || null)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Search'
                                    variant='outlined'
                                    fullWidth
                                    helperText='You can search by pid/patient id/procedure id/hospital'
                                />
                            )}
                        />
                        <Box>
                            <Button variant='outlined' onClick={() => setSearch(null)} disabled={search === null}>
                                Clear search
                            </Button>
                        </Box>
                        <DataGrid
                            columns={columns}
                            rows={filteredRows}
                            getRowId={(row) => row.procedure_id}
                            autoHeight
                        />
                    </Stack>
                </ForceSelectApp>
            </PaperCard>
        </Grid>
    );
}
