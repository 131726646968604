import { useEffect } from 'react';

/**
 * This hook allows to conveniently handle keyboard events and includes options for modifier keys.
 */
export default function useKeyBoardEvent(props) {
    const { watchKeys, shiftKey = false, ctrlKey = false, onKeyDown = null, onKeyUp = null, onKeyPress = null } = props;

    useEffect(() => {
        function isInputTarget(e) {
            return ['INPUT', 'TEXTAREA', 'SELECT'].includes(e.target.tagName);
        }

        function isWatchedKey(e) {
            return !isInputTarget(e) && watchKeys.includes(e.key) && e.shiftKey === shiftKey && e.ctrlKey === ctrlKey;
        }

        const events = [
            { type: 'keydown', action: onKeyDown },
            { type: 'keyup', action: onKeyUp },
            { type: 'keypress', action: onKeyPress },
        ]
            .filter((e) => e.action !== null)
            .map((e) => ({ ...e, handler: (keyEvent) => isWatchedKey(keyEvent) && e.action(keyEvent) }));

        events.forEach(({ type, handler }) => document.addEventListener(type, handler));
        return () => events.forEach(({ type, handler }) => document.removeEventListener(type, handler));
    }, [watchKeys, onKeyDown, onKeyUp, onKeyPress, shiftKey, ctrlKey]);
}
