import * as d3 from 'd3';
import format from 'format-duration';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import TimelineCursor from './TimelineCursor';
import useD3 from '../d3/useD3';
import TimelineVMGradient from './TimelineVMGradient';
import TimelineFrames from './TimelineFrames';
import TimelineBrush from './TimelineBrush';

const defaultLayout = {
    width: 1000,
    columnHeight: 30,
    columnFillRatio: 0.6,
    axisHeight: 24,
    cardWidth: 200,
    padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    backgroundColor: 'transparent',
    separatorColor: '#a0a0a0',
};

export default function TimelineContent(props) {
    const { data, seeker, time, frames, hoveredFrame, disablePinning = false, brush = null } = props;

    const layout = {
        ...defaultLayout,
        ...props.layout,
    };

    const { width, columnHeight, columnFillRatio, axisHeight, cardWidth, padding, backgroundColor, separatorColor } =
        layout;

    const { top: pt, right: pr, bottom: pb, left: pl } = padding;

    const columnCount = data.length;
    const height = columnHeight * columnCount + axisHeight;
    const demiSpace = (columnHeight * (1 - columnFillRatio)) / 2;

    const timespan = time.end - time.start;

    const drawChart = useCallback(
        (chart) => {
            chart
                .attr('viewBox', [0, 0, width + pl + pr, height + pt + pb])
                .attr('preserveAspectRatio', 'xMidYMid meet')
                .style('background-color', backgroundColor)
                .append('g')
                .attr('transform', `translate(${pl}, ${pt})`)
                .selectAll('g')
                .data(data)
                .join('g')
                .attr('transform', (d, i) => `translate(0, ${i * columnHeight})`)
                .each(function (row) {
                    const fullView = d3.select(this);
                    // append a black rectangle for the background
                    fullView
                        .append('rect')
                        .attr('x', 0)
                        .attr('y', -demiSpace)
                        .attr('width', width)
                        .attr('height', columnHeight)
                        .attr('fill', backgroundColor);
                    fullView
                        .append('text')
                        .text(row.group)
                        .attr('x', -70)
                        .attr('y', columnHeight / 2)
                        .attr('text-anchor', 'start')
                        .attr('font-size', '.7em')
                        .attr('font-weight', 'bold')
                        .attr('fill', row.color);
                    // a group contains data
                    fullView
                        .selectAll('g')
                        .data(row.data)
                        .join('g')
                        .each(function (category) {
                            const rowView = d3.select(this);
                            // hovering on the rectangle should show the label and the start and end time
                            rowView
                                .selectAll('rect')
                                .data(category.data)
                                .join('rect')
                                .attr('x', (entry) => (entry.start * width) / timespan)
                                .attr('y', 0)
                                .attr('width', (entry) => ((entry.end - entry.start) * width) / timespan)
                                .attr('height', columnHeight * columnFillRatio)
                                .attr('fill', (entry) => entry.color ?? category.color)
                                // show a card on mouseover that contains the label and the start and end time, and the duration
                                .on('mouseover', function (event, entry) {
                                    d3.select(this).attr('opacity', 0.5);
                                    const mouse = d3.pointer(event);
                                    // show card slightly on the right of the mouse pointer or on the left if the mouse pointer is too close to the right border
                                    const x =
                                        mouse[0] + 10 > width - cardWidth ? mouse[0] - cardWidth + 50 : mouse[0] + 100;
                                    chart
                                        .append('rect')
                                        .classed('card', true)
                                        .attr('x', x)
                                        .attr('y', mouse[1])
                                        .attr('width', cardWidth)
                                        .attr('height', 70)
                                        .attr('fill', 'white')
                                        .attr('stroke', 'black')
                                        .attr('stroke-width', 1)
                                        .attr('rx', 5)
                                        .attr('ry', 5);
                                    chart
                                        .append('text')
                                        .classed('card', true)
                                        .attr('x', x + 10)
                                        .attr('y', mouse[1] + 20)
                                        .attr('font-size', '1em')
                                        .attr('font-weight', 'bold')
                                        .text(entry.val);
                                    chart
                                        .append('text')
                                        .classed('card', true)
                                        .attr('x', x + 10)
                                        .attr('y', mouse[1] + 40)
                                        .attr('font-size', '.7em')
                                        .text(`${entry.val}: ${format(entry.start)} - ${format(entry.end)}`);
                                    chart
                                        .append('text')
                                        .classed('card', true)
                                        .attr('x', x + 10)
                                        .attr('y', mouse[1] + 60)
                                        .attr('font-size', '.7em')
                                        .text(`Duration: ${format(entry.end - entry.start)}`);
                                })
                                .on('mouseout', function () {
                                    d3.select(this).attr('opacity', 1);
                                    chart.selectAll('.card').remove();
                                });
                        })
                        .append('g')
                        .selectAll('g')
                        .data(row.bars ?? [])
                        .join('g')
                        .each(function (barGroup) {
                            const rowView = d3.select(this);
                            rowView
                                .selectAll('line')
                                .data(barGroup.data)
                                .join('line')
                                .attr('x1', (entry) => entry * width)
                                .attr('y1', -demiSpace)
                                .attr('x2', (entry) => entry * width)
                                .attr('y2', columnHeight - demiSpace)
                                .attr('stroke', barGroup.color)
                                .attr('stroke-width', 1);
                            // if barGroup has showLabel, show the label as text on top of the bar
                            if (barGroup.showLabel) {
                                rowView
                                    .selectAll('text')
                                    .data(barGroup.data)
                                    .join('text')
                                    .attr('x', (entry) => entry * width)
                                    .attr('y', -demiSpace - 5)
                                    .attr('text-anchor', 'middle')
                                    .attr('font-size', '.6em')
                                    .attr('font-weight', 'bold')
                                    .attr('fill', barGroup.color)
                                    .text(barGroup.label);
                            }
                        });
                });
            // add separator lines between the rows
            chart
                .append('g')
                .attr('transform', `translate(${pl}, ${pt})`)
                .selectAll('line')
                .data(data)
                .join('line')
                .attr('x1', 0)
                .attr('y1', (_, i) => i * columnHeight - demiSpace)
                .attr('x2', width)
                .attr('y2', (_, i) => i * columnHeight - demiSpace)
                .attr('stroke', separatorColor)
                .attr('stroke-width', 1);
            // time axis at the bottom with ticks and using the "format" function to format the ticks
            chart
                .append('g')
                .attr('transform', `translate(${pl}, ${height + pt - axisHeight - demiSpace})`)
                .call(
                    d3
                        .axisBottom(d3.scaleLinear().domain([0, timespan]).range([0, width]))
                        .ticks(10)
                        .tickFormat(format),
                );
            // but we also want to show the last tick with the total time
            chart
                .append('text')
                .text(format(timespan))
                .attr('x', width + pl)
                .attr('y', height + pt - axisHeight + 18)
                .attr('text-anchor', 'middle')
                .attr('font-size', '0.6em')
                .attr('fill', 'black');
        },
        [
            axisHeight,
            backgroundColor,
            cardWidth,
            columnFillRatio,
            columnHeight,
            data,
            demiSpace,
            height,
            pb,
            pl,
            pr,
            pt,
            separatorColor,
            timespan,
            width,
        ],
    );

    const ref = useD3(
        (chart) => {
            drawChart(chart);
            return () => chart.selectAll('*').remove();
        },
        [data, drawChart],
    );

    return (
        <Box width='100%' position='relative'>
            <svg ref={ref} />
            <TimelineCursor
                seeker={seeker}
                layout={layout}
                height={height}
                demiSpace={demiSpace}
                style={{ position: 'absolute', top: 0, left: 0 }}
            />
            <TimelineVMGradient
                thickness={8}
                layout={layout}
                height={height}
                style={{ position: 'absolute', top: 0, left: 0 }}
            />
            {!disablePinning && (
                <TimelineFrames
                    frames={frames}
                    hoveredFrame={hoveredFrame}
                    layout={layout}
                    height={height}
                    demiSpace={demiSpace}
                    style={{ position: 'absolute', top: 0, left: 0 }}
                />
            )}
            {brush && (
                <TimelineBrush
                    brush={brush}
                    layout={layout}
                    height={height}
                    demiSpace={demiSpace}
                    style={{ position: 'absolute', top: 0, left: 0 }}
                />
            )}
        </Box>
    );
}
