import PaperCard from './PaperCard';
import { AsyncBoundary } from '../../aceapi/utils';
import { List, ListItem, ListItemText, Skeleton, Stack, Switch, TextField } from '@mui/material';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useParams } from 'react-router-dom';
import { useProceduresFalseAlarms } from '../../aceapi/aceComponents';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

function AsyncFalseAlarmCard(props) {
    const { app } = useAceApp();
    const { uuid } = useParams();

    const { onlyWithdrawal, threshold } = props;
    const [debouncedThreshold] = useDebounce(threshold, 500);

    const { data: faStats } = useProceduresFalseAlarms({
        pathParams: { procedureId: uuid },
        queryParams: { app, only_withdrawal: onlyWithdrawal, threshold: debouncedThreshold },
    });

    return (
        <List dense>
            <ListItem>
                <Typography>
                    <b>Average false alarm rate per minute</b>: {faStats.count_per_minute.toFixed(2)}
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    <b>Total false alarms</b>: {faStats.count}
                </Typography>
            </ListItem>
        </List>
    );
}

export default function FalseAlarmCard(props) {
    const [onlyWithdrawal, setOnlyWithdrawal] = useState(true);
    const [threshold, setThreshold] = useState(200);

    return (
        <PaperCard title='False Alarms Stats'>
            <Stack direction='column'>
                <Stack direction='row' spacing={2} pt={2} pl={2} alignItems='center'>
                    <Switch checked={onlyWithdrawal} onChange={(e) => setOnlyWithdrawal(e.target.checked)} /> Only
                    Withdrawal
                    <TextField
                        label='Threshold'
                        type='number'
                        variant='outlined'
                        value={threshold}
                        onChange={(e) => setThreshold(Math.min(10000, Math.max(0, e.target.value)))}
                    />
                </Stack>
                <AsyncBoundary
                    fallback={
                        <List dense>
                            {Array(7)
                                .fill()
                                .map((_, i) => (
                                    <ListItemText key={i}>
                                        <Skeleton animation='wave' />
                                    </ListItemText>
                                ))}
                        </List>
                    }
                >
                    <AsyncFalseAlarmCard onlyWithdrawal={onlyWithdrawal} threshold={threshold} {...props} />
                </AsyncBoundary>
            </Stack>
        </PaperCard>
    );
}
