import { AsyncBoundary } from '../../../../aceapi/utils';
import ConfirmButton from '../../../dialogs/ConfirmButton';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExportPackageCreate } from '../../../../aceapi/aceComponents';

function AsyncGGSummaryForceRefresh() {
    const { app } = useAceApp();
    const { mutateAsync: createPackage } = useGgExportPackageCreate();
    const queryClient = useQueryClient();

    const [packageLoading, setPackageLoading] = useState(false);

    return (
        <ConfirmButton
            variant='contained'
            color='primary'
            action='Force refresh to the latest form data (please do not overuse)'
            ButtonType={LoadingButton}
            loading={packageLoading}
            disabled={packageLoading}
            loadingPosition='start'
            onConfirm={() => {
                setPackageLoading(true);
                createPackage({
                    pathParams: { app },
                    body: {
                        exportType: 'forms',
                        forceRefresh: true,
                    },
                }).then(() => {
                    queryClient.invalidateQueries({ queryKey: ['gg'] }).then(() => setPackageLoading(false));
                });
            }}
        >
            Force Refresh Form Data
        </ConfirmButton>
    );
}

export default function GGSummaryForceRefresh() {
    return (
        <AsyncBoundary>
            <AsyncGGSummaryForceRefresh />
        </AsyncBoundary>
    );
}
