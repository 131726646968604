import { polypCountPerDiagnosis } from './utils';
import Typography from '@mui/material/Typography';
import format from 'format-duration';
import { AsyncBoundary } from '../../aceapi/utils';
import { Skeleton, Stack } from '@mui/material';
import useShow from '../../aceapi/hooks/useShow';
import useExtraData from './useExtraData';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useAvgRead } from '../../aceapi/aceComponents';
import Grid from '@mui/material/Grid';
import PaperCard from './PaperCard';
import useProcTime from '../../aceapi/hooks/useProcTime';

const SummaryPaper = (props) => (
    <PaperCard xs={6} p={3} paperProps={{ variant: 'outlined' }} paperStyle={{ backgroundColor: '#eeeeee' }}>
        <Typography variant='body1' align='center'>
            <b>{props.children}</b>
        </Typography>
    </PaperCard>
);

function AsyncShortEventSummary({ uuid, frames, caecum, withdrawal, caq = false }) {
    const { app } = useAceApp();
    const polypCounts = polypCountPerDiagnosis(frames).filter(([diagnosis]) => diagnosis !== 'uncertain');
    const { data: avgVM } = useAvgRead({
        pathParams: { procedureId: uuid },
        queryParams: { app, plot: 'visible_mucosa' },
    });
    const show = useShow();
    const { extraRow } = useExtraData({ show: show.extra_models });
    const retroflexion = extraRow?.find((x) => x.val.toLowerCase() === 'retroflexion')?.start;
    const GWT = retroflexion && caecum < retroflexion ? format(retroflexion - caecum) : withdrawal;

    const time = useProcTime(null, uuid);
    const elapsedTime = time.internalEnd - time.start;

    return (
        <div>
            {caq ? (
                <Grid container spacing={1}>
                    <SummaryPaper>
                        Retroflexion:
                        <br />
                        {retroflexion ? format(retroflexion) : '-'}
                    </SummaryPaper>
                    <SummaryPaper>
                        Caecum:
                        <br />
                        {caecum ? format(caecum) : '-'}
                    </SummaryPaper>
                    <SummaryPaper>
                        Gross withdrawal time:
                        <br />
                        {caecum ? GWT : '-'}
                    </SummaryPaper>
                    <SummaryPaper>
                        Total procedure time:
                        <br />
                        {elapsedTime ? format(elapsedTime) : '-'}
                    </SummaryPaper>
                </Grid>
            ) : (
                <>
                    <Typography variant='h5' color='primary.dark' align='center'>
                        Summary
                    </Typography>
                    {polypCounts.map(([diagnosis, count]) => (
                        <Typography key={diagnosis} variant='body1' align='center'>
                            {count} {diagnosis}
                        </Typography>
                    ))}
                    <Typography variant='body1' align='center'>
                        Average VM: {Math.round(avgVM.average * 100) / 100}%
                    </Typography>
                    <Typography variant='body1' align='center'>
                        Caecal intubation: {caecum ? 'yes' : 'no'}
                    </Typography>
                    <Typography variant='body1' align='center'>
                        Withdrawal time: {format(withdrawal)}
                    </Typography>
                    <Typography variant='body1' align='center'>
                        Retroflexion: {retroflexion ? format(retroflexion) : 'no'}
                    </Typography>
                </>
            )}
        </div>
    );
}

export default function ShortEventSummary(props) {
    return (
        <AsyncBoundary
            fallback={
                <Stack width='100%' alignItems='center'>
                    <Typography variant='h5' color='primary.dark' align='center'>
                        Summary
                    </Typography>
                    {Array(5)
                        .fill()
                        .map((_, i) => (
                            <Typography key={i} variant='body1'>
                                <Skeleton width={150 + ((i * (17 * i + 1) * 36) % 150)} animation='wave' />
                            </Typography>
                        ))}
                </Stack>
            }
        >
            <AsyncShortEventSummary {...props} />
        </AsyncBoundary>
    );
}
