import { useContext } from 'react';
import { TokenContext } from '../../config/contexts';

const handler = (defaultValue) => ({
    get: (target, name) => (Object.prototype.hasOwnProperty.call(target, name) ? target[name] : defaultValue),
});

export default function useShow() {
    const token = useContext(TokenContext);
    const components = token.parse().user.components;
    const show = Object.fromEntries(components.map((x) => [x, true]));
    return new Proxy(show, handler(false));
}
