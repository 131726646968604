import { IconButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const ellipsis = {
    style: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
};

export default function CustomNotification({ notif, icon, clear }) {
    return (
        <MenuItem>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText
                primaryTypographyProps={ellipsis}
                secondaryTypographyProps={ellipsis}
                secondary={notif.custom ?? 'no message'}
            >
                <b>{new Date(notif.created_at).toLocaleString()}</b>: <i>{notif.reason}</i>
            </ListItemText>
            <IconButton onClick={clear}>
                <ClearIcon />
            </IconButton>
        </MenuItem>
    );
}
