import useExtraData from '../../components/report/useExtraData';
import useProcTime from './useProcTime';
import { useProceduresCaecum } from '../aceComponents';
import { useAceApp } from '../../components/Menu/ReportAppSelector';
import { useParams } from 'react-router-dom';
import useAuthorized from './useAuthorized';
import useShow from './useShow';
import { useMemo } from 'react';

export default function useProcTimeDetails(overrideApp = null, overrideUuid = null) {
    const ace = useAceApp();
    const params = useParams();
    const app = overrideApp ?? ace.app;
    const uuid = overrideUuid ?? params.uuid;
    const authorized = useAuthorized(overrideApp, overrideUuid);
    const show = useShow();

    const time = useProcTime();
    const { extraRow } = useExtraData({ show: show.extra_models });
    const { data: caecum } = useProceduresCaecum(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: authorized },
    );

    const caecumRelativeTs = useMemo(() => (caecum?.timestamp ?? time.start) - time.start, [caecum, time.start]);

    const cleaningTime = useMemo(
        () =>
            extraRow
                ?.filter((x) => x.val.toLowerCase().includes('cleaning'))
                .map((x) => Math.max(x.end - Math.max(x.start, caecumRelativeTs), 0))
                .reduce((a, b) => a + b, 0) ?? 0,
        [extraRow, caecumRelativeTs],
    );

    const interventionTime = useMemo(
        () =>
            extraRow
                ?.filter((x) => /(therap|forcep|biopsy)/.test(x.val.toLowerCase()))
                .map((x) => Math.max(x.end - Math.max(x.start, caecumRelativeTs), 0))
                .reduce((a, b) => a + b, 0) ?? 0,
        [extraRow, caecumRelativeTs],
    );

    const withdrawalTime = useMemo(
        () => time.end - (caecum?.timestamp ?? time.start) - cleaningTime - interventionTime,
        [time, caecum, cleaningTime, interventionTime],
    );

    return useMemo(
        () => ({
            caecumRelativeTs,
            cleaningTime,
            interventionTime,
            withdrawalTime,
        }),
        [caecumRelativeTs, cleaningTime, interventionTime, withdrawalTime],
    );
}
