import { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { type2component } from '../summary/utils';

export default function useDebugOptions(isStaff, options) {
    const [state, setState] = useState(Object.fromEntries(options.map((s) => [s.key, s.defaultValue])));

    const setValue = (key, newValue) => {
        setState((prevState) => ({ ...prevState, [key]: newValue }));
    };

    const render = () => {
        return (
            <Stack
                direction='column'
                spacing={1}
                sx={{
                    overflowY: 'auto',
                    maxHeight: '12rem',
                }}
            >
                {options.map((s) => (
                    <Typography key={s.key} component='span' pt={1}>
                        {type2component[s.type].component(s.key, state[s.key], setValue, s.extraProps ?? {})}
                        {s.name}
                    </Typography>
                ))}
            </Stack>
        );
    };

    return {
        state,
        render,
    };
}
