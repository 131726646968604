import { Box } from '@mui/material';

export default function FlexBox({ children, ...props }) {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                ...props,
            }}
        >
            {children}
        </Box>
    );
}
