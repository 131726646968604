import { Autocomplete, CircularProgress, List, ListItemText, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { useProceduresQuickSearch } from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

export default function ProcedureQuickSearch() {
    const [inputValue, setInputValue] = useState('');

    const { app } = useAceApp();
    const { data, isLoading } = useProceduresQuickSearch({ queryParams: { app, q: inputValue } }, { suspense: false });
    const options = data?.results ?? [];

    const queryClient = useQueryClient();
    const throttledFetch = useMemo(
        () =>
            debounce(async (val) => {
                setInputValue(val);
                await queryClient.invalidateQueries({ queryKey: ['procedures', 'quick_search'] });
            }, 100),
        [queryClient],
    );

    const navigate = useNavigate();

    return (
        <Autocomplete
            autoHighlight
            clearOnEscape
            disablePortal
            getOptionLabel={(option) => option.procedure_id}
            filterOptions={(x) => x}
            loading={isLoading}
            options={options}
            renderOption={(props, option) => (
                <List {...props}>
                    <ListItemText>
                        <Typography
                            variant='body2'
                            color='primary.light'
                            sx={{ display: 'inline-block', minWidth: 80 }}
                        >
                            <b>({option.database.toUpperCase()})</b>
                        </Typography>
                        {option.patient_id ? (
                            <Typography variant='body2' color='primary.dark' sx={{ display: 'inline-block' }}>
                                <b>{option.patient_id}</b>
                            </Typography>
                        ) : (
                            <Typography color='text.secondary' sx={{ display: 'inline-block' }}>
                                {option.procedure_id}
                            </Typography>
                        )}
                    </ListItemText>
                    <ListItemText edge='end' align='right'>
                        {option.username}
                    </ListItemText>
                </List>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label='Search'
                    helperText='You can type any part of the patient id, username or procedure hash (results limited)'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onInputChange={async (event, newInputValue) => {
                await throttledFetch(newInputValue);
            }}
            onChange={(e, option) => {
                navigate(`/${option.database}/procedures/${option.procedure_id}`);
            }}
        />
    );
}
