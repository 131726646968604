import CaddieStream from './CaddieStream';
import ProcedureStream from './ProcedureStream';
import useShow from '../../aceapi/hooks/useShow';
import { useMemo } from 'react';

export default function VideoOrStream(
    { streaming, seeker, setUnavailable, streamProps = {}, videoProps = {} },
    ...props
) {
    const show = useShow();

    return useMemo(
        () =>
            streaming ? (
                show.stream ? (
                    <CaddieStream {...streamProps} {...props} />
                ) : null
            ) : show.video ? (
                <ProcedureStream seeker={seeker} setUnavailable={setUnavailable} noPaper {...videoProps} {...props} />
            ) : null,
        [streaming, show.stream, show.video, streamProps, props, seeker, setUnavailable, videoProps],
    );
}
