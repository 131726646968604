import useProcTime from './useProcTime';
import { useParams } from 'react-router-dom';
import { timedelta } from '../../components/summary/utils';
import {
    useAvgRead,
    useFramesRead,
    useFullFramesRead,
    useProceduresCaecum,
    useProceduresExtraDetections,
    useProceduresPredictions,
    useProceduresRecordings,
    useProceduresStatus,
} from '../aceComponents';
import { useAceApp } from '../../components/Menu/ReportAppSelector';
import useShow from './useShow';

const MAX_STREAM_LENGTH = timedelta.HOUR * 2;

export default function useProcOngoing(enabled = true) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const show = useShow();
    const time = useProcTime();
    const streaming = enabled && time?.ongoing && time?.end - time?.start < MAX_STREAM_LENGTH;

    const defaultParams = { pathParams: { procedureId: uuid }, queryParams: { app } };
    const defaultOptions = { enabled: streaming, refetchInterval: streaming ? 10000 : false, suspense: false };

    useProceduresRecordings(defaultParams, defaultOptions);
    useProceduresCaecum(defaultParams, defaultOptions);
    useFramesRead(defaultParams, defaultOptions);
    useFullFramesRead(defaultParams, defaultOptions);
    useProceduresStatus(defaultParams, defaultOptions);
    useProceduresPredictions(defaultParams, defaultOptions);
    useProceduresExtraDetections(defaultParams, { ...defaultOptions, enabled: streaming && show.extra_models });
    useAvgRead({ ...defaultParams, queryParams: { app, plot: 'visible_mucosa' } }, defaultOptions);

    return { streaming };
}
