import FlexBox from '../report/FlexBox';
import BowelPath from '../../resources/BowelPath';
import { Box, Grid, Skeleton, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import FrameDiagBox from './timeline/FrameDiagBox';
import DrawLines from '../../resources/DrawLines';
import { AsyncBoundary } from '../../aceapi/utils';
import useProcTime from '../../aceapi/hooks/useProcTime';
import { PlaceHolderFrame } from './timeline/FrameGrid';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useProceduresCaecum } from '../../aceapi/aceComponents';

const r = '8px';
const sideBoxSize = 144;
const frameSize = '6rem';

function AsyncBowelChart({ uuid, frameProps }) {
    const { frames, hoveredFrame } = frameProps;

    const { app } = useAceApp();
    const pathRef = useRef();
    const imgRef = useRef();
    const time = useProcTime();
    const { data: caecum } = useProceduresCaecum({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });

    const getPathCoordinates = (at) => {
        const path = pathRef.current;
        if (!path) return null;
        const length = path.getTotalLength();
        const v = at * length;
        return path.getPointAtLength(v);
    };

    const framePositionRatio = (frame) => {
        const cts = caecum?.timestamp;
        if (cts)
            return frame.timestamp < cts
                ? (frame.timestamp - time.start) / (cts - time.start)
                : 1 - (frame.timestamp - cts) / (time.end - cts);
        return 1 - (frame.timestamp - time.start) / (time.end - time.start);
    };

    const pFrames = Array.isArray(frames)
        ? frames.filter((x) => x.selected).map((x) => ({ ...x, at: framePositionRatio(x), color: 'blue' }))
        : [];
    if (hoveredFrame.state && pFrames.find((x) => x.frame_id === hoveredFrame.state.frame_id) === undefined)
        pFrames.push({ ...hoveredFrame.state, at: framePositionRatio(hoveredFrame.state), color: 'black' });
    const topFrames = pFrames.filter((x) => x.at >= 0.5).sort((a, b) => b.at - a.at);
    const rightFrames = pFrames.filter((x) => x.at < 0.5).sort((a, b) => b.at - a.at);

    const [frameRects, setFrameRects] = useState([]);
    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);

    const rootRef = useRef();
    let origin = rootRef.current?.getBoundingClientRect();
    origin = { x: origin?.x ?? 0, y: origin?.y ?? 0 };
    const lines = frameRects.map((v) => {
        const at = pFrames.find((x) => x.frame_id === v.key)?.at ?? 0;
        const to = getPathCoordinates(at);
        const rect = v.rect;
        const isTop = at >= 0.5;
        const offsetX = isTop ? rect.width / 2 : 0;
        const offsetY = isTop ? rect.height : rect.height / 2;
        return {
            key: v.key,
            src: { x: rect.x - origin.x + offsetX, y: rect.y - origin.y + offsetY },
            dst: { x: to.x, y: to.y },
            isTop,
        };
    });

    return (
        <div style={{ position: 'relative' }} ref={rootRef}>
            <DrawLines lines={lines} boxOffset={sideBoxSize} />
            <Grid container justifyContent='space-between'>
                <Grid item xs={12}>
                    <Box
                        height={sideBoxSize}
                        sx={{ backgroundColor: '#dedede', borderRadius: `${r} ${r} 0 ${r}`, p: 0.5 }}
                    >
                        <Stack
                            direction='row'
                            height='100%'
                            alignItems='center'
                            spacing={0.5}
                            onScroll={(e) => setScrollX(e?.target?.scrollLeft)}
                            sx={{ overflowX: 'auto', transform: 'rotateX(180deg)' }}
                        >
                            {topFrames.map((frame) => (
                                <FrameDiagBox
                                    key={frame.frame_id}
                                    frame={frame}
                                    side='top'
                                    fullframe
                                    borderThickness='2px'
                                    borderColor={frame.color}
                                    width={frameSize}
                                    height={frameSize}
                                    rectCallback={setFrameRects}
                                    frameNum={pFrames.length}
                                    scroll={scrollX}
                                    style={{ transform: 'rotateX(180deg)' }}
                                />
                            ))}
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <FlexBox alignItems='center' justifyContent='center' position='relative'>
                        <img
                            width='100%'
                            height='100%'
                            src='/colon.png'
                            alt='bowel'
                            style={{ position: 'absolute' }}
                            ref={imgRef}
                        />
                        <BowelPath uuid={uuid} pathRef={pathRef} {...frameProps} />
                    </FlexBox>
                </Grid>
                <Grid item xs='auto'>
                    <Box
                        width={sideBoxSize}
                        height='100%'
                        sx={{ backgroundColor: '#dedede', borderRadius: `0 0 ${r} ${r}` }}
                    >
                        <Stack
                            direction='column'
                            alignItems='center'
                            spacing={0.5}
                            maxHeight={imgRef.current?.height}
                            onScroll={(e) => setScrollY(e?.target?.scrollTop)}
                            sx={{ overflowY: 'auto', height: '100%' }}
                        >
                            {rightFrames.map((frame) => (
                                <FrameDiagBox
                                    key={frame.frame_id}
                                    frame={frame}
                                    side='right'
                                    fullframe
                                    borderThickness='2px'
                                    borderColor={frame.color}
                                    width={frameSize}
                                    height={frameSize}
                                    rectCallback={setFrameRects}
                                    frameNum={pFrames.length}
                                    scroll={scrollY}
                                />
                            ))}
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}

export default function BowelChart(props) {
    return (
        <AsyncBoundary fallback={<PlaceHolderBowelChart />}>
            <AsyncBowelChart {...props} />
        </AsyncBoundary>
    );
}

function PlaceHolderBowelChart() {
    return (
        <Grid container justifyContent='space-between'>
            <Grid item xs={12}>
                <Box height={sideBoxSize} sx={{ backgroundColor: '#dedede', borderRadius: `${r} ${r} 0 ${r}`, p: 0.5 }}>
                    <Stack direction='row' height='100%' alignItems='center' spacing={0.5}>
                        {Array(3)
                            .fill()
                            .map((_, i) => (
                                <PlaceHolderFrame key={i} />
                            ))}
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={7} pt={1}>
                <Skeleton variant='rounded' animation='wave' height='100%' width='100%' />
            </Grid>
            <Grid item xs='auto'>
                <Box
                    width={sideBoxSize}
                    height='100%'
                    sx={{ backgroundColor: '#dedede', borderRadius: `0 0 ${r} ${r}` }}
                >
                    <Stack
                        direction='column'
                        alignItems='center'
                        spacing={0.5}
                        sx={{ overflowY: 'auto', height: '100%' }}
                    >
                        {Array(3)
                            .fill()
                            .map((_, i) => (
                                <PlaceHolderFrame key={i} />
                            ))}
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    );
}
