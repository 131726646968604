import { ThemeOptions } from '@mui/material';

export const themeOptions: ThemeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#376092',
        },
        secondary: {
            main: '#56676e',
        },
        background: {
            default: '#cdcdcd',
            paper: '#f8f8f8',
        },
    },
    custom: {
        elevation: 1,
    },
} as ThemeOptions;
