import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import PaperCard from '../components/report/PaperCard';

export default function PageAppRedirect() {
    const { product, page, uuid } = useParams();
    const { setApp } = useAceApp();

    const { hash, pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const newPath = `/${page}/${uuid}`;
        if (pathname === newPath) return;
        setApp(product);
        navigate({
            pathname: newPath,
            hash,
            replace: true,
        });
    }, [hash, navigate, page, pathname, product, setApp, uuid]);

    return (
        <Grid container spacing={3}>
            <PaperCard xs={12} title={`Redirecting to ${product.toUpperCase()}...`} align='center' />
        </Grid>
    );
}
