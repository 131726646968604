import FrameBox from './FrameBox';
import { Stack } from '@mui/material';
import { useEffect, useRef } from 'react';

export default function FrameDiagBox({ side = 'top', ...props }) {
    const { frame, rectCallback, frameNum, scroll, style } = props;

    const stackRef = useRef();

    useEffect(() => {
        if (!rectCallback) return;
        const add = (key, rect) =>
            rectCallback((prevState) => [...prevState.filter((x) => x.key !== key), { key: key, rect: rect }]);
        const remove = (key) => rectCallback((prevState) => prevState.filter((x) => x.key !== key));
        const rect = stackRef.current?.getBoundingClientRect();
        if (!rect) return;
        add(frame.frame_id, {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
        });
        return () => remove(frame.frame_id);
    }, [frame.frame_id, rectCallback, frameNum, scroll]);

    let dir, before, writingMode;
    switch (side) {
        case 'top':
            dir = 'column';
            before = true;
            writingMode = 'horizontal-tb';
            break;
        case 'bottom':
            dir = 'column';
            before = false;
            writingMode = 'horizontal-tb';
            break;
        case 'left':
            dir = 'row';
            before = true;
            writingMode = 'vertical-lr';
            break;
        case 'right':
            dir = 'row';
            before = false;
            writingMode = 'vertical-rl';
            break;
        default:
            throw new Error('Invalid side: ' + side);
    }

    const caption = (
        <figcaption
            style={{
                background: '#333333',
                color: 'white',
                fontSize: '14px',
                textAlign: 'center',
                writingMode: writingMode,
            }}
        >
            {frame.diagnosis}
        </figcaption>
    );

    return (
        <Stack direction={dir} ref={stackRef} sx={{ ...style }}>
            {before && caption}
            <FrameBox {...props} />
            {!before && caption}
        </Stack>
    );
}
