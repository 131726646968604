import { useCallback, useEffect, useRef } from 'react';

export default function BoxSelectCanvas({ setBox, video, ...props }) {
    const canvasRef = useRef(null);

    const draw = useCallback((canvas, ctx, x1, y1, x2, y2) => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = '#00ffff';
        ctx.lineWidth = 2;
        ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.addEventListener(
            'mousemove',
            function (e) {
                findPos('move', e);
            },
            false,
        );
        canvas.addEventListener(
            'mousedown',
            function (e) {
                findPos('down', e);
            },
            false,
        );
        canvas.addEventListener(
            'mouseup',
            function (e) {
                findPos('up', e);
            },
            false,
        );
        canvas.addEventListener(
            'mouseout',
            function (e) {
                findPos('out', e);
            },
            false,
        );
        const context = canvas.getContext('2d');
        let boundingClientRect = canvas.getBoundingClientRect();
        canvas.width = boundingClientRect.width;
        canvas.height = boundingClientRect.height;
        const get_x = (x) => Math.trunc((x * video.width) / canvas.width);
        const get_y = (y) => Math.trunc((y * video.height) / canvas.height);

        let flag = false;
        let prevX = 0,
            currX = 0,
            prevY = 0,
            currY = 0;

        function findPos(res, e) {
            if (res === 'down') {
                boundingClientRect = canvas.getBoundingClientRect();
                currX = e.clientX - boundingClientRect.left;
                prevX = currX;
                currY = e.clientY - boundingClientRect.top;
                prevY = currY;
                flag = true;
            }
            if (res === 'up' || res === 'out') {
                if (!flag) return;
                flag = false;
                const x1 = Math.min(prevX, currX);
                const y1 = Math.min(prevY, currY);
                const x2 = Math.max(prevX, currX);
                const y2 = Math.max(prevY, currY);
                const x = get_x(x1);
                const y = get_y(y1);
                const w = get_x(x2) - x;
                const h = get_y(y2) - y;
                setBox({ x1: x, y1: y, x2: x + w, y2: y + h });
                draw(canvas, context, prevX, prevY, currX, currY);
            }
            if (res === 'move') {
                if (flag) {
                    boundingClientRect = canvas.getBoundingClientRect();
                    currX = e.clientX - boundingClientRect.left;
                    currY = e.clientY - boundingClientRect.top;
                    draw(canvas, context, prevX, prevY, currX, currY);
                }
            }
        }
    }, [draw, setBox, video.height, video.width]);

    return <canvas ref={canvasRef} {...props} />;
}
