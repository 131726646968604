import { useEffect, useState } from 'react';

export default function useLocalState(name, defaultValue, parse = JSON.parse) {
    const [state, setState] = useState(() => {
        const storedValue = localStorage.getItem(name);
        return storedValue !== null ? parse(storedValue) : defaultValue;
    });

    useEffect(() => {
        localStorage.setItem(name, JSON.stringify(state));
    }, [name, state]);

    const resetState = () => {
        setState(defaultValue);
    };

    return {
        state,
        setState,
        resetState,
    };
}
