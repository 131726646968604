import { useState } from 'react';
import { MenuItem, TextField } from '@mui/material';

export default function StringSelector({ value, onChange, options, extraInputProps }) {
    const [curOption, setCurOption] = useState(options.find((x) => x.value === value)?.key ?? 'ErrBadDefault');

    const handleChange = (e) => {
        const value = e.target.value;
        setCurOption(value);
        return onChange(options.find((x) => x.key === value)?.value ?? {});
    };

    return (
        <>
            <TextField
                select
                value={curOption}
                onChange={handleChange}
                sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minWidth: '25ch' }}
                {...extraInputProps}
            >
                {options.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
}
