import { List, ListItemText, Skeleton } from '@mui/material';
import { AsyncBoundary } from '../../aceapi/utils';
import { useParams } from 'react-router-dom';
import PaperCard from './PaperCard';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useProceduresEvents } from '../../aceapi/aceComponents';

function AsyncEventsCard() {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const { data: events } = useProceduresEvents({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });

    return (
        <List dense sx={{ overflowY: 'auto', maxHeight: '12rem' }}>
            {events.map((event) => (
                <ListItemText key={event.id}>
                    <b>{new Date(event.timestamp).toLocaleString('en-GB')}</b>: {event.event_name}
                </ListItemText>
            ))}
            {events.length === 0 && <ListItemText>No events recorded</ListItemText>}
        </List>
    );
}

export default function EventsCard(props) {
    return (
        <PaperCard title='Events'>
            <AsyncBoundary
                fallback={
                    <List dense>
                        {Array(7)
                            .fill()
                            .map((_, i) => (
                                <ListItemText key={i}>
                                    <Skeleton animation='wave' />
                                </ListItemText>
                            ))}
                    </List>
                }
            >
                <AsyncEventsCard {...props} />
            </AsyncBoundary>
        </PaperCard>
    );
}
