import PaperCard from '../components/report/PaperCard';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import UndoIcon from '@mui/icons-material/Undo';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
    const navigate = useNavigate();

    return (
        <Grid container spacing={3}>
            <PaperCard
                xs={12}
                title={
                    <>
                        404 <EmojiNatureIcon fontSize='36px' />
                    </>
                }
                variant='h2'
                align='center'
            >
                <Divider sx={{ mb: 2 }} />
                <Typography variant='h6'>The page you are trying to access was not found...</Typography>
                <Typography variant='body1'>
                    Make sure you have the right URL or if you think this is a bug, please contact the administrator.
                </Typography>
                <Stack direction='row' spacing={2} mt={2}>
                    <Button variant='contained' startIcon={<UndoIcon />} onClick={() => navigate(-1)}>
                        Go Back
                    </Button>
                    <Button variant='contained' startIcon={<DashboardIcon />} onClick={() => navigate('/dashboard')}>
                        Go to Dashboard
                    </Button>
                </Stack>
            </PaperCard>
        </Grid>
    );
}
