import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExploreList } from '../../../../aceapi/aceComponents';
import { useMemo, useState } from 'react';
import { Stack, Switch, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { chartColor } from '../../../Charts/ChartConfig';
import {
    DISCORDANCES,
    forAllPolyps,
    generateFakePolyps,
    greyColor,
    PerPolypToolkit,
    SENIORITIES,
    TRUSTS,
} from './OpticalDiagnosisUtils';
import { AsyncBoundary } from '../../../../aceapi/utils';
import PaperCard from '../../../report/PaperCard';
import FakeProgressBar from '../../../placeholders/FakeProgressBar';
import useShow from '../../../../aceapi/hooks/useShow';

function AsyncOpticalDiagnosisPerPolyp() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });

    const [withSeniority, setWithSeniority] = useState(true);
    const [category, setCategory] = useState('All');
    const [focusPlot, setFocusPlot] = useState();
    const [fakeData, setFakeData] = useState(false);
    const show = useShow();

    const all_endoscopists = useMemo(() => {
        return fakeData
            ? Array.from({ length: 8 }, (_, i) => 'endoscopist ' + (i + 1))
            : [...new Set(explore.all.map((p) => p.endoscopist))];
    }, [explore.all, fakeData]);

    const all_seniorities = useMemo(() => {
        return fakeData ? SENIORITIES : [...new Set(explore.all.map((p) => p.seniority))];
    }, [explore.all, fakeData]);

    const polyps = useMemo(() => {
        if (fakeData) {
            return generateFakePolyps(50);
        }
        const polyps = [];
        forAllPolyps(explore.all, (polyp, isNeo) => {
            DISCORDANCES.forEach(({ trust, condition }) => {
                if (condition(polyp)) {
                    polyps.push({ ...polyp.polyp, neoplastic: isNeo, trust });
                }
            });
        });
        return polyps;
    }, [explore.all, fakeData]);

    const seniorityData = useMemo(() => {
        const data = [];
        for (const trust of TRUSTS) {
            const row = { trust, neo: [], nonNeo: [] };
            for (const seniority of all_seniorities) {
                row.neo.push({
                    seniority: seniority,
                    count: polyps.filter((p) => p.trust === trust && p.neoplastic === true && p.seniority === seniority)
                        .length,
                });
                row.nonNeo.push({
                    seniority: seniority,
                    count: polyps.filter(
                        (p) => p.trust === trust && p.neoplastic === false && p.seniority === seniority,
                    ).length,
                });
            }
            data.push(row);
        }
        return data;
    }, [polyps, all_seniorities]);
    const endoscopistData = useMemo(() => {
        const data = [];

        for (const trust of TRUSTS) {
            const row = { trust, neo: [], nonNeo: [] };
            for (const endo of all_endoscopists.filter((e) => !focusPlot || e === focusPlot)) {
                row.neo.push({
                    endoscopist: endo,
                    count: polyps.filter((p) => p.trust === trust && p.neoplastic === true && p.endoscopist === endo)
                        .length,
                });
                row.nonNeo.push({
                    endoscopist: endo,
                    count: polyps.filter((p) => p.trust === trust && p.neoplastic === false && p.endoscopist === endo)
                        .length,
                });
            }
            data.push(row);
        }
        return data;
    }, [all_endoscopists, focusPlot, polyps]);

    const chartData = useMemo(() => {
        return withSeniority ? seniorityData : endoscopistData;
    }, [withSeniority, seniorityData, endoscopistData]);

    const all_focussed = useMemo(() => {
        return withSeniority ? all_seniorities : all_endoscopists;
    }, [withSeniority, all_seniorities, all_endoscopists]);

    return (
        <Stack spacing={2}>
            <Typography variant='h5'>Optical Diagnosis Per Polyp</Typography>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Typography>Focus: </Typography>
                <TextField
                    value={focusPlot}
                    onChange={(e) => setFocusPlot(e.target.value)}
                    select
                    SelectProps={{ native: true }}
                >
                    <option value=''>All</option>
                    {all_focussed.map((a) => (
                        <option key={a} value={a}>
                            {a}
                        </option>
                    ))}
                </TextField>
                <Typography>Category</Typography>
                <TextField
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    select
                    SelectProps={{ native: true }}
                >
                    {['All', 'Neoplastics', 'Non-Neoplastics'].map((a) => (
                        <option key={a} value={a}>
                            {a}
                        </option>
                    ))}
                </TextField>
                <Typography>Seniority: </Typography>
                <Switch
                    checked={withSeniority}
                    onChange={(e) => {
                        setWithSeniority(e.target.checked);
                        setFocusPlot();
                    }}
                />
                {show.debug && (
                    <>
                        <Typography>Debug: </Typography>
                        <Switch
                            checked={fakeData}
                            onChange={(e) => {
                                setFakeData(e.target.checked);
                            }}
                        />
                    </>
                )}
            </Stack>
            <ResponsiveContainer width='100%' height={800}>
                <BarChart data={chartData}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='trust' />
                    <YAxis yAxisId='left' orientation='left' />
                    <YAxis yAxisId='right' orientation='right' />
                    <Tooltip
                        content={<PerPolypToolkit dataKey='trust' category={category} withSeniority={withSeniority} />}
                    />
                    <Legend payloadUniqBy={(entry) => (entry.color === greyColor ? null : entry.value)} />
                    {all_focussed
                        .filter((focussed) => !focusPlot || focussed === focusPlot)
                        .map((focussed, i) => {
                            const focus = withSeniority ? 'seniority' : 'endoscopist';

                            if (category === 'All') {
                                return (
                                    <Bar
                                        yAxisId='left'
                                        dataKey={(entry) =>
                                            entry['neo'].find((item) => item[focus] === focussed)?.count +
                                            entry['nonNeo'].find((item) => item[focus] === focussed)?.count
                                        }
                                        stackId='a'
                                        fill={chartColor(i)}
                                        name={focussed}
                                        key={focussed}
                                    />
                                );
                            }
                            return (
                                <>
                                    <Bar
                                        yAxisId='left'
                                        dataKey={(entry) =>
                                            entry['neo'].find((item) => item[focus] === focussed)?.count
                                        }
                                        stackId='a'
                                        fill={category === 'Neoplastics' ? chartColor(i) : greyColor}
                                        name={focussed}
                                    />
                                    <Bar
                                        yAxisId='right'
                                        dataKey={(entry) =>
                                            entry['nonNeo'].find((item) => item[focus] === focussed)?.count
                                        }
                                        stackId='b'
                                        fill={category === 'Non-Neoplastics' ? chartColor(i) : greyColor}
                                        name={focussed}
                                    />
                                </>
                            );
                        })}
                </BarChart>
            </ResponsiveContainer>
        </Stack>
    );
}
export function OpticalDiagnosisPerPolyp() {
    return (
        <AsyncBoundary
            fallback={
                <PaperCard xs={12} title='Optical Diagnosis Per Polyp'>
                    <FakeProgressBar />
                </PaperCard>
            }
        >
            <AsyncOpticalDiagnosisPerPolyp />
        </AsyncBoundary>
    );
}
