export const normalizeString = (str) => {
    return str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, ' ');
};

export const extractIssueString = (str) => {
    return normalizeString(str)
        .replace('ignore', '')
        .replace(/[^a-z0-9\s]/gi, '')
        .trim();
};

export const caseStatuses = {
    unassigned: 'Unassigned',
    reportMissing: 'Report Missing',
    review: 'Review',
    issues: 'Issues',
    awaitingApproval: 'Awaiting Approval',
    finished: 'Finished',
    notClinicalCase: 'Not Clinical Case',
    excluded: 'Excluded',
};

export function getMostRecentSpecialComment(comments, procedureId) {
    const specialComments = comments.filter((comment) => {
        return comment.procedure_id === procedureId && Object.values(caseStatuses).includes(comment.name);
    });

    const defaultStatus = caseStatuses.unassigned;
    specialComments.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
    });
    return specialComments.length > 0 ? specialComments[0].name : defaultStatus;
}

export function getSpecialCommentIssue(comments, procedureId) {
    const specialComments = comments.filter((comment) => {
        return comment.procedure_id === procedureId && Object.values(caseStatuses).includes(comment.name);
    });

    specialComments.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
    });

    if (specialComments.length > 0 && specialComments[0].name === caseStatuses.issues) {
        return specialComments[0].body;
    }

    return null;
}

export function getIgnoredIssues(comments, procedureId) {
    return comments
        .filter((comment) => comment.procedure_id === procedureId && normalizeString(comment.name).startsWith('ignore'))
        .map((comment) => ({
            comment_id: comment.id,
            issue: extractIssueString(comment.name),
        }));
}
