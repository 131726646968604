import React from 'react';
import { useTheme } from '@mui/material';
import { Legend, PolarAngleAxis, RadialBar, RadialBarChart, ResponsiveContainer, Tooltip } from 'recharts';
import { AsyncBoundary } from '../../aceapi/utils';
import { useParams } from 'react-router-dom';
import { useAvgRead } from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

function ChartContent({
    withAvgVM = false,
    extraData = [],
    formatter = (e) => e,
    legendVerticalAlign = 'bottom',
    legendAlign = 'center',
    ...props
}) {
    const { height, cx, cy, innerRadius, outerRadius, barSize, domain } = props;

    const theme = useTheme();
    const { app } = useAceApp();
    const { uuid } = useParams();

    const { data: avgVM } = useAvgRead({
        pathParams: { procedureId: uuid },
        queryParams: { app, plot: 'visible_mucosa' },
    });
    const data = [
        ...extraData,
        ...(withAvgVM
            ? [
                  {
                      name: 'Average VM',
                      value: Math.round(avgVM?.average),
                      fill: theme.palette.primary.main,
                  },
              ]
            : []),
    ].sort((a, b) => a.value - b.value);

    return (
        <ResponsiveContainer width='100%' height={height}>
            <RadialBarChart
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                barSize={barSize}
                startAngle={90}
                endAngle={-270}
                data={data}
            >
                {domain && (
                    <PolarAngleAxis type='number' domain={domain} dataKey={'value'} angleAxisId={0} tick={false} />
                )}
                <RadialBar
                    label={{ position: 'insideStart', fill: '#fff', fontSize: 12, formatter }}
                    background
                    clockWise
                    dataKey='value'
                    isAnimationActive={false}
                    angleAxisId={0}
                />
                <Legend
                    iconSize={10}
                    layout='vertical'
                    verticalAlign={legendVerticalAlign}
                    align={legendAlign}
                    wrapperStyle={{ fontSize: 12 }}
                />
                <Tooltip formatter={formatter} labelFormatter={(e) => data[e].name} />
            </RadialBarChart>
        </ResponsiveContainer>
    );
}

export default function RadialChart({ height = 200, name, ...props }) {
    return (
        <AsyncBoundary
            fallback={
                <ResponsiveContainer width='100%' height={height}>
                    <RadialBarChart
                        cx='50%'
                        cy='40%'
                        innerRadius='90%'
                        outerRadius='90%'
                        barSize={10}
                        data={[{ name: 'loading...', 'N/A': 0 }]}
                    >
                        <RadialBar
                            label={{ position: 'insideStart', fill: '#fff' }}
                            background
                            clockWise
                            dataKey='N/A'
                        />
                        <Legend
                            iconSize={10}
                            layout='vertical'
                            verticalAlign='bottom'
                            align='center'
                            wrapperStyle={{ fontSize: 12 }}
                        />
                        <Tooltip />
                    </RadialBarChart>
                </ResponsiveContainer>
            }
        >
            <ChartContent name={name} height={height} {...props} />
        </AsyncBoundary>
    );
}
