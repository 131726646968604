import { useEffect, useState } from 'react';
import hash from 'object-hash';
import useLocalState from '../../report/useLocalState';

const types = {
    SWITCH: 0,
    INT_SLIDER: 1,
    STRING_SELECTOR: 2,
    STRING_MULTISELECTOR: 3,
    DATE_SELECTOR: 4,
    SIMPLE_DATE_SELECTOR: 5,
    DISABLED: 6,
};

export default function useSummaryOptions(component) {
    const [state, setState] = useState(() => {
        return JSON.parse(localStorage.getItem('summaryOptions')) || {};
    });

    const localStSortModel = useLocalState('stSortModel', {});
    const localStPage = useLocalState('stPage', {});
    const localStExp = useLocalState('stExp', {});

    useEffect(() => {
        const optHash = hash(state);
        const oldHash = localStorage.getItem('summaryOptionsHash') || '';
        if (optHash !== oldHash) {
            console.log('Updating options...', optHash);
            localStSortModel.resetState();
            localStPage.resetState();
            localStExp.resetState();
            localStorage.setItem('summaryOptionsHash', optHash);
            localStorage.setItem('summaryOptions', JSON.stringify(state));
        }
    }, [localStExp, localStPage, localStSortModel, state]);

    const register = (key, name, type = types.SWITCH, initValue = false, extraProps = {}) => {
        if (!(key in (state?.[component] ?? {})))
            setState((prevState) => ({
                ...prevState,
                [component]: { ...prevState[component], [key]: { name, type, extraProps, value: initValue } },
            }));
    };

    const setValue = (key, newValue) => {
        if (key in (state?.[component] ?? {}))
            setState((prevState) => ({
                ...prevState,
                [component]: { ...prevState[component], [key]: { ...prevState[component][key], value: newValue } },
            }));
    };

    const rename = (key, newName) => {
        if (key in (state?.[component] ?? {}) && state?.[component]?.[key]?.name !== newName)
            setState((prevState) => ({
                ...prevState,
                [component]: { ...prevState[component], [key]: { ...prevState[component][key], name: newName } },
            }));
    };

    return {
        values: state?.[component] ?? {},
        register,
        setValue,
        rename,
        types,
        tableOpt: {
            sortModel: localStSortModel,
            page: localStPage,
            expanded: localStExp,
        },
    };
}

export { types };
