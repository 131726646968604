import PaperCard from './PaperCard';
import { AsyncBoundary } from '../../aceapi/utils';
import { List, ListItem, Skeleton, Typography } from '@mui/material';
import useExtraData from './useExtraData';
import useShow from '../../aceapi/hooks/useShow';
import useAuthorized from '../../aceapi/hooks/useAuthorized';

function AsyncPolypStats() {
    const show = useShow();
    const authorized = useAuthorized();
    const { annotations } = useExtraData({ show: show.extra_models, authorized });

    const polyp_count = (annotations ?? []).filter((x) => x.model?.key === 'newpolyp_annotation').length;
    const resection_count = (annotations ?? []).filter((x) => x.model?.key === 'resection_annotation').length;
    const resection_percentage = Math.round((resection_count / polyp_count) * 100);

    return (
        <List dense>
            <ListItem>
                <Typography>
                    <b>Polyp Count</b>: {polyp_count}
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    <b>Resection Count</b>: {resection_count}
                </Typography>
            </ListItem>
            <ListItem>
                <Typography>
                    <b>Resection Ratio</b>: {resection_percentage}%
                </Typography>
            </ListItem>
        </List>
    );
}

export default function PolypStats(props) {
    return (
        <PaperCard title='Polyp Stats'>
            <AsyncBoundary
                fallback={
                    <List dense>
                        {Array(4)
                            .fill()
                            .map((_, i) => (
                                <ListItem key={i}>
                                    <Typography variant='body1' width='100%'>
                                        <Skeleton animation='wave' />
                                    </Typography>
                                </ListItem>
                            ))}
                    </List>
                }
            >
                <AsyncPolypStats {...props} />
            </AsyncBoundary>
        </PaperCard>
    );
}
