import { AsyncBoundary } from '../../../../aceapi/utils';
import PaperCard from '../../../report/PaperCard';
import FakeProgressBar from '../../../placeholders/FakeProgressBar';
import { useGgExportPackageCreate } from '../../../../aceapi/aceComponents';
import { useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useAceApp } from '../../../Menu/ReportAppSelector';
import Box from '@mui/material/Box';
import clsx from 'clsx';
import ConfirmButton from '../../../dialogs/ConfirmButton';
import { LoadingButton } from '@mui/lab';
import DownloadIcon from '@mui/icons-material/Download';

function AsyncQuerySummary() {
    const { app } = useAceApp();
    const mutation = useGgExportPackageCreate();

    const columns = useMemo(
        () => [
            {
                field: 'formTitle',
                headerName: 'Form Title',
                minWidth: 200,
                flex: 0.2,
            },
            {
                field: 'questionText',
                headerName: 'Question Text',
                minWidth: 500,
                flex: 0.8,
            },
            {
                field: 'openCount',
                headerName: 'Open Count',
                minWidth: 120,
                flex: 0.1,
                headerClassName: 'gg-queries-open-count--header',
                cellClassName: (params) =>
                    clsx('gg-queries-open-count', {
                        highlight: params.value > 0,
                    }),
            },
            {
                field: 'closedCount',
                headerName: 'Closed Count',
                minWidth: 120,
                flex: 0.1,
                headerClassName: 'gg-queries-closed-count--header',
                cellClassName: (params) =>
                    clsx('gg-queries-closed-count', {
                        highlight: params.value > 0,
                    }),
            },
            {
                field: 'lastUpdated',
                headerName: 'Last Updated',
                minWidth: 180,
                flex: 0.15,
                renderCell: (params) => new Date(params.value).toLocaleString('en-GB'),
            },
        ],
        [],
    );

    function loadQueries() {
        return mutation.mutateAsync({ pathParams: { app } });
    }

    function forceRefresh() {
        return mutation.mutateAsync({ pathParams: { app }, body: { forceRefresh: true } });
    }

    const queryData = useMemo(() => {
        // group mutations by formTitle and questionText and count open and closed
        const data = mutation.data || [];
        const grouped = Object.groupBy(data, (item) => item.formTitle + item.questionText);
        return Object.entries(grouped).map(([key, values]) => {
            const openCount = values.filter((item) => item.status === 'Open').length;
            const closedCount = values.filter((item) => item.status === 'Closed').length;
            return {
                id: key,
                formTitle: values[0].formTitle,
                questionText: values[0].questionText,
                openCount,
                closedCount,
                lastUpdated: Math.max(...values.map((item) => new Date(item.actionTime).getTime())),
            };
        });
    }, [mutation.data]);

    return (
        <PaperCard xs={12} title='Greenlight Guru Query Summary'>
            <Stack spacing={2} direction='column'>
                <Stack spacing={2} direction='row' pt={2}>
                    {!mutation.isSuccess ? (
                        <LoadingButton
                            loading={mutation.isLoading}
                            loadingPosition='start'
                            startIcon={<DownloadIcon />}
                            onClick={loadQueries}
                            disabled={mutation.isLoading}
                            variant='contained'
                        >
                            Load Queries
                        </LoadingButton>
                    ) : (
                        <Button
                            onClick={mutation.reset}
                            disabled={mutation.isLoading}
                            variant='contained'
                            color='secondary'
                        >
                            Clear
                        </Button>
                    )}
                    <ConfirmButton
                        onConfirm={forceRefresh}
                        action='force refresh the queries from Greenlight Guru'
                        disabled={mutation.isLoading}
                        variant='outlined'
                        color='warning'
                    >
                        Force Refresh
                    </ConfirmButton>
                </Stack>
                <Box
                    sx={{
                        width: '100%',
                        margin: 'auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {mutation.data && (
                        <DataGrid
                            getRowId={(row) => row.formTitle + row.questionText}
                            rows={queryData}
                            columns={columns}
                            autoHeight
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            sx={{
                                '& .gg-queries-open-count--header': { color: 'red' },
                                '& .gg-queries-closed-count--header': { color: 'green' },
                                '& .gg-queries-open-count.highlight': { backgroundColor: '#ffcccc' },
                                '& .gg-queries-closed-count.highlight': { backgroundColor: '#ccffcc' },
                            }}
                        />
                    )}
                </Box>
            </Stack>
        </PaperCard>
    );
}

export default function QuerySummary() {
    return (
        <AsyncBoundary
            fallback={
                <PaperCard xs={12} title='Issue Summary'>
                    <FakeProgressBar />
                </PaperCard>
            }
        >
            <AsyncQuerySummary />
        </AsyncBoundary>
    );
}
