import { useAceApp } from '../Menu/ReportAppSelector';
import { useSourcesList } from '../../aceapi/aceComponents';
import { useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';

export default function ForceSelectApp({ children }) {
    const { app, setApp } = useAceApp();
    const { data: sources } = useSourcesList({});

    const isAppSelected = useMemo(() => {
        return sources.map((source) => source.nickname).includes(app);
    }, [app, sources]);

    return isAppSelected ? (
        children
    ) : (
        <Box m={2} pt={3} pb={3}>
            <Stack direction='column' spacing={2} alignItems='center'>
                <Typography variant='h4' align='center'>
                    Please select an app
                </Typography>
                <Stack direction='row' spacing={2} alignItems='center'>
                    {sources?.map((source) => (
                        <Button
                            key={source.id}
                            variant='contained'
                            size='large'
                            onClick={() => setApp(source.nickname)}
                            sx={{ backgroundColor: source.color }}
                        >
                            {source.nickname}
                        </Button>
                    ))}
                </Stack>
            </Stack>
        </Box>
    );
}
