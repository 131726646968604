import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

export default function TextWidget({ field, control }) {
    return (
        <Controller
            name={field.name}
            control={control}
            render={(props) => <TextField type='search' label={field.label} variant='outlined' {...props.field} />}
        />
    );
}
