import { GradientPath } from 'gradient-path';
import { useEffect, useState } from 'react';
import linspace from 'linspace';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import { useGraphRead, useProceduresCaecum } from '../aceapi/aceComponents';

export default function BowelPath({ uuid, pathRef }) {
    const { app } = useAceApp();
    const { data: vmdata } = useGraphRead({
        pathParams: { procedureId: uuid },
        queryParams: { app, plot: 'visible_mucosa', sampling: 100, aggregate: 'mean' },
    });

    const { data: caecum } = useProceduresCaecum({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });

    const [rendered, setRendered] = useState(false);
    const [gpPath, setGpPath] = useState(null);

    function getColor(value) {
        const hue = (value * 120).toString(10);
        return ['hsl(', hue, ',100%,50%)'].join('');
    }

    useEffect(() => {
        const path = pathRef.current;
        if (gpPath || !path || !path.closest('svg') || rendered) return;

        setGpPath(
            new GradientPath({
                path: path,
                segments: 1000,
                samples: 3,
                precision: 3, // Optional
            }),
        );
    }, [gpPath, pathRef, rendered]);

    useEffect(() => {
        if (!gpPath || rendered) return;

        let datapoints = vmdata?.graph_points?.vals ?? [];
        if (caecum?.timestamp) {
            datapoints = datapoints
                ?.map((x, i) => ({ val: x, ts: vmdata?.graph_points?.list_ts[i] }))
                ?.filter((x) => x.ts > caecum.timestamp)
                ?.map((x) => x.val);
        }

        const positions = linspace(1, 0, datapoints.length);
        const vmgradient = datapoints?.map((v, i) => ({ color: getColor(v / 100), pos: positions[i] })) ?? [];
        vmgradient.reverse();

        if (vmgradient.length < 2) return;

        gpPath.render({
            type: 'path',
            fill: vmgradient,
            width: 7,
            stroke: vmgradient,
            strokeWidth: 0.5,
        }) && setRendered(true);
    }, [caecum.timestamp, gpPath, rendered, vmdata?.graph_points?.list_ts, vmdata?.graph_points?.vals]);

    return (
        <div style={{ position: 'relative', width: '100%', height: 290 }}>
            <svg xmlns='http://www.w3.org/2000/svg' id='svg5' viewBox='0 0 212 297'>
                <defs />
                <path
                    ref={pathRef}
                    style={{
                        fill: 'none',
                        stroke: '#000000',
                        strokeWidth: 7,
                        strokeLinecap: 'round',
                        strokeLinejoin: 'round',
                        strokeOpacity: 1,
                    }}
                    d='m 94.703169,281.50589 c 0,0 -10.019429,-88.3358 6.372951,-93.06424 16.39237,-4.72844 66.05278,-1.49386 72.98467,-25.56811 C 180.99268,138.79928 166.09724,117.19837 172.5093,83.529 178.92137,49.859625 202.42878,7.1459608 186.19161,19.184037 169.95444,31.222114 121.4128,107.82965 60.053494,38.273138 -1.3058089,-31.283378 39.455441,150.00572 39.455441,150.00572'
                    id='path172'
                />
            </svg>
        </div>
    );
}
