import { useParams } from 'react-router-dom';
import useAuthorized from './useAuthorized';
import { timedelta } from '../../components/summary/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAceApp } from '../../components/Menu/ReportAppSelector';
import { useTimeRead } from '../aceComponents';

const debug = false;

export default function useProcTime(overrideApp = null, overrideUuid = null, suspense = true) {
    const ace = useAceApp();
    const params = useParams();
    const app = overrideApp ?? ace.app;
    const uuid = overrideUuid ?? params.uuid;
    const authorized = useAuthorized(overrideApp, overrideUuid);
    const { data: time } = useTimeRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { suspense },
    );

    const start = time?.time.start ?? Date.now();
    const end = time?.time.end ?? Date.now();
    const estimate = time?.time.estimate ?? false;
    const status = time?.status ?? 'UNKNOWN';

    const isLastDataRecent = useCallback(
        (limit = timedelta.MINUTE) => {
            const diff = Date.now() - end;
            return diff >= 0 && diff < limit;
        },
        [end],
    );

    const ongoing = authorized && status === 'STARTED' && isLastDataRecent();
    useTimeRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: ongoing, refetchInterval: 2000 },
    );

    const [lastEndUpdate, setLastEndUpdate] = useState(Date.now());
    const [tick, setTick] = useState(0);
    useEffect(() => {
        if (ongoing) {
            const interval = setInterval(() => {
                setTick((prev) => prev + 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [ongoing]);

    useEffect(() => {
        if (end) {
            setLastEndUpdate(Date.now());
        }
    }, [end]);

    const internalEnd = useMemo(() => {
        if (debug) console.log('Ticking', tick);
        return end + (Date.now() - lastEndUpdate);
    }, [end, lastEndUpdate, tick]);

    const getStatusMessage = useCallback(() => {
        const isRecent = isLastDataRecent();
        switch (status) {
            case 'STARTED':
                return isRecent
                    ? 'This procedure is ongoing.'
                    : 'Error: The procedure was not properly ended; if this is frequent, please file a report.';
            case 'INTERRUPTED':
                return isRecent
                    ? 'This procedure was interrupted but may still be ongoing.'
                    : 'This procedure was not properly ended!';
            case 'COMPLETED':
            case 'UPLOADED':
                return 'This procedure was completed normally but is missing its end timestamp.';
            default:
                return 'Error: Invalid procedure status.';
        }
    }, [isLastDataRecent, status]);

    const startDate = useMemo(() => new Date(start), [start]);
    const endDate = useMemo(() => new Date(end), [end]);

    return useMemo(
        () => ({
            start,
            end,
            internalEnd,
            estimate,
            status,
            startDate,
            endDate,
            isLastDataRecent,
            getStatusMessage,
            ongoing,
        }),
        [start, end, internalEnd, estimate, status, startDate, endDate, isLastDataRecent, getStatusMessage, ongoing],
    );
}
