import useStickyState from '../report/useStickyState';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from 'react';
import { ChangelogPlaceholder } from '../placeholders/placeholders';
import { useChangelogList } from '../../aceapi/aceComponents';

const versionRegex = /## \[([0-9.]+)]/;

const preprocess = (text) => {
    const { index: startIdx } = versionRegex.exec(text);
    if (startIdx === -1) return text;
    const { index: oldRelIdx } = versionRegex.exec(text.substring(startIdx + 1));
    if (oldRelIdx === -1) return text.substring(startIdx);
    const oldVerIdx = oldRelIdx + startIdx + 1;
    const part1 = text.substring(startIdx, oldVerIdx);
    const part2 = text.substring(oldVerIdx);
    return '# Last version changelog\n' + part1 + '\n# Older versions\n' + part2;
};

export default function ChangelogPopup(props) {
    const { open: controlledShow, onClose: controlledOnClose } = props;

    const [keepShowing, setKeepShowing] = useStickyState('showChangelog', true);
    const [show, setShow] = useState(keepShowing);
    const [content, setContent] = useState(null);
    const currentVersion = localStorage.getItem('version');

    const { data } = useChangelogList({}, { suspense: false });

    useEffect(() => {
        if (data) {
            data.text()
                .then((text) => {
                    const processed = preprocess(text);
                    setContent(processed);
                })
                .catch((err) => {
                    console.error(err);
                    setContent('Failed to load changelog.');
                });
        }
    }, [data]);

    return (
        <Dialog open={controlledShow ?? show} onClose={() => setShow(false)} maxWidth='lg'>
            <DialogTitle variant='h4' fontFamily='Roboto Mono,Monospace'>
                New Release Notice (current version: <b>{currentVersion}</b>)
            </DialogTitle>
            <DialogContent>
                <DialogContentText maxHeight='50vh' fontFamily='Roboto Mono,Monospace'>
                    {content ? <ReactMarkdown>{content}</ReactMarkdown> : <ChangelogPlaceholder />}
                </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogContent>
                <DialogContentText>
                    <b>If you want to see the changelog again, you can find it in the About Page. </b>
                    {controlledShow === undefined &&
                        'If you press "Got it", you won\'t see this popup until the next release.'}
                </DialogContentText>
                <DialogActions>
                    {controlledShow === undefined ? (
                        <>
                            <Button variant='outlined' color='secondary' onClick={() => setShow(false)}>
                                Show again next time
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    setKeepShowing(false);
                                    setShow(false);
                                }}
                            >
                                Got it
                            </Button>
                        </>
                    ) : (
                        <Button variant='contained' onClick={controlledOnClose}>
                            OK
                        </Button>
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
