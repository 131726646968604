import { AsyncBoundary } from '../../../../aceapi/utils';
import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgEnrollmentsRead, useGgFormAnswersRead, useGgSubjectsRead } from '../../../../aceapi/aceComponents';
import Typography from '@mui/material/Typography';
import { Box, List, ListItem, ListItemText, Stack, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';
import { timedelta } from '../../../summary/utils';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import CollapsiblePaperCard from '../../../report/CollapsiblePaperCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { DataGrid } from '@mui/x-data-grid';
import { FormsHelper } from '../formUtils';
import PaperCard from '../../../report/PaperCard';
import FakeProgressBar from '../../../placeholders/FakeProgressBar';

const MAX_ISSUE_DATA_COLUMNS = 6;

class FormAnswersManager {
    constructor(formAnswers = [], formSubjects = [], formEnrollments = []) {
        this._formAnswers = formAnswers;
        this._formSubjects = formSubjects;
        this._formEnrollments = formEnrollments;

        this._formsBySubject = null;
    }

    get formsBySubject() {
        if (!this._formsBySubject) {
            this._formsBySubject = {};
            for (const subject of this._formSubjects) {
                this._formsBySubject[subject.patientId] = [
                    new FormsHelper(this._formAnswers.filter((form) => form.subject.patientId === subject.patientId)),
                    this._formEnrollments.filter((enrollment) => enrollment.subject.patientId === subject.patientId),
                ];
            }
        }
        return this._formsBySubject;
    }
}

const anomalyTests = [
    {
        key: 'missing_study_exit',
        name: 'Missing Study Exit (>12d)',
        description: '12+ days have passed since the procedure and the Study Exit form has not been completed.',
        check: (subject, forms) => {
            const dateOfProcedure = forms.form('Colonoscopy Procedure')?.question('Date of Procedure')?.getText();
            if (!dateOfProcedure) return false;
            const today = new Date();
            const procedureDate = new Date(dateOfProcedure);
            if (today - procedureDate < 12 * timedelta.DAY) return false;
            return forms.form('Study Exit') === null;
        },
    },
    {
        key: 'good_subject_screen_failure',
        name: 'Good Subject Screen Failure',
        description: 'Subject signed Informed Consent, passed all incl./excl. criteria but has Screen Failure form.',
        check: (subject, forms) => {
            const informedConsent = forms.form('Informed Consent')?.question('Informed Consent Signed?')?.yes();
            if (!informedConsent) return false;
            const screenFailure = forms.form('Screen Fail')?.question('Is the patient a screen failure?')?.yes();
            if (!screenFailure) return false;
            const inclusionCriteria = forms
                .form('Inclusion Criteria')
                ?.questionFilter({ questionType: 'YES_NO' })
                ?.every((answer) => answer.yes());
            const exclusionCriteria = forms
                .form('Exclusion Criteria')
                ?.questionFilter({ questionType: 'YES_NO' })
                ?.every((answer) => answer.no());
            return inclusionCriteria && exclusionCriteria;
        },
    },
    {
        key: 'discontinued_subject',
        name: 'Discontinued Subject',
        description: 'Subject has been discontinued.',
        check(subject, forms, enrollments) {
            return enrollments.some((enrollment) => enrollment.discontinued);
        },
    },
    {
        key: 'device_deficiency',
        name: 'Device Deficiency',
        description: 'Date of device malfunctions should be equal to the colonoscopy procedure date.',
        check(subject, forms) {
            const dateOfProcedure = forms.form('Colonoscopy Procedure')?.question('Date of Procedure')?.getText();
            if (!dateOfProcedure) return false;
            const deviceIssuesForms = forms.filter('Study Device Problems or Malfunctions');
            if (!deviceIssuesForms || deviceIssuesForms.length === 0) return false;
            const malfunctionsForms = deviceIssuesForms.filter((deviceForm) =>
                deviceForm.question('Is there a device malfunction to report?').yes(),
            );
            if (malfunctionsForms.length === 0) return false;
            const formsHaveCorrectDate = malfunctionsForms.every(
                (deviceForm) => deviceForm.question('Date of Malfunctions')?.getText() === dateOfProcedure,
            );
            return !formsHaveCorrectDate;
        },
    },
    {
        key: 'protocol_deviation',
        name: 'Protocol Deviation',
        description:
            "Type of deviation on the PD form is selected as 'Subject did not meet all eligibility criteria'; " +
            "however all the 'Inclusion criteria' are entered as 'Yes' and all the 'Exclusion criteria' are selected " +
            "as 'No'.",
        check(subject, forms) {
            const protocolDeviation = forms.form('Protocol Deviations');
            if (!protocolDeviation) return false;
            const hasIneligibleDeviation = protocolDeviation
                .question('Type of Deviation')
                ?.choicesInclude('Subject did not meet all eligibility criteria');
            if (!hasIneligibleDeviation) return false;
            const inclusionCriteria = forms
                .form('Inclusion Criteria')
                ?.questionFilter({ questionType: 'YES_NO' })
                ?.every((answer) => answer.yes());
            const exclusionCriteria = forms
                .form('Exclusion Criteria')
                ?.questionFilter({ questionType: 'YES_NO' })
                ?.every((answer) => answer.no());
            return inclusionCriteria || exclusionCriteria;
        },
    },
    {
        key: 'colonoscopy',
        name: 'Colonoscopy',
        description:
            "On the Colonoscopy form 'Should the patient be excluded from the data analysis for any reason?' is " +
            "recorded as 'Yes'; however, on the study Exit form 'Has the subject complete the study?' is recorded as " +
            'Yes.',
        check(subject, forms) {
            const excludeFromAnalysis = forms
                .form('Colonoscopy Procedure')
                ?.question('Should the patient be excluded from the data analysis for any reason?')
                ?.yes();
            if (!excludeFromAnalysis) return false;
            const studyExit = forms.form('Study Exit');
            if (!studyExit) return false;
            // There has been a typo on question title where it should be 'completed' instead of 'complete'.
            // This is a workaround to handle the typo and still work if it is fixed.
            return studyExit.questionRegex(/^Has the subject complete.? the study/i)?.yes();
        },
    },
    {
        key: 'study_exit',
        name: 'Study Exit',
        description:
            "On the Study Exit form 'When did the patient exit the study?' is recorded as 'After consent, before " +
            "randomisation' or 'After randomisation but before the start of the procedure'; however procedure date " +
            "is entered on the 'Colonoscopy' form",
        check(subject, forms) {
            const exitMoment = forms.form('Study Exit')?.question('When did the patient exit the study?');
            if (!exitMoment) return false;
            const dateOfProcedure = forms.form('Colonoscopy Procedure')?.question('Date of Procedure')?.getText();
            if (!dateOfProcedure) return false;

            return (
                exitMoment.choicesInclude('After consent, before randomisation') ||
                exitMoment.choicesInclude('After randomisation but before the start of the procedure')
            );
        },
    },
    {
        key: 'adverse_event_1',
        name: 'Adverse Event 1',
        description:
            "'Onset Date' on the Adverse Event form is not equal to the 'Date of Procedure' on the Colonoscopy form.",
        check(subject, forms) {
            const dateOfProcedure = forms.form('Colonoscopy Procedure')?.question('Date of Procedure')?.getText();
            if (!dateOfProcedure) return false;
            const adverseEvents = forms.filterRegex(/Adverse Event/i);
            if (!adverseEvents || adverseEvents.length === 0) return false;
            return adverseEvents.some(
                (adverseEvent) => adverseEvent.question('Onset Date')?.getText() !== dateOfProcedure,
            );
        },
    },
    {
        key: 'adverse_event_2',
        name: 'Adverse Event 2',
        description:
            "Date of resolution' on the Adverse Event form is after the 'Date of study exit' on the Study Exit form",
        check(subject, forms) {
            const dateOfExit = forms.form('Study Exit')?.question('Date of study exit')?.getText();
            if (!dateOfExit) return false;
            const adverseEvents = forms.filterRegex(/Adverse Event/i);
            if (!adverseEvents || adverseEvents.length === 0) return false;
            return adverseEvents.some(
                (adverseEvent) =>
                    new Date(adverseEvent.question('Date of resolution')?.getText()) > new Date(dateOfExit),
            );
        },
    },
    {
        key: 'adverse_event_3',
        name: 'Adverse Event 3',
        description:
            "On the Adverse Event (AE)form 'Timing of event' is recorded as 'Colonoscopy Procedure' or " +
            "'Post-colonoscopy up to discharge' but the 'Onset Date' of the AE is not equal to the 'Procedure Date' " +
            'on the Colonoscopy form',
        check(subject, forms) {
            const dateOfProcedure = forms.form('Colonoscopy Procedure')?.question('Date of Procedure')?.getText();
            if (!dateOfProcedure) return false;
            const adverseEvents = forms.filterRegex(/Adverse Event/i);
            if (!adverseEvents || adverseEvents.length === 0) return false;
            return adverseEvents.some((adverseEvent) => {
                const timing = adverseEvent.question('Timing of event');
                const onsetDate = adverseEvent.question('Onset Date')?.getText();
                return (
                    (timing.choicesInclude('Colonoscopy Procedure') ||
                        timing.choicesInclude('Post-colonoscopy up to discharge')) &&
                    onsetDate !== dateOfProcedure
                );
            });
        },
    },
    {
        key: 'adverse_event_4',
        name: 'Adverse Event 4',
        description:
            "On the Adverse Event (AE)form 'Timing of the event' is recorded as 'Post-colonoscopy, from discharge up " +
            "to 7 days from the procedure' but the 'Onset Date' of the AE is  equal to the 'Procedure Date' on the " +
            'Colonoscopy form',
        check(subject, forms) {
            const dateOfProcedure = forms.form('Colonoscopy Procedure')?.question('Date of Procedure')?.getText();
            if (!dateOfProcedure) return false;
            const adverseEvents = forms.filterRegex(/Adverse Event/i);
            if (!adverseEvents || adverseEvents.length === 0) return false;
            return adverseEvents.some((adverseEvent) => {
                const timing = adverseEvent.question('Timing of event');
                const onsetDate = adverseEvent.question('Onset Date')?.getText();
                return (
                    timing.choicesInclude('Post-colonoscopy, from discharge up to 7 days from the procedure') &&
                    onsetDate === dateOfProcedure
                );
            });
        },
    },
];

function AsyncAnomalyReport() {
    const { app } = useAceApp();

    const { data: formAnswers } = useGgFormAnswersRead({ pathParams: { app } });
    const { data: subjects } = useGgSubjectsRead({ pathParams: { app } });
    const { data: enrollments } = useGgEnrollmentsRead({ pathParams: { app } });

    const [sortModel, setSortModel] = useState([]);
    const [page, setPage] = useState(0);

    const formAnswersManager = useMemo(
        () => new FormAnswersManager(formAnswers, subjects, enrollments),
        [formAnswers, subjects, enrollments],
    );

    const issueColumns = useMemo(
        () => [
            { field: 'id', headerName: 'Subject', flex: 0.2, minWidth: 20 },
            ...anomalyTests.map((test) => ({
                field: test.key,
                headerName: test.name,
                renderHeader: () => (
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <Tooltip title={test.description}>
                            <HelpIcon color='info' />
                        </Tooltip>
                        {test.name}
                    </Stack>
                ),
                flex: 0.2,
                minWidth: 20,
                renderCell: (params) => {
                    return (
                        <Tooltip title={test.description}>
                            {params.value ? <CancelIcon color='error' /> : <CheckCircleIcon color='success' />}
                        </Tooltip>
                    );
                },
            })),
        ],
        [],
    );

    // if there are too many columns, the table will be too wide, we need to split the columns into multiple tables
    // and repeat the subject column in each table. The maximum number of columns parameter excludes the subject column
    const issueColumnsSplit = useMemo(() => {
        const columns = issueColumns.slice(1);
        const splitColumns = [];
        for (let i = 0; i < columns.length; i += MAX_ISSUE_DATA_COLUMNS) {
            splitColumns.push([issueColumns[0], ...columns.slice(i, i + MAX_ISSUE_DATA_COLUMNS)]);
        }
        // add empty columns to the last table to make all tables have the same number of columns
        const lastTable = splitColumns[splitColumns.length - 1];
        const emptyColumns = new Array(MAX_ISSUE_DATA_COLUMNS - lastTable.length + 1)
            .fill(null)
            .map((_, index) => ({ field: `empty${index}`, headerName: '', flex: 0.2, minWidth: 20 }));
        splitColumns[splitColumns.length - 1] = [...lastTable, ...emptyColumns];
        return splitColumns;
    }, [issueColumns]);

    const issues = useMemo(
        () =>
            Object.entries(formAnswersManager.formsBySubject)
                .map(([subject, [forms, enrollments]]) => {
                    const issues = {};
                    anomalyTests.forEach((test) => {
                        issues[test.key] = test.check(subject, forms, enrollments);
                    });
                    return { id: subject, ...issues };
                })
                .filter((subject) => Object.keys(subject).some((key) => key !== 'id' && subject[key])),
        [formAnswersManager],
    );

    return (
        <Stack direction='column' spacing={2}>
            <CollapsiblePaperCard
                title={
                    <Stack direction='column' spacing={1}>
                        <Typography variant='h4'>Issue Summary</Typography>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <Typography variant='h6'>Legend:</Typography>
                            <CheckCircleIcon color='success' />
                            <Typography variant='body1'> = No issues,</Typography>
                            <CancelIcon color='error' />
                            <Typography variant='body1'> = Issue,</Typography>
                            <ErrorIcon color='warning' />
                            <Typography variant='body1'> = Issue ignored,</Typography>
                            <HelpIcon color='error' />
                            <Typography variant='body1'> = Other issue</Typography>
                        </Stack>
                    </Stack>
                }
                xs={12}
            >
                <Box
                    sx={{
                        width: '100%',
                        margin: 'auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {issueColumnsSplit.map((columns, index) => (
                        <DataGrid
                            key={index}
                            rows={issues}
                            columns={columns}
                            page={page}
                            onPageChange={(newPage) => setPage(newPage)}
                            autoHeight
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            sortModel={sortModel}
                            onSortModelChange={setSortModel}
                        />
                    ))}
                </Box>
            </CollapsiblePaperCard>
            <CollapsiblePaperCard title='Issue Legend' xs={12}>
                <Box m={2} mt={0}>
                    <List>
                        {anomalyTests.map((test) => (
                            <ListItem key={test.key}>
                                <ListItemText
                                    primary={test.name}
                                    secondary={test.description}
                                    primaryTypographyProps={{ variant: 'h6' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </CollapsiblePaperCard>
        </Stack>
    );
}

export default function AnomalyReport() {
    return (
        <AsyncBoundary
            fallback={
                <PaperCard xs={12} title='Issue Summary'>
                    <FakeProgressBar />
                </PaperCard>
            }
        >
            <AsyncAnomalyReport />
        </AsyncBoundary>
    );
}
