import { Button, Grid, Stack, Typography } from '@mui/material';
import PaperCard from '../components/report/PaperCard';
import * as React from 'react';
import { useEffect } from 'react';
import { useSourcesList } from '../aceapi/aceComponents';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import { useNavigate } from 'react-router-dom';

export default function ForbiddenApp() {
    const { app, setApp } = useAceApp();
    const { data: sources } = useSourcesList({});
    const navigate = useNavigate();

    useEffect(() => {
        if (!sources) return;
        if ([...sources.map((source) => source.nickname), 'all'].includes(app)) {
            navigate('/dashboard');
        }
    }, [app, navigate, sources]);

    return (
        <Grid container spacing={3}>
            <PaperCard xs={12} title='Access denied' variant='h2' align='center'>
                <Typography variant='h5' align='center' m={2}>
                    You have insufficient permissions to access the <b>{app.toUpperCase()}</b> app or it does not exist.
                    <br />
                    Please contact an admin to grant you access to this app or select another app:
                </Typography>
                <Stack direction='row' spacing={2} alignItems='center' justifyContent='center'>
                    <Button
                        variant='contained'
                        size='large'
                        onClick={() => {
                            setApp('all');
                            navigate('/dashboard');
                        }}
                        color='primary'
                    >
                        All
                    </Button>
                    {sources.map((source) => (
                        <Button
                            key={source.id}
                            variant='contained'
                            size='large'
                            onClick={() => {
                                setApp(source.nickname);
                                navigate('/dashboard');
                            }}
                            sx={{ backgroundColor: source.color }}
                        >
                            {source.nickname}
                        </Button>
                    ))}
                </Stack>
            </PaperCard>
        </Grid>
    );
}
