import { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

export default function SimpleDateSelector({ value, onChange }) {
    const [date, setDate] = useState(new Date(value));

    const handleChange = (newDate) => {
        const formattedNewDate = new Date(newDate);
        formattedNewDate.setHours(0, 0, 0, 0);
        setDate(formattedNewDate);
        onChange(formattedNewDate.getTime());
    };

    return (
        <Box mt={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    label='From'
                    inputFormat='dd/MM/yyyy'
                    value={date}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </Box>
    );
}
