import React, { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import getMenuItems from '../../config/menuItems';
import MuiAppBar from '@mui/material/AppBar';
import CameraIcon from '@mui/icons-material/Camera';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import styled from '@emotion/styled';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MuiDrawer from '@mui/material/Drawer';
import { Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import ReportNotifications from '../notifications/ReportNotifications';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ReportAppSelector, { useAceApp } from './ReportAppSelector';
import { useSourcesList } from '../../aceapi/aceComponents';
import { ComputeAnnotationContext } from '../../config/contexts';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const Menu = ({ token }) => {
    const [open, setOpen] = React.useState(false);

    const isUserActive = token.parse().user.is_active;
    const { app } = useAceApp();
    const { data: sources, isSuccess } = useSourcesList({}, { suspense: false, enabled: isUserActive });

    const color = useMemo(
        () => (isSuccess && sources.find((source) => source.nickname === app)?.color) ?? '#376092',
        [isSuccess, sources, app],
    );

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const menuItems = getMenuItems({ token });

    const handleReturnToCaddie = () => {
        window.location.href = 'https://caddie.odin-vision.com/';
    };

    const user = token.parse().user;

    const location = useLocation();

    const { isComputingAnnotations } = useContext(ComputeAnnotationContext);

    return (
        <div>
            <AppBar
                position='absolute'
                open={open}
                style={{
                    backgroundColor: color,
                    transition: 'background-color 0.5s ease',
                    WebkitTransition: 'background-color 0.5s ease',
                    MozTransition: 'background-color 0.5s ease',
                }}
            >
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge='start'
                        color='inherit'
                        aria-label='open drawer'
                        onClick={handleDrawerOpen}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component='h1'
                        variant='h6'
                        color='inherit'
                        noWrap
                        sx={{ flexGrow: 1, minWidth: '240px' }}
                    >
                        ACE-{app.toUpperCase()}{' '}
                        {menuItems.find((item) => item.value === location.pathname)?.primaryText}
                    </Typography>
                    <ReportAppSelector active={isUserActive} />
                    {user.is_staff && (
                        <Tooltip title='Open the Django admin panel'>
                            <IconButton color='inherit' href='/admin' target='_blank'>
                                <AdminPanelSettingsIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {user.is_active && (
                        <>
                            <ReportNotifications />
                            <Typography component='h3' variant='h6' color='inherit' noWrap>
                                <b>{user.username}</b> {' is logged in!'}
                            </Typography>
                        </>
                    )}
                    <IconButton color='inherit' onClick={handleReturnToCaddie} title='Return to CADDIE'>
                        <Badge badgeContent={0} color='secondary'>
                            <CameraIcon />
                        </Badge>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant='permanent' open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List>
                    {menuItems.map(
                        (item) =>
                            item.visible && (
                                <ListItemButton
                                    key={item.primaryText}
                                    component={Link}
                                    to={item.value}
                                    disabled={isComputingAnnotations}
                                >
                                    <ListItemIcon>{item.leftIcon}</ListItemIcon>
                                    <ListItemText primary={item.primaryText} />
                                </ListItemButton>
                            ),
                    )}
                    {user.is_active && (
                        <ListItemButton key={'Logout'} onClick={() => token.logout()}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary='Logout' />
                        </ListItemButton>
                    )}
                </List>
            </Drawer>
        </div>
    );
};

export default Menu;
