import * as React from 'react';
import { useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import Widget from './widgets/Widget';
import Box from '@mui/material/Box';
import searchFields from '../../config/searchFields';
import ProcedureDataGrid from './ProcedureDataGrid';
import SearchHistory from './SearchHistory';
import useSearch from './hooks/useSearch';
import { TokenContext } from '../../config/contexts';

export default function SearchForm() {
    const search = useSearch();
    const token = useContext(TokenContext);

    useEffect(() => {
        if (!search.result && token.access.isValid()) search.refresh();
    }, [search, token.access]);

    const { setValue, handleSubmit, control, reset } = useForm({
        defaultValues: Object.fromEntries(
            searchFields.map((field) => [field.name, field.type === 'date' ? null : field.defaultValue ?? '']),
        ),
    });

    useEffect(() => {
        Object.entries(search.filters.state ?? []).forEach(([key, value]) => setValue(key, value));
    }, [search.filters.state, setValue]);

    return (
        <>
            <Box
                m={2}
                sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
                component='form'
                onSubmit={handleSubmit((e) => search.submit(e, -1))}
                onReset={() => {
                    search.reset();
                    reset();
                }}
            >
                <Stack direction='column' spacing={2}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        {searchFields.map((field) => (
                            <Widget
                                key={field.name}
                                field={field}
                                control={control}
                                setValue={setValue}
                                formValues={search.filters.state}
                            />
                        ))}
                    </Stack>
                    <SearchHistory search={search} />
                    <Stack direction='row' spacing={2}>
                        <Button color='primary' type='submit' variant='outlined' disabled={search.isLoading}>
                            Submit
                        </Button>
                        <Button color='secondary' type='reset' variant='outlined' disabled={search.isLoading}>
                            Reset
                        </Button>
                    </Stack>
                    <Divider />
                </Stack>
            </Box>
            <Box m={2} pt={3} pb={3}>
                <Grid item xs={12} justifyContent='center'>
                    {Array.isArray(search.result) ? (
                        <ProcedureDataGrid
                            rows={search.result}
                            pageSize={15}
                            sortModel={search.sortModel.state}
                            onSortModelChange={search.sortModelChanged}
                            sortingMode='server'
                            page={search.currentPage.state}
                            onPageChange={search.pageChanged}
                            rowCount={search.totalCount}
                            paginationMode='server'
                        />
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress />
                            <Typography>
                                Loading might take a while, but if it looks stuck, please refresh the page
                            </Typography>
                        </div>
                    )}
                </Grid>
            </Box>
        </>
    );
}
