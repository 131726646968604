import TextWidget from './TextWidget';
import { Typography } from '@mui/material';
import SelectWidget from './SelectWidget';
import DateWidget from './DateWidget';
import SwitchWidget from './SwitchWidget';

export default function Widget(props) {
    const type = props.field.type;

    switch (type) {
        case 'text':
            return <TextWidget {...props} />;
        case 'select':
            return <SelectWidget {...props} />;
        case 'switch':
            return <SwitchWidget {...props} />;
        case 'date':
            return <DateWidget {...props} />;
        default:
            return <Typography>Unknown widget type: {type}</Typography>;
    }
}
