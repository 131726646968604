/* eslint-disable @typescript-eslint/no-unused-vars */
import { caseStatuses, extractIssueString, getIgnoredIssues } from './utils';
import { useMemo } from 'react';
import useProcTime from '../../aceapi/hooks/useProcTime';
import useExtraData from '../report/useExtraData';
import useShow from '../../aceapi/hooks/useShow';
import useHospitalNames from './useHospitalNames';
import {
    useClinicalreportEagleIgnoredIssues,
    useClinicalreportGgpatientList,
    useClinicalreportGgpatientRead,
    useProceduresCaecum,
    useProceduresComments,
    useProceduresRead,
} from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

export const sanityChecks = [
    {
        name: 'Procedure ID OK',
        field: 'procedure_id',
        eval: (catchVal, caddieVal) => catchVal === caddieVal,
    },
];

export function useReportAnomalyAnalysis({ uuid, reportID }) {
    const { app } = useAceApp();
    const show = useShow();
    const time = useProcTime(app, uuid);

    const { data: procedure } = useProceduresRead({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });
    const { data: caecum } = useProceduresCaecum({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });
    const { data: procComments } = useProceduresComments({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });

    const ignoredIssues = useMemo(() => getIgnoredIssues(procComments?.data ?? [], uuid), [procComments, uuid]);

    const { annotations } = useExtraData({
        show: show.extra_models,
        overrideUuid: uuid,
    });
    const { getHospitalName, getHospitalAcronym } = useHospitalNames();
    const { data: patient } = useClinicalreportGgpatientRead(
        {
            pathParams: { id: reportID },
            queryParams: { app },
        },
        { enabled: !!reportID },
    );

    const caddieEquivalence = useMemo(
        () => ({
            procedure_id: () => uuid,
            hospital_name: () => getHospitalName(procedure.username),
            site_name: () => getHospitalAcronym(procedure.username),
            hospital_short_name: () => procedure.username,
            patient_id: () => procedure.patient_id,
            subject_id: () => procedure.patient_id,
            date_of_procedure: () => time.startDate.toISOString().split('T')[0],
            randomisation: () => procedure.mode,
            time: () => time.startDate.toLocaleTimeString('en-GB'),
            start_time: () => time.startDate.toLocaleTimeString('en-GB'),
            total_lesions_removed_or_biopsied: () =>
                annotations?.filter((x) => x?.model?.key === 'resection_annotation').length,
            any_polyps_detected_not_removed: () => {
                const polyps = annotations?.filter((x) => x?.model?.key === 'newpolyp_annotation');
                const removed = annotations?.filter((x) => x?.model?.key === 'resection_annotation');
                return polyps?.length - removed?.length > 0;
            },
            extent_of_examination: () => !!caecum?.timestamp,
        }),
        [
            annotations,
            caecum?.timestamp,
            getHospitalAcronym,
            getHospitalName,
            procedure.mode,
            procedure.patient_id,
            procedure.username,
            time.startDate,
            uuid,
        ],
    );

    const aceValues = useMemo(() => {
        return Object.entries(caddieEquivalence).reduce((acc, [key, value]) => {
            acc[key] = value();
            return acc;
        }, {});
    }, [caddieEquivalence]);

    const issues = useMemo(
        () =>
            sanityChecks
                .map((check) => {
                    const ggVal = patient?.[check.field];
                    const caddieVal = aceValues[check.field];
                    if (!check.eval(ggVal, caddieVal)) {
                        const ignored = ignoredIssues.find((x) => x.issue === extractIssueString(check.name));
                        return {
                            name: check.name,
                            field: check.field,
                            catch: ggVal,
                            caddie: caddieVal,
                            ignored: !!ignored,
                            ignored_comment_id: ignored?.comment_id,
                        };
                    }
                    return null;
                })
                .filter((x) => x !== null),
        [aceValues, ignoredIssues, patient],
    );

    return {
        issues,
        issuesCount: useMemo(() => issues.filter((x) => !x.ignored).length, [issues]),
        aceValues,
    };
}

export function useReportAnomalySummary() {
    const { app } = useAceApp();
    const { data: reports } = useClinicalreportGgpatientList({ queryParams: { app } });
    const { data: ignoredIssues } = useClinicalreportEagleIgnoredIssues({ queryParams: { app } });

    const statusesToInclude = [caseStatuses.issues, caseStatuses.awaitingApproval];
    const cases = (reports ?? []).filter((x) => statusesToInclude.includes(x.ext_task_status));

    function extendedAnomalyAnalysis(report) {
        const startData = new Date(report.ext_start);

        const aceValues = {
            procedure_id: report.procedure_id,
            hospital_name: report.ext_hospital,
            hospital_short_name: report.ext_username,
            patient_id: report.ext_patient_id,
            date_of_procedure: startData.toISOString().split('T')[0],
            randomisation: report.ext_mode,
            time: startData.toLocaleTimeString('en-GB'),
            start_time: startData.toLocaleTimeString('en-GB'),
            total_lesions_removed_or_biopsied: report.ext_resection_count,
            any_polyps_detected_not_removed: report.ext_polyp_count - report.ext_resection_count > 0,
            extent_of_examination: !!report.ext_caecum,
        };

        const issues = sanityChecks
            .filter((check) => check.field !== 'procedure_id')
            .map((check) => {
                const ggVal = report[check.field];
                const aceVal = aceValues[check.field];
                if (!check.eval(ggVal, aceVal)) {
                    const ignored = (ignoredIssues ?? [])
                        .filter((x) => x.procedure_id === report.procedure_id)
                        .find((x) => extractIssueString(x.name) === extractIssueString(check.name));
                    return {
                        name: check.name,
                        field: check.field,
                        catch: ggVal,
                        caddie: aceVal,
                        ignored: !!ignored,
                        ignored_comment_id: ignored?.id,
                    };
                }
                return null;
            })
            .filter((x) => x !== null);

        return {
            issues,
            issuesCount: issues.filter((x) => !x.ignored).length,
            aceValues,
        };
    }

    return cases
        .map((x) => {
            const analysis = extendedAnomalyAnalysis(x);
            const allIssues = sanityChecks
                .filter((check) => check.field !== 'procedure_id')
                .map((check) => ({
                    field: check.field,
                    issue: analysis.issues.find((y) => y.name === check.name) !== undefined,
                }));
            return {
                id: x.id,
                procedure_id: x.procedure_id,
                hospital_name: x.hospital_name,
                patient_id: x.patient_id,
                issue_count: analysis.issuesCount,
                ...allIssues.reduce((acc, cur) => {
                    acc[cur.field] = cur.issue;
                    return acc;
                }, {}),
                other: x.ext_issue ? x.ext_issue : null,
                ignoredIssues: analysis.issues
                    .filter((x) => x.ignored)
                    .map((x) => ({
                        name: x.name,
                        comment_id: x.ignored_comment_id,
                    })),
            };
        })
        .filter((x) => x.issue_count > 0 || x.other !== null);
}
