import ProcedureDataGrid from '../search/ProcedureDataGrid';
import PaperCard from '../report/PaperCard';
import { useState } from 'react';

const sortMethod = [{ field: 'index', sort: 'desc' }];

export default function PinnedProcedures({ pin }) {
    const [sortModel, setSortModel] = useState(sortMethod);

    return (
        <PaperCard title='Pinned Procedures' xs={12}>
            <ProcedureDataGrid
                rows={pin.procs}
                pageSize={10}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                pin={pin}
                unpinMode
                extraColumn={[
                    {
                        field: 'index',
                        headerName: 'i',
                        flex: 0.1,
                    },
                ]}
            />
        </PaperCard>
    );
}
