import Grid from '@mui/material/Grid';
import useAuthorized from '../aceapi/hooks/useAuthorized';
import Forbidden from '../components/Login/Forbidden';
import ProcedureTitle from '../components/report/ProcedureTitle';
import EventTimeline from '../components/Charts/EventTimeline';
import useShow from '../aceapi/hooks/useShow';
import useVideoSeeker from '../components/stream/useVideoSeeker';
import useVideoKeyNav from '../components/shortcuts/useVideoKeyNav';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import ShortEventSummary from '../components/report/ShortEventSummary';
import PaperCard from '../components/report/PaperCard';
import { useEffect, useState } from 'react';
import VideoOrStream from '../components/stream/VideoOrStream';
import useProcOngoing from '../aceapi/hooks/useProcOngoing';
import useProcCaecum from '../aceapi/hooks/useProcCaecum';
import { useImagesRead } from '../aceapi/aceComponents';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';

function ImageOrBlank({ url, alt }) {
    const commonStyle = {
        width: '100%',
        height: 'auto',
        borderRadius: '0.5rem',
    };

    return url ? (
        <img src={url} alt={alt} style={commonStyle} />
    ) : (
        <ImageNotSupportedOutlinedIcon
            style={{
                ...commonStyle,
                color: '#aaaaaa',
                backgroundColor: '#000000',
                padding: '1rem',
            }}
        />
    );
}

export default function ProcedureCAQ(props) {
    const { app } = useAceApp();
    const { pin } = props;
    const { uuid } = useParams();
    const authorized = useAuthorized();
    const show = useShow();

    const seeker = useVideoSeeker();
    useVideoKeyNav(seeker);

    const [unavailable, setUnavailable] = useState({});

    useEffect(() => {
        if (Object.keys(unavailable ?? {}).length === 0) return;
        console.log('Some data is unavailable: ', unavailable);
    }, [unavailable]);

    const caecum = useProcCaecum();

    const { streaming } = useProcOngoing();

    const { data: caecumFrames } = useImagesRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: {
                key: 'caecum',
                value: 'yes',
                count: 1,
                filter_blobs: true,
                app,
            },
        },
        {
            refetchInterval: streaming ? 10000 : false,
        },
    );

    return !authorized ? (
        <Forbidden />
    ) : (
        <Grid container spacing={2}>
            <ProcedureTitle pin={pin} />
            <Grid item xs={12} container spacing={2} alignItems='flex-start'>
                {show.video && (
                    <PaperCard xs={8}>
                        <VideoOrStream
                            streaming={streaming}
                            seeker={seeker}
                            setUnavailable={setUnavailable}
                            streamProps={{
                                maxHeight: '52rem',
                            }}
                        />
                    </PaperCard>
                )}
                {show.timeline && (
                    <PaperCard xs={4}>
                        <Stack direction='column' justifyContent='space-between' height='100%'>
                            <ShortEventSummary
                                caecum={caecum?.relativeTs}
                                withdrawal={caecum.withdrawalTime}
                                uuid={uuid}
                                caq
                            />
                            <EventTimeline maxHeight='42rem' seeker={seeker} startExpanded fixedState />
                            <Grid container spacing={1}>
                                <Grid xs={6} item>
                                    <ImageOrBlank url={caecumFrames?.image_frames?.[0]?.url} alt='caecum' />
                                </Grid>
                                <Grid xs={6} item>
                                    <ImageOrBlank alt='retroflexion' />
                                </Grid>
                            </Grid>
                        </Stack>
                    </PaperCard>
                )}
            </Grid>
        </Grid>
    );
}
