import { useState } from 'react';
import { Box, Grid, IconButton, Tooltip, Zoom } from '@mui/material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const CollapseButton = ({ setCollapsed }) => {
    return (
        <IconButton
            onClick={() => setCollapsed(true)}
            sx={{
                position: 'absolute',
                left: '1rem',
                bottom: 0,
                zIndex: 50,
            }}
        >
            <Tooltip title='Collapse panel'>
                <CloseFullscreenIcon />
            </Tooltip>
        </IconButton>
    );
};

const ExpandButton = ({ setCollapsed }) => {
    return (
        <IconButton
            onClick={() => setCollapsed(false)}
            sx={{
                position: 'absolute',
                right: '-.5rem',
                bottom: '-.5rem',
                zIndex: 50,
            }}
        >
            <Tooltip title='Restore panel'>
                <OpenInFullIcon />
            </Tooltip>
        </IconButton>
    );
};

export default function CollapsibleStack(props) {
    const { children, collapsibleSize = 4, size = 12, spacing = 2 } = props;

    const [collapsed, setCollapsed] = useState(false);
    const normalChildren = children.slice(0, -1);
    const lastChild = children[children.length - 1];

    return (
        <Grid item xs={size} container spacing={spacing}>
            {normalChildren.map((child, i) => (
                <Grid item xs key={i}>
                    {child}
                </Grid>
            ))}
            <Box position='relative'>
                {collapsed ? (
                    <ExpandButton setCollapsed={setCollapsed} />
                ) : (
                    <CollapseButton setCollapsed={setCollapsed} />
                )}
            </Box>
            <Zoom in={!collapsed} unmountOnExit>
                <Grid item={!collapsed} xs={collapsed ? undefined : collapsibleSize}>
                    {lastChild}
                </Grid>
            </Zoom>
        </Grid>
    );
}
