import { Skeleton, Stack, Typography } from '@mui/material';
import PaperCard from './PaperCard';
import QRCode from 'react-qr-code';
import useProcTime from '../../aceapi/hooks/useProcTime';
import { AsyncBoundary } from '../../aceapi/utils';
import React, { useMemo } from 'react';

function AsyncProcedureCard({ unavailable }) {
    const time = useProcTime();

    const qrcode = useMemo(() => <QRCode value={window.location.href} size={100} />, []);

    return (
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='column' maxWidth={340}>
                <Typography align='left'>
                    <b>Start</b>: {time.startDate.toLocaleString('en-GB')}
                </Typography>
                {time.estimate && (
                    <Typography align='left'>
                        <b>{time.getStatusMessage()}</b>
                    </Typography>
                )}
                <Typography align='left'>
                    <b>{time.estimate ? 'Last data from' : 'End'}</b>: {time.endDate.toLocaleString('en-GB')}
                </Typography>
                {Object.entries(unavailable).map(([name, reason]) => (
                    <Typography key={name} align='left'>
                        <b>{name}</b>: {reason}
                    </Typography>
                ))}
            </Stack>
            {qrcode}
        </Stack>
    );
}

export default function ProcedureCard(props) {
    return (
        <PaperCard title='Procedure'>
            <AsyncBoundary
                fallback={
                    <Stack direction='row' spacing={1}>
                        <Stack width='calc(100% - 100px)' spacing={1}>
                            <Skeleton variant='rounded' height={46} animation='wave' />
                            <Skeleton variant='rounded' height={46} animation='wave' />
                        </Stack>
                        <Skeleton variant='rounded' width={100} height={100} animation='wave' />
                    </Stack>
                }
            >
                <AsyncProcedureCard {...props} />
            </AsyncBoundary>
        </PaperCard>
    );
}
