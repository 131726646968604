import useStickyState from '../../report/useStickyState';
import hash from 'object-hash';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFindList } from '../../../aceapi/aceComponents';
import { useAceApp } from '../../Menu/ReportAppSelector';

const MAX_HISTORY_LENGTH = 20;

const formatDate = (d, included) => {
    if (included) d.setHours(23, 59, 59, 999);
    else d.setHours(0, 0, 0, 0);
    return d.getTime();
};

const defaultListHandler = {
    get: function (target, property) {
        return property in target ? target[property] : [];
    },
};

const searchFields = [
    { name: 'username', param: 'username__icontains' },
    { name: 'patient_id', param: 'recordings__patient_id__icontains' },
    { name: 'status', param: 'status' },
    { name: 'mode', param: 'mode' },
    { name: 'from', param: 'start__gte', format: (x) => formatDate(x, false) },
    { name: 'to', param: 'start__lte', format: (x) => formatDate(x, true) },
    { name: 'hide_blank_patient_ids', param: 'recordings__patient_id__isnull', format: (x) => x === 'true' },
];

export default function useSearch() {
    const { app } = useAceApp();
    const { search } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams(localStorage.getItem('searchParams') || '');
    const queryParams = Object.fromEntries(searchParams.entries());

    const { data: searchResult, isLoading } = useFindList(
        {
            queryParams: {
                app,
                ...queryParams,
            },
        },
        { suspense: false },
    );

    const totalCount = searchResult?.count ?? 0;

    useEffect(() => {
        if (!search) {
            setSearchParams(localStorage.getItem('searchParams') || '');
        }
    }, [search, setSearchParams]);

    const hasParams = Array.from(searchParams.entries()).length > 0;

    const [searchFilters, setSearchFilters] = useState(() => {
        const sf = {};
        if (hasParams) {
            for (const field of searchFields) {
                if (searchParams.has(field.param)) sf[field.name] = searchParams.get(field.param);
            }
        }
        if (sf?.from) sf.from = new Date(parseInt(sf.from));
        if (sf?.to) sf.to = new Date(parseInt(sf.to));
        return sf;
    });

    const [searchSortModel, setSearchSortModel] = useState(() => {
        if (hasParams) {
            const ordering = searchParams.get('ordering');
            return (
                ordering?.split(',').map((x) => {
                    return { field: x.replace(/^-/, ''), sort: x.startsWith('-') ? 'desc' : 'asc' };
                }) ?? []
            );
        }
        return [
            {
                field: 'start',
                sort: 'desc',
            },
        ];
    });

    const [searchCurrentPage, setSearchCurrentPage] = useState(() => {
        if (hasParams) {
            const page = searchParams.get('page');
            return page ? parseInt(page) - 1 : 0;
        }
        return 0;
    });

    useEffect(() => {
        setSearchCurrentPage(0);
    }, [app]);

    const defaultListHandlerProxy = new Proxy({}, defaultListHandler);

    const [searchHistory, setSearchHistory] = useStickyState('searchHistory', defaultListHandlerProxy, (h) => {
        const history = new Proxy(JSON.parse(h), defaultListHandler);
        Object.entries(history).forEach((v) => {
            v[1].forEach((h) => {
                if (h.filters.from) h.filters.from = new Date(h.filters.from);
                if (h.filters.to) h.filters.to = new Date(h.filters.to);
            });
        });
        return history;
    });

    const submitSearch = async (newFilters, page, sortModel) => {
        const params = [];
        if (newFilters) {
            for (const field of searchFields) {
                if (newFilters[field.name])
                    params.push(
                        `${field.param}=${field.format ? field.format(newFilters[field.name]) : newFilters[field.name]}`,
                    );
            }
        }

        let apiPage = (page ?? searchCurrentPage) + 1;
        if (page === -1) {
            // So we can reset to page one on actual submit
            setSearchCurrentPage(0);
            apiPage = 1;
        }
        const apiOrdering = (sortModel ?? searchSortModel)
            .map((s) => `${s.sort === 'asc' ? '' : '-'}${s.field}`)
            .join(',');
        const allParams = `page=${apiPage}&ordering=${apiOrdering}&${params.join('&')}`;
        setSearchParams(allParams);
        localStorage.setItem('searchParams', allParams);

        setSearchFilters(newFilters);
        setSearchHistory((prevState) => {
            prevState = new Proxy({ ...prevState }, defaultListHandler);
            if (newFilters === null) return prevState;
            const searchHash = hash(newFilters);
            const previousSearch = prevState[app].find((s) => s.hash === searchHash);
            const others = prevState[app].filter((s) => s.hash !== searchHash);
            let unpinned = others.filter((s) => !s.pinned);
            while (unpinned.length >= MAX_HISTORY_LENGTH) {
                others.splice(
                    others.indexOf(unpinned.reduce((oldest, s) => (s.timestamp < oldest.timestamp ? s : oldest))),
                    1,
                );
                unpinned = others.filter((s) => !s.pinned);
            }
            prevState[app] = [
                ...others,
                {
                    filters: newFilters,
                    hash: searchHash,
                    timestamp: previousSearch?.timestamp ?? Date.now(),
                    pinned: previousSearch?.pinned ?? false,
                },
            ];
            return prevState;
        });
    };

    const sortModelChanged = async (newSortModel) => {
        setSearchSortModel(newSortModel);
        await submitSearch(searchFilters, -1, newSortModel);
    };

    const pageChanged = async (page) => {
        setSearchCurrentPage(page);
        await submitSearch(searchFilters, page, searchSortModel);
    };

    const resetSearch = async () => {
        setSearchSortModel([{ field: 'start', sort: 'desc' }]);
        setSearchCurrentPage(0);
        await submitSearch(null, 0, [{ field: 'start', sort: 'desc' }]);
    };

    const refreshSearch = async () => {
        await submitSearch(searchFilters);
    };

    const removeFromHistory = (hash) =>
        setSearchHistory((prevState) => {
            prevState[app] = prevState[app].filter((s) => s.hash !== hash);
            return { ...prevState };
        });

    const clearHistory = () =>
        setSearchHistory((prevState) => {
            prevState[app] = prevState[app].filter((s) => s.pinned);
            return { ...prevState };
        });

    const restoreHistory = async (hash) => {
        const history = searchHistory[app].find((s) => s.hash === hash);
        if (history) await submitSearch(history.filters);
    };

    const historyTogglePin = (hash) =>
        setSearchHistory((prevState) => {
            const history = prevState[app].find((s) => s.hash === hash);
            if (history) {
                history.pinned = !history.pinned;
                return { ...prevState };
            }
            return prevState;
        });

    return {
        filters: { state: searchFilters, setState: setSearchFilters },
        result: isLoading ? 'loading' : searchResult?.results || [],
        totalCount: searchResult?.results ? totalCount : 0,
        sortModel: { state: searchSortModel, setState: setSearchSortModel },
        currentPage: { state: searchCurrentPage, setState: setSearchCurrentPage },
        isLoading: isLoading,
        submit: submitSearch,
        sortModelChanged: sortModelChanged,
        pageChanged: pageChanged,
        reset: resetSearch,
        refresh: refreshSearch,
        history: {
            state: searchHistory[app],
            setState: setSearchHistory,
            remove: removeFromHistory,
            clear: clearHistory,
            restore: restoreHistory,
            togglePin: historyTogglePin,
        },
    };
}
