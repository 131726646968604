import {
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import ClearIcon from '@mui/icons-material/Clear';
import ConfirmButton from '../dialogs/ConfirmButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';

function SearchEntry({ search, entry }) {
    return (
        <ListItemButton onClick={() => search.history.restore(entry.hash)} disabled={search.isLoading}>
            <ListItemIcon>{entry.pinned ? <BookmarkIcon /> : <RestoreIcon />}</ListItemIcon>
            <ListItemText>
                <i style={{ marginRight: '1rem' }}>{new Date(entry.timestamp).toLocaleString('en-GB')}:</i>{' '}
                {entry.filters.product || 'Any Product'} | {entry.filters.patient_id || 'Any PatientID'} |{' '}
                {entry.filters.username || 'Any Username'} | {entry.filters.status || 'Any Status'} |{' '}
                {entry.filters.mode || 'Any Mode'} | From {entry.filters.from?.toLocaleDateString('en-GB') || '*'} | To{' '}
                {entry.filters.to?.toLocaleDateString('en-GB') || '*'}
            </ListItemText>
        </ListItemButton>
    );
}

function SearchEntryActions({ search, entry }) {
    return (
        <>
            <Tooltip title='Save search profile'>
                <IconButton onClick={() => search.history.togglePin(entry.hash)}>
                    {entry.pinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}
                </IconButton>
            </Tooltip>
            <ConfirmButton
                ButtonType={IconButton}
                action='remove this from the search history'
                edge='end'
                onConfirm={() => search.history.remove(entry.hash)}
            >
                <ClearIcon />
            </ConfirmButton>
        </>
    );
}

export default function SearchHistory({ search }) {
    const allHistory = search.history.state;
    const history = allHistory.filter((x) => !x.pinned).sort((a, b) => b.timestamp - a.timestamp);
    const pinned = allHistory.filter((x) => x.pinned).sort((a, b) => b.timestamp - a.timestamp);

    return (
        <>
            <Stack direction='row' alignItems='center'>
                <Typography variant='h6'>Search History</Typography>
                <ConfirmButton
                    ButtonType={IconButton}
                    action='irreversibly clear the search history (pinned searches will be preserved)'
                    onConfirm={() => search.history.clear()}
                >
                    <DeleteForeverIcon />
                </ConfirmButton>
            </Stack>
            <List
                dense
                sx={{
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    overflowY: 'auto',
                    maxHeight: '12rem',
                }}
            >
                {pinned.map((x, i) => (
                    <ListItem
                        key={x.hash}
                        sx={{ backgroundColor: i % 2 === 0 ? '#cce' : '#ddf' }}
                        secondaryAction={<SearchEntryActions search={search} entry={x} />}
                        disablePadding
                    >
                        <SearchEntry search={search} entry={x} />
                    </ListItem>
                ))}
                {history.map((x, i) => (
                    <ListItem
                        key={x.hash}
                        sx={{ backgroundColor: i % 2 === 0 ? '#eee' : '#fff' }}
                        secondaryAction={<SearchEntryActions search={search} entry={x} />}
                        disablePadding
                    >
                        <SearchEntry search={search} entry={x} />
                    </ListItem>
                ))}
                {allHistory.length === 0 && (
                    <ListItem>
                        <ListItemText>No entries</ListItemText>
                    </ListItem>
                )}
            </List>
        </>
    );
}
