import * as React from 'react';
import { useContext } from 'react';
import PaperFloatingCard from '../report/PaperFloatingCard';
import CaddieStream from './CaddieStream';
import { ExpandedStreamContext } from '../../config/contexts';
import { IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function ExpandedStreamModal() {
    const expandedStream = useContext(ExpandedStreamContext);
    const { app = null, uuid = null } = expandedStream?.[0] || {};
    const setExpandedStreamState = expandedStream?.[1] || (() => null);

    return (
        app &&
        uuid && (
            <PaperFloatingCard
                top='5rem'
                left='50%'
                transform='translateX(-50%)'
                height='calc(min(100vh - 10rem, 100vw - 10rem))'
                width='calc(min(100vh - 10rem, 100vw - 10rem))'
            >
                <CaddieStream
                    app={app}
                    uuid={uuid}
                    maxHeight='calc(min(100vh - 10rem, 100vw - 10rem) - 2rem)'
                    expanded
                />
                <IconButton
                    variant='contained'
                    onClick={() => setExpandedStreamState(null)}
                    sx={{ position: 'absolute', top: -11, left: -11, zIndex: 2 }}
                >
                    <Tooltip title='Close'>
                        <CloseIcon color='error' />
                    </Tooltip>
                </IconButton>
            </PaperFloatingCard>
        )
    );
}
