import useStickyState from '../../report/useStickyState';

export default function usePinnedProcedures() {
    const [state, setState] = useStickyState('pinnedProcedures', [], (s) => {
        const sf = JSON.parse(s);
        return sf.filter((x) => typeof x === 'object' && typeof x.procedure_id === 'string');
    });

    const addProcedure = (procedure) => {
        console.log('addProcedure', procedure);
        setState((prevState) => [...prevState, procedure]);
    };

    const removeProcedure = (pid) => {
        setState((prevState) => prevState.filter((x) => x.procedure_id !== pid));
    };

    return {
        procs: state,
        addProcedure,
        removeProcedure,
    };
}
