import { useParams } from 'react-router-dom';
import Forbidden from '../components/Login/Forbidden';
import PaperCard from '../components/report/PaperCard';
import { Stack, Typography } from '@mui/material';
import useFrames from '../components/report/useFrames';
import FrameGrid from '../components/Charts/timeline/FrameGrid';
import format from 'format-duration';
import { useCallback, useContext, useEffect } from 'react';
import useDebugOptions from '../components/report/useDebugOptions';
import { types } from '../components/summary/hooks/useSummaryOptions';
import ExtraDataLegend from '../components/report/ExtraDataLegend';
import useExtraData from '../components/report/useExtraData';
import useAuthorized from '../aceapi/hooks/useAuthorized';
import useProcTime from '../aceapi/hooks/useProcTime';
import useShow from '../aceapi/hooks/useShow';
import useVideoSeeker from '../components/stream/useVideoSeeker';
import ProcedureStream from '../components/stream/ProcedureStream';
import useKeyBoardEvent from '../components/events/useKeyBoardEvent';
import ReportTimeline from '../components/Charts/ReportTimeline';
import ShortcutCard from '../components/shortcuts/ShortcutCard';
import VideoNavButtons from '../components/stream/VideoNavButtons';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import { useQueryClient } from '@tanstack/react-query';
import { useEditsTimelineCreate, useProceduresTimelineEdits } from '../aceapi/aceComponents';
import CommentSection from '../components/comments/CommentSection';
import { TokenContext } from '../config/contexts';
import CadxAnnotation from '../components/clinical/CADxAnnotation';
import GuruPolypData from '../components/clinical/guru/GuruPolypData';

// TODO: this really needs to be refactored with ProcedureTimelineEdit
export default function PolypCountEdit(props) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const authorized = useAuthorized();
    const show = useShow();
    const procTime = useProcTime();
    const token = useContext(TokenContext);
    const user = token.parse().user;
    const isStaff = user.is_active && user.is_staff;
    const userInfo = { username: user.username, isStaff };

    const { extraRow, extraModels, edits, extraBars } = useExtraData({
        show: show.extra_models,
        authorized,
    });

    const queryClient = useQueryClient();
    const { data: timelineEdits } = useProceduresTimelineEdits(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: authorized && show.timeline, suspense: false },
    );
    const { mutateAsync: createEdit } = useEditsTimelineCreate();

    useEffect(() => {
        edits.setState(timelineEdits ?? []);
    }, [timelineEdits, edits]);

    const noop = useCallback(() => null, []);
    const seeker = useVideoSeeker();

    const frameProps = useFrames({
        uuid,
        setUnavailable: noop,
        authorized,
    });

    const options = useDebugOptions(extraModels !== null, [
        {
            key: 'models',
            type: types.STRING_SELECTOR,
            name: '',
            defaultValue: 8,
            extraProps: {
                options:
                    extraModels?.map((x) => ({
                        key: x.key,
                        name: x.name,
                        value: x.id,
                    })) ?? [],
                extraInputProps: { label: 'Model to alter', helperText: 'For annotating use models ending with *' },
            },
        },
    ]);

    const singleFrameAnnotation = () => {
        if (options.state.models === null) {
            alert('Please select a model to alter');
            return;
        }
        createEdit({
            body: {
                procedure_id: uuid,
                type: 'single',
                model: options.state.models,
                start: seeker.timestamp.state - procTime.start,
                end: null,
                comment: {},
            },
        }).then(async () => {
            await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'timeline_edits'] });
        });
    };

    useKeyBoardEvent({
        watchKeys: ['s'],
        onKeyUp: () => singleFrameAnnotation(),
    });

    return !authorized || !show.timeline ? (
        <Forbidden />
    ) : (
        <Stack container spacing={3}>
            <PaperCard xs={6} title='Clinical Review' variant='h2' align='center'>
                <ProcedureStream uuid={uuid} seeker={seeker} setUnavailable={noop} noPaper />
                <VideoNavButtons seeker={seeker} />
                <Stack spacing={2}>
                    <CadxAnnotation {...frameProps} seeker={seeker} />
                    <Typography variant='h6'>Greenlight Guru Polyp Data</Typography>
                    <GuruPolypData />
                </Stack>
                <ReportTimeline
                    setUnavailable={noop}
                    loaded={noop}
                    toggleBars
                    seeker={seeker}
                    extraRow={extraRow}
                    extraBars={extraBars}
                    compact
                    {...frameProps}
                    {...props}
                />
                <Stack spacing={2}>
                    <Typography variant='h6' color='primary' fontFamily='Roboto Mono, Monospace'>
                        Cursor: {seeker.frameInput()} (video: {format(seeker.timestamp.state - seeker.video_start)} |
                        timeline: {format(seeker.timestamp.state - procTime.start)})
                    </Typography>
                    <ExtraDataLegend extraModels={extraModels} />
                    <FrameGrid {...frameProps} />
                </Stack>
            </PaperCard>
            <ShortcutCard xs={6} />
            {show.comments && (
                <PaperCard xs={6} title='Comments' align='center'>
                    <CommentSection {...userInfo} />
                </PaperCard>
            )}
        </Stack>
    );
}
