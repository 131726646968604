import { AsyncBoundary } from '../../../aceapi/utils';
import {
    useClinicalreportGgpatientFromProcedureId,
    useClinicalreportGgpatientPolypDiagnosis,
} from '../../../aceapi/aceComponents';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { ErrorBoundary } from 'react-error-boundary';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function AsyncGuruPolypData() {
    const { uuid } = useParams();

    const { data: report } = useClinicalreportGgpatientFromProcedureId(
        { queryParams: { procedure_id: uuid } },
        { retry: false },
    );
    const { data: table } = useClinicalreportGgpatientPolypDiagnosis(
        { pathParams: { id: report?.id } },
        { enabled: !!report },
    );

    const { cols, rows } = table ?? { cols: {}, rows: [] };

    const polypIdKey = Object.keys(cols).find((key) => cols[key].toLowerCase().includes('polyp id'));

    const grid_cols = Object.keys(cols).map((key) => ({
        field: key,
        headerName: cols[key],
        width: 150,
    }));

    return (
        <DataGrid
            getRowId={(row) => (polypIdKey ? row[polypIdKey] : Object.keys(cols)[0])}
            rows={rows}
            columns={grid_cols}
            pageSize={5}
            rowsPerPageOptions={[5]}
            autoHeight
            initialState={{
                sorting: {
                    sortModel: [
                        {
                            field: polypIdKey ?? 'id',
                            sort: 'asc',
                        },
                    ],
                },
            }}
        />
    );
}

export default function GuruPolypData(props) {
    return (
        <AsyncBoundary>
            <ErrorBoundary
                fallbackRender={({ error }) => (
                    <Box sx={{ border: 1, borderColor: 'error.main', borderRadius: 1, p: 1 }}>
                        <Typography variant='body2'>
                            Could not get polyp data from greenlight guru: <b>{error?.stack}</b>.
                            <br />
                            Make sure the procedure is correctly linked to the case and that the form answers are valid.
                        </Typography>
                    </Box>
                )}
            >
                <AsyncGuruPolypData {...props} />
            </ErrorBoundary>
        </AsyncBoundary>
    );
}
