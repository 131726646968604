import PaperCard from '../report/PaperCard';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

const navigationShortcuts = [
    { key: '⬅️➡️', description: 'Move frame by frame' },
    { key: 'Shift+⬅️➡️', description: 'Move by 10 frames' },
    { key: 'Ctrl+⬅️➡️', description: 'Move by 100 frames' },
];

const annotationShortcuts = [
    { key: 's', description: 'Add single frame annotation' },
    { key: 'a', description: 'Add annotation' },
    { key: 'd', description: 'Delete annotation' },
    { key: 'p', description: 'Download annotations' },
];

const detectionShortcuts = [
    { key: 'u', description: 'Next detection' },
    { key: 'y', description: 'Previous detection' },
    { key: 'x', description: 'Discard current detection' },
    { key: 'b', description: 'Bookmark current frame' },
    { key: 'n', description: 'Return to bookmarked frame' },
];

export default function ShortcutCard({ xs = 8, showNavigation = true, showAnnotation = true, showDetection = true }) {
    const shortcuts = [
        [...(showNavigation ? navigationShortcuts : []), ...(showAnnotation ? annotationShortcuts : [])],
        [...(showDetection ? detectionShortcuts : [])],
    ];

    return (
        <PaperCard xs={xs} title='Keyboard Shortcuts'>
            <Grid container>
                {shortcuts.map((shortcutGroup, i) => (
                    <Grid key={i} item xs={12 / shortcuts.length}>
                        {shortcutGroup.map(({ key, description }) => (
                            <Typography key={key} fontFamily='Roboto Mono'>
                                <b>{key}</b>: {description}
                            </Typography>
                        ))}
                    </Grid>
                ))}
            </Grid>
        </PaperCard>
    );
}
