import { Divider, Grid, Paper, Typography } from '@mui/material';
import ForceSelectApp from '../components/report/ForceSelectApp';
import PerformanceSearch from '../components/search/PerformanceSearch';

export default function ProcedurePerformanceSearch() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper>
                    <Typography variant='h3' align='center'>
                        Co-creation Cases Search
                    </Typography>
                    <Divider />
                    <ForceSelectApp>
                        <PerformanceSearch />
                    </ForceSelectApp>
                </Paper>
            </Grid>
        </Grid>
    );
}
