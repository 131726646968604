import { Accordion, AccordionDetails, AccordionSummary, Slider, Switch, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StringSelector from './widgets/StringSelector';
import StringMultiSelector from './widgets/StringMultiSelector';
import DateSelector from './widgets/DateSelector';
import SimpleDateSelector from './widgets/SimpleDateSelector';
import format from 'format-duration';
import BlockIcon from '@mui/icons-material/Block';

const timedelta = {
    SECOND: 1000,
    MINUTE: 60 * 1000,
    HOUR: 60 * 60 * 1000,
    DAY: 24 * 60 * 60 * 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000,
    MONTH: 28 * 24 * 60 * 60 * 1000,
    YEAR: 365 * 24 * 60 * 60 * 1000,
};

const type2component = [
    {
        component: (key, value, setOption, extraProps) => (
            <Switch checked={value} onChange={(e) => setOption(key, e.target.checked)} {...extraProps} />
        ),
    },
    {
        component: (key, value, setOption, { timeSlider = false, ...extraProps }) => (
            <Slider
                value={value}
                onChange={(e, v) => setOption(key, v)}
                {...extraProps}
                valueLabelDisplay='auto'
                valueLabelFormat={timeSlider ? (v) => format(v, { leading: true }) : (v) => v}
            />
        ),
    },
    {
        component: (key, value, setOption, extraProps) => (
            <StringSelector value={value} onChange={(v) => setOption(key, v)} {...extraProps} />
        ),
    },
    {
        component: (key, value, setOption, extraProps) => (
            <StringMultiSelector value={value} onChange={(v) => setOption(key, v)} {...extraProps} />
        ),
    },
    {
        component: (key, value, setOption, extraProps) => (
            <DateSelector value={value} onChange={(v) => setOption(key, v)} {...extraProps} />
        ),
    },
    {
        component: (key, value, setOption, extraProps) => (
            <SimpleDateSelector value={value} onChange={(v) => setOption(key, v)} {...extraProps} />
        ),
    },
    {
        component: () => <BlockIcon />,
    },
];

function StatOptionForm({ options, setOption }) {
    return (
        <Accordion sx={{ boxShadow: 4 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Options</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {Object.entries(options).map(([key, { name, type, extraProps, value }]) => (
                    <div key={key}>
                        <Typography>{name}</Typography>
                        {type2component[type].component(key, value, setOption, extraProps)}
                    </div>
                ))}
            </AccordionDetails>
        </Accordion>
    );
}

export { StatOptionForm, timedelta, type2component };
