import { useCommentsCreate, useCommentsPartialUpdate } from '../../aceapi/aceComponents';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export default function useCommentHelper() {
    const { uuid } = useParams();

    const queryClient = useQueryClient();

    const { mutateAsync: createComment } = useCommentsCreate();
    const { mutateAsync: patchComment } = useCommentsPartialUpdate();

    const addComment = useCallback(
        async (name, body) => {
            const comment = await createComment({
                body: {
                    name,
                    body,
                    parent: null,
                    procedure_id: uuid,
                },
            });
            await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'comments'] });
            return comment;
        },
        [createComment, queryClient, uuid],
    );

    const editComment = useCallback(
        async (id, body) => {
            await patchComment({
                pathParams: { id },
                body: { body },
            });
            queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'comments'] });
        },
        [patchComment, queryClient, uuid],
    );

    const softDeleteComment = useCallback(
        async (id) => {
            await patchComment({
                pathParams: { id },
                body: { name: '[deleted]', body: '[deleted]' },
            });
            queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'comments'] });
        },
        [patchComment, queryClient, uuid],
    );

    return { addComment, editComment, softDeleteComment };
}
