import { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Stack, Tab } from '@mui/material';
import { StatOptionForm } from './utils';
import Box from '@mui/material/Box';
import useSummaryOptions from './hooks/useSummaryOptions';
import { useGroupMetagroupsList, useGroupTypesList, useGroupUsersList } from '../../aceapi/aceComponents';

export default function TabPerTypes({ name, component, ...props }) {
    const [value, setValue] = useState(() => localStorage.getItem('summaryTab') || 'user');

    const { data: types } = useGroupTypesList({});
    const { data: users } = useGroupUsersList({});
    const { data: metagroups } = useGroupMetagroupsList({});

    const handleTabChange = (v) => {
        localStorage.setItem('summaryTab', v);
        setValue(v);
    };

    const {
        values: options,
        register: registerOption,
        setValue: setOption,
        rename: renameOption,
        types: optionTypes,
        tableOpt,
    } = useSummaryOptions(name);

    const format_user = users.map((user) => ({
        id: user.id,
        name: user.username,
        type: 'user',
        users: [user.username],
        test: metagroups.filter((x) => x.test).some((x) => x.users.includes(user.username)),
    }));

    const Component = component;

    return (
        <Stack spacing={2}>
            <Box pl='10%' pr='10%'>
                <StatOptionForm options={options} setOption={setOption} />
            </Box>
            <TabContext value={value}>
                <TabList onChange={(e, v) => handleTabChange(v)} centered>
                    <Tab value='user' label='user' />
                    {types.map((type) => (
                        <Tab key={type.id} value={type.name} label={type.name} />
                    ))}
                </TabList>
                <TabPanel value='user'>
                    <Component
                        type='user'
                        groups={format_user}
                        options={options}
                        registerOption={registerOption}
                        renameOption={renameOption}
                        optionTypes={optionTypes}
                        tableOpt={tableOpt}
                        {...props}
                    />
                </TabPanel>
                {types.map((type) => (
                    <TabPanel key={type.id} value={type.name}>
                        <Component
                            type={type.name}
                            groups={metagroups.filter((x) => x.type === type.name)}
                            options={options}
                            registerOption={registerOption}
                            renameOption={renameOption}
                            optionTypes={optionTypes}
                            tableOpt={tableOpt}
                            {...props}
                        />
                    </TabPanel>
                ))}
            </TabContext>
        </Stack>
    );
}
