import { Divider, Typography } from '@mui/material';
import { timedelta } from '../utils';
import ReportLineChart from '../../Charts/stats/ReportLineChart';
import { useEffect, useState } from 'react';
import useCommonSummaryFilters from '../hooks/useCommonSummaryFilters';
import { useAceApp } from '../../Menu/ReportAppSelector';
import { useSummaryProceduresMaxDuration } from '../../../aceapi/aceComponents';

export default function ProcedureTrendStat({ type, groups, registerOption, renameOption, options, optionTypes }) {
    const { app } = useAceApp();
    const { data: unsortedProcs } = useSummaryProceduresMaxDuration({ queryParams: { app } });
    const procs = unsortedProcs?.sort((x, y) => x.start - y.start) ?? [];
    const { filteredGroups, filteredProcs } = useCommonSummaryFilters({
        groups,
        procs,
        registerOption,
        renameOption,
        options,
        optionTypes,
    });

    const [formatted, setFormatted] = useState([]);
    const [period, setPeriod] = useState(timedelta.WEEK);
    const [start, setStart] = useState(1619395200000);

    useEffect(() => {
        registerOption('start', 'Starting date', optionTypes.SIMPLE_DATE_SELECTOR, 1619395200000);
        registerOption(
            'period',
            'Trend period length',
            optionTypes.DATE_SELECTOR,
            { name: 'week' },
            {
                disableCustom: true,
                options: [
                    { key: 'day', name: '1 day', value: { name: 'day', ts: timedelta.DAY } },
                    { key: 'week', name: '1 week (7 days)', value: { name: 'week', ts: timedelta.WEEK } },
                    { key: 'week2', name: '2 weeks (14 days)', value: { name: 'week2', ts: 2 * timedelta.WEEK } },
                    { key: 'month', name: '1 month', value: { name: 'month', ts: timedelta.MONTH } },
                    { key: 'month2', name: '2 months', value: { name: 'month2', ts: 2 * timedelta.MONTH } },
                    { key: 'month6', name: '6 months', value: { name: 'month6', ts: 6 * timedelta.MONTH } },
                    { key: 'year', name: '1 year (12 months)', value: { name: 'year', ts: 12 * timedelta.MONTH } },
                ],
            },
        );
    }, [optionTypes, registerOption]);

    useEffect(() => {
        const newStart = options?.['start']?.value;
        if (newStart > 1514764800000) setStart(newStart);

        const newPeriod = options?.['period']?.value?.ts;
        if (newPeriod) setPeriod(newPeriod);
    }, [options]);

    useEffect(() => {
        const now = Date.now();
        const span = now - start;
        let N = Math.ceil(span / period);
        N = N < 1 ? 1 : N;
        const spans = Array(N)
            .fill()
            .map((_, i) => {
                if (period < timedelta.MONTH) return start + period * i;
                const dateStart = new Date(start);
                dateStart.setMonth(dateStart.getMonth() + i * (period / timedelta.MONTH), 1);
                return dateStart.getTime();
            });

        const getValues = (group) => {
            const nprocs = filteredProcs.filter((x) => group.users.includes(x.username));
            const values = Array(N).fill(0);
            let i = 0;
            let p = 0;
            while (i < N && p < nprocs.length) {
                const proc = nprocs[p];
                if (!proc.start || proc.start < spans[i]) {
                    ++p;
                    continue;
                }
                while (i < N - 1 && proc.start >= spans[i + 1]) ++i;
                values[i]++;
                ++p;
            }
            return values;
        };

        const values = filteredGroups.map((x) => getValues(x));
        const formatted_var = spans.map((span, i) => ({
            time: span,
            ...filteredGroups.map((group, j) => ({ [group.name]: values[j][i] })).reduce((x, y) => ({ ...x, ...y })),
        }));

        setFormatted(formatted_var);
    }, [filteredGroups, filteredProcs, period, start]);

    return (
        <>
            <Typography variant='h4'>Procedure trend per {type}</Typography>
            <Divider />
            <ReportLineChart formatted={formatted} groups={filteredGroups} period={period} />
        </>
    );
}
