import { Box, CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AsyncBoundary } from '../../aceapi/utils';
import { useState } from 'react';
import CommentIcon from '@mui/icons-material/Comment';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import PendingIcon from '@mui/icons-material/Pending';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import CommentNotification from './CommentNotification';
import MouseNotification from './MouseNotification';
import CustomNotification from './CustomNotification';
import CancelIcon from '@mui/icons-material/Cancel';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import ReportIcon from '@mui/icons-material/Report';
import { useNotificationsDelete, useNotificationsList } from '../../aceapi/aceComponents';
import { useQueryClient } from '@tanstack/react-query';

const getNotificationType = (notif) => {
    if (notif.comment) {
        return CommentNotification;
    } else if (notif.dagrun) {
        return MouseNotification;
    } else {
        return CustomNotification;
    }
};

const getNotificationIcon = (notif) => {
    switch (notif.reason) {
        case 'new_reply':
            return <CommentIcon />;
        case 'reply_edit':
            return <EditNotificationsIcon />;
        case 'diagnosis_request':
        case 'detection_request':
            return <InfoIcon />;
        case 'dagrun_triggered':
            return <PendingIcon />;
        case 'dagrun_result':
            return <AssignmentTurnedInIcon />;
        case 'dagrun_failed':
        case 'dagrun_unreachable':
        case 'dagrun_invalid':
        case 'dagrun_error':
            return <AssignmentLateIcon />;
        case 'dagrun_rejected':
        case 'dagrun_cancelled':
            return <CancelIcon />;
        case 'mouse_timeout':
            return <RunningWithErrorsIcon />;
        case 'mouse_error':
            return <ReportIcon />;
        default:
            return <NotificationImportantIcon />;
    }
};

function AsyncReportNotifications() {
    const { data: notifs } = useNotificationsList({}, { refetchInterval: 5000 });
    const { mutateAsync: deleteNotif } = useNotificationsDelete();

    const queryClient = useQueryClient();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleDelete = (nid) => (e) => {
        e.stopPropagation();
        deleteNotif({ pathParams: { id: nid } })
            .then(async () => await queryClient.invalidateQueries({ queryKey: ['notifications'] }))
            .catch((err) => console.log(err));
    };

    return (
        <>
            <IconButton size='large' color='inherit' onClick={(e) => setAnchorEl(e.currentTarget)}>
                <Badge badgeContent={notifs.length} color='error'>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu open={open} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} sx={{ maxWidth: '40vw' }}>
                {notifs.map((notif) => {
                    const NotificationType = getNotificationType(notif);
                    return (
                        <NotificationType
                            key={notif.id}
                            notif={notif}
                            icon={getNotificationIcon(notif)}
                            clear={handleDelete(notif.id)}
                        />
                    );
                })}
                {notifs.length === 0 && (
                    <MenuItem>
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon>
                        <ListItemText>No notifications to show</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}

export default function ReportNotifications() {
    return (
        <Box sx={{ display: { xs: 'none', md: 'flex' } }} mr={1}>
            <AsyncBoundary
                fallback={
                    <div style={{ position: 'relative' }}>
                        <IconButton size='large' color='inherit'>
                            <NotificationsIcon />
                        </IconButton>
                        <CircularProgress
                            color='warning'
                            sx={{
                                position: 'absolute',
                                top: 6,
                                left: 3,
                                zIndex: 3,
                            }}
                        />
                    </div>
                }
            >
                <AsyncReportNotifications />
            </AsyncBoundary>
        </Box>
    );
}
