import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { IconButton, Stack, Switch, Tooltip, Typography } from '@mui/material';
import ProcedureDataGrid from '../components/search/ProcedureDataGrid';
import PaperCard from '../components/report/PaperCard';
import RefreshIcon from '@mui/icons-material/Refresh';
import ProcedureQuickSearch from '../components/dashboard/ProcedureQuickSearch';
import useStickyState from '../components/report/useStickyState';
import PinnedProcedures from '../components/dashboard/PinnedProcedures';
import useMiscParams from '../components/dashboard/hooks/useMiscParams';
import useShow from '../aceapi/hooks/useShow';
import { useGroupTestUsersList, useProceduresLatest } from '../aceapi/aceComponents';
import { useQueryClient } from '@tanstack/react-query';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import ErrorIcon from '@mui/icons-material/Error';

const sortMethod = [{ field: 'start', sort: 'desc' }];

export default function Dashboard({ pin }) {
    const { app } = useAceApp();
    const [autoRefresh, setAutoRefresh] = useStickyState('dashboardAutoRefresh', true);
    const [lastRefresh, setLastRefresh] = useState(new Date(Date.now()));

    const { data: allLatestProcs } = useProceduresLatest(
        { queryParams: { app } },
        { refetchInterval: autoRefresh ? 5000 : false },
    );

    const queryClient = useQueryClient();

    const show = useShow();

    const { data: testUsers } = useGroupTestUsersList({});
    const testUsernames = testUsers.map((x) => x.username);

    const params = useMiscParams([
        {
            key: 'hideTestUsers',
            name: 'Hide test users',
            tooltip: 'Hide all procedures of users that are registered as test users',
            defaultValue: true,
            enabled: Array.isArray(testUsers) && testUsers.length > 0,
        },
        {
            key: 'showStream',
            name: 'Show stream',
            defaultValue: true,
            tooltip: 'Show the video stream of all ongoing procedures',
            enabled: show.stream,
        },
    ]);

    const latestProcs = params.state.hideTestUsers
        ? allLatestProcs.procedures?.filter((x) => !testUsernames.includes(x.username))
        : allLatestProcs.procedures;

    const unreachableApps = useMemo(
        () => (allLatestProcs?.unreachable_apps ?? []).map((x) => x.toUpperCase()),
        [allLatestProcs],
    );

    useEffect(() => {
        setLastRefresh(new Date(Date.now()));
    }, [allLatestProcs]);

    const ongoingProcs = latestProcs.filter((p) => p.status === 'STARTED');

    const refreshLatest = async () => {
        await queryClient.invalidateQueries({ queryKey: ['procedures', 'latest'] });
    };

    return (
        <Grid container spacing={3}>
            {unreachableApps.length > 0 && (
                <PaperCard title='Unreachable Apps' xs={12} color='error'>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <ErrorIcon color='error' />
                        <Typography variant='h6'>The following apps appear to be unreachable:</Typography>
                        <Typography variant='h6' color='error'>
                            <b>{unreachableApps.join(', ')}</b>
                        </Typography>
                        <ErrorIcon color='error' />
                    </Stack>
                </PaperCard>
            )}
            <PaperCard title={`Quick Procedure search (${app})`} xs={12}>
                <ProcedureQuickSearch />
            </PaperCard>
            <PaperCard title='Options' xs={2}>
                <Typography>Auto Refresh</Typography>
                <Stack direction='row' alignItems='center'>
                    <Tooltip title='Toggle auto-refresh'>
                        <Switch checked={autoRefresh} onChange={(e) => setAutoRefresh(e.target.checked)} />
                    </Tooltip>
                    <Tooltip title='Latest data fetch time'>
                        <Typography>{lastRefresh.toLocaleTimeString('en-GB')}</Typography>
                    </Tooltip>
                    {!autoRefresh && (
                        <Tooltip title='Trigger manual refresh'>
                            <IconButton onClick={refreshLatest}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
                {params.render()}
            </PaperCard>
            <PaperCard title={`Ongoing Procedures (${app})`} xs={10}>
                {ongoingProcs.length > 0 ? (
                    <ProcedureDataGrid
                        rows={ongoingProcs}
                        pageSize={7}
                        sortModel={sortMethod}
                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        sortable={false}
                        sortingMode='server'
                        pin={pin}
                        streaming={show.stream && params.state.showStream}
                    />
                ) : (
                    <Typography>No currently ongoing procedures.</Typography>
                )}
            </PaperCard>
            <PaperCard
                title={`Latest Procedures (${app})${
                    unreachableApps.length > 0 ? ` (excluding ${unreachableApps.join(', ')})` : ''
                }`}
                xs={12}
            >
                <ProcedureDataGrid
                    rows={latestProcs}
                    pageSize={7}
                    sortModel={sortMethod}
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSelector
                    sortable={false}
                    sortingMode='server'
                    pin={pin}
                />
            </PaperCard>
            <PinnedProcedures pin={pin} />
        </Grid>
    );
}
