import * as d3 from 'd3';
import useD3 from '../d3/useD3';
import { useEffect, useMemo, useState } from 'react';
import useProcTime from '../../../aceapi/hooks/useProcTime';
import useKeyBoardEvent from '../../events/useKeyBoardEvent';

export function useTimelineBrush(seeker) {
    const [interval, setInterval] = useState(null);
    const video_start = seeker.video_start;

    const smartSetInterval = (bound, intended = 'start') => {
        setInterval((prevState) => {
            if (bound < (prevState?.start ?? Infinity)) return { start: bound, end: prevState?.end ?? bound };
            if (bound > (prevState?.end ?? -Infinity)) return { start: prevState?.start, end: bound };
            return { ...prevState, [intended]: bound };
        });
    };

    useKeyBoardEvent({
        watchKeys: ['['],
        onKeyUp: () => smartSetInterval(seeker.timestamp.state, 'start'),
    });

    useKeyBoardEvent({
        watchKeys: [']'],
        onKeyUp: () => smartSetInterval(seeker.timestamp.state, 'end'),
    });

    const frameInterval = useMemo(() => {
        return {
            start: seeker.ts2frame(interval?.start ?? 0),
            end: seeker.ts2frame(interval?.end ?? 0),
        };
    }, [interval?.end, interval?.start, seeker]);

    const videoInterval = useMemo(() => {
        return {
            start: (interval?.start ?? video_start) - video_start,
            end: (interval?.end ?? video_start) - video_start,
        };
    }, [interval?.end, interval?.start, video_start]);

    return {
        selected: interval !== null,
        start: interval?.start ?? 0,
        end: interval?.end ?? 0,
        setInterval,
        frameInterval,
        videoInterval,
        smartSetInterval,
    };
}

export default function TimelineBrush(props) {
    const { brush, layout, height, demiSpace, ...rest } = props;

    const { width, padding } = layout;

    const { top: pt, right: pr, bottom: pb, left: pl } = padding;

    const [mouseDown, setMouseDown] = useState(false);
    const [brushSelection, setBrushSelection] = useState([0, 0]);

    const time = useProcTime();
    const timespan = time.end - time.start;

    const handleBrush = (selection) => {
        const [x1, x2] = selection;
        const start = time.start + (x1 / width) * timespan;
        const end = time.start + (x2 / width) * timespan;
        brush.setInterval({ start, end });
    };

    const drawBrush = (chart, interval) => {
        const brushView = chart
            .attr('viewBox', [0, 0, width + pl + pr, height + pt + pb])
            .attr('pointer-events', 'none');
        // add a brushX to the chart (blue)
        const brushX = d3
            .brushX()
            .extent([
                [0, 0],
                [width, height],
            ])
            .on('brush', (e) => {
                const selection = e.selection;
                if (selection) handleBrush(selection);
            })
            .on('start', () => setMouseDown(true))
            .on('end', (e) => {
                const selection = e.selection;
                if (selection) handleBrush(selection);
                setMouseDown(false);
            });
        brushView
            .append('g')
            .attr('class', 'brush')
            .attr('transform', `translate(${pl}, ${pt - demiSpace})`)
            .call(brushX)
            .call(brushX.move, interval)
            .selectAll('.selection')
            .attr('fill', 'blue')
            .attr('stroke', 'blue');
    };

    useEffect(() => {
        if (mouseDown || (brush.start === 0 && brush.end === 0)) return;
        const start = brush.start;
        const end = brush.end;
        const x1 = ((start - time.start) / timespan) * width;
        const x2 = ((end - time.start) / timespan) * width;
        setBrushSelection([x1, x2]);
    }, [brush.end, brush.start, mouseDown, time.start, timespan, width]);

    const ref = useD3(
        (chart) => {
            drawBrush(chart, brushSelection);
            return () => chart.selectAll('*').remove();
        },
        [brushSelection],
    );

    return <svg ref={ref} {...rest} />;
}
