import { Box, LinearProgress } from '@mui/material';
import FlexBox from '../report/FlexBox';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

export default function FakeProgressBar(props) {
    const [value, setValue] = useState(0);

    const { step = 0.1, add = 0 } = props;

    useEffect(() => {
        let progress = 0;

        const timer = setInterval(() => {
            progress += step;
            setValue(Math.min((Math.atan(progress) / (Math.PI / 2)) * 100 + add, 100));
        }, 100);
        return () => {
            clearInterval(timer);
        };
    }, [add, step]);

    return (
        <FlexBox alignItems='center'>
            <Box width='100%' mr={1}>
                <LinearProgress variant='determinate' value={value} />
            </Box>
            <Box minWidth={56}>
                <Typography variant='body2'>{`${value.toFixed(2)}%`}</Typography>
            </Box>
        </FlexBox>
    );
}
