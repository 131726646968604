import { useAceApp } from '../../../Menu/ReportAppSelector';
import { DISCORDANCES, forAllPolyps, MetricsGrid, TRUSTS } from './OpticalDiagnosisUtils';
import { useGgExploreList } from '../../../../aceapi/aceComponents';

const DISCORDANCE_COLS = [
    { field: 'Name', headerMetric: 'Metric', flex: 0.5 },
    { field: 'Neoplastics', headerName: 'Neoplastics', flex: 0.5 },
    { field: 'Non Neoplastics', headerName: 'Non Neoplastics', flex: 0.5 },
    { field: 'All', headerName: 'All', flex: 0.5 },
].map((col) => ({
    ...col,
    valueFormatter: (params) => {
        const value = params.value;
        if (typeof value === 'number' && value % 1 !== 0) return (Math.round(value * 100) / 100).toFixed(2);
        return value;
    },
}));

/** Same as in OpticalDiagnosisPerPolyp.js
 */
export function OpticalDiagnosisPerPolypTable() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });
    const discordanceRows = TRUSTS.reduce((acc, trust, id) => {
        acc[id] = { id, Name: 'Human ' + trust.toLowerCase() };
        return acc;
    }, []);
    const counts = discordanceRows.reduce((acc, row) => {
        acc[row.Name] = { true: 0, false: 0 };
        return acc;
    }, {});
    forAllPolyps(explore.all, (polyp, isNeo) => {
        DISCORDANCES.forEach(({ trust, condition }) => {
            if (condition(polyp)) {
                counts['Human ' + trust.toLowerCase()][isNeo] += 1;
            }
        });
    });
    Object.entries(counts).forEach(([trust, value], id) => {
        discordanceRows[id] = {
            id,
            Name: trust,
            All: value[true] + value[false],
            Neoplastics: value[true],
            'Non Neoplastics': value[false],
        };
    });
    return <MetricsGrid name='Discordance' rows={discordanceRows} columns={DISCORDANCE_COLS} />;
}
