import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Alert, Box, LinearProgress, Snackbar, Stack, Typography } from '@mui/material';
import FlexBox from '../report/FlexBox';
import { useAceApp } from '../Menu/ReportAppSelector';

export function useTrackedTask({ start, useProgress, taskDescription, progressAsPercent = false }) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const [completed, setCompleted] = useState(false);
    const [job, setJob] = useState(null);

    // const polling = running && job && (state === "STARTED" || state === "PENDING" || total > 0);
    const { data: track } = useProgress(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app, job },
        },
        {
            enabled: !!job,
            refetchInterval: 2000,
        },
    );

    const { state, stage, current, total } = track || {};

    useEffect(() => {
        if (job && state === 'SUCCESS') {
            setCompleted(true);
            setJob(null);
        }
    }, [job, state]);

    function TaskStatus() {
        if (!job) {
            return (
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={completed}
                    onClose={() => setCompleted(false)}
                    autoHideDuration={2000}
                >
                    <Alert severity='success'>{taskDescription} completed successfully!</Alert>
                </Snackbar>
            );
        }

        if (total > 0)
            return (
                <Stack spacing={2}>
                    <Typography variant='h6'>
                        {taskDescription}
                        {stage && `: ${stage}`}
                    </Typography>
                    <FlexBox alignItems='center'>
                        <Box width='100%' mr={1}>
                            <LinearProgress color='success' variant='determinate' value={(current * 100) / total} />
                        </Box>
                        <Box minWidth={120}>
                            <Typography variant='body2'>
                                {progressAsPercent ? `${Math.round((current * 100) / total)}%` : `${current}/${total}`}
                            </Typography>
                        </Box>
                    </FlexBox>
                </Stack>
            );

        if (state === 'PENDING')
            return (
                <Stack spacing={2}>
                    <Typography variant='h6'>Task pending...</Typography>
                    <LinearProgress color='warning' />
                </Stack>
            );

        if (state === 'SUCCESS') {
            return <Typography>Task completed!</Typography>;
        }

        return (
            <Typography color='error'>
                {taskDescription} failed! Reason: {state}
            </Typography>
        );
    }

    function render() {
        return <TaskStatus />;
    }

    async function run() {
        const res = await start({ pathParams: { procedureId: uuid }, queryParams: { app } });
        const job = res?.job;
        if (job) {
            setJob(job);
        }
    }

    return {
        render,
        run,
        running: !!job,
        completed,
    };
}

export function useSequentialTasks(tasks) {
    const [current, setCurrent] = useState(null);
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        if (current !== null && current < tasks.length) {
            const task = tasks[current];
            // Ensure the task is not already running or completed.
            if (!task.running && !task.completed) {
                console.log(`Running task ${current + 1} of ${tasks.length}`);
                task.run();
            }
        }
    }, [current, tasks]);

    useEffect(() => {
        // Wait for the current task to complete before advancing.
        if (current !== null) {
            const task = tasks[current];
            if (task && task.completed) {
                setCurrent((prevState) => prevState + 1);
            }
        }
    }, [tasks, current]);

    useEffect(() => {
        if (current >= tasks.length) {
            setCompleted(true);
            setCurrent(null);
        }
    }, [current, tasks.length]);

    function render() {
        if (completed) {
            return (
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={completed}
                    onClose={() => setCompleted(false)}
                    autoHideDuration={7000}
                >
                    <Alert severity='success' onClose={() => setCompleted(false)}>
                        All tasks completed successfully!
                    </Alert>
                </Snackbar>
            );
        }

        if (current === null) return null;

        return (
            <Stack spacing={2}>
                <Typography variant='h6'>
                    Running task {current + 1} of {tasks.length}
                </Typography>
                <FlexBox alignItems='center'>
                    <Box width='100%' mr={1}>
                        <LinearProgress
                            color='info'
                            variant='determinate'
                            value={((current + 1) * 100) / tasks.length}
                        />
                    </Box>
                    <Box minWidth={120}>
                        <Typography variant='body2'>{`${current + 1}/${tasks.length}`}</Typography>
                    </Box>
                </FlexBox>
            </Stack>
        );
    }

    return {
        render,
        run: () => setCurrent(0), // Restart the sequence
        running: current !== null && current < tasks.length,
        completed,
    };
}
