import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CollapsiblePaperCard from '../report/CollapsiblePaperCard';
import { useClinicalreportGgpatientList } from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';
import { AsyncBoundary } from '../../aceapi/utils';
import PaperCard from '../report/PaperCard';
import FakeProgressBar from '../placeholders/FakeProgressBar';
import { useMemo } from 'react';

const daysSinceTimestamp = (unixTimestamp) => {
    const date = new Date(unixTimestamp);
    const today = new Date();
    const diff = today.getTime() - date.getTime();
    const daysSince = Math.floor(diff / 86400000);
    return daysSince;
};

const columns = [
    { field: 'site_name', headerName: 'Site Name', flex: 0.5, minWidth: 150 },
    { field: 'subject_id', headerName: 'Subject ID', flex: 0.7, minWidth: 100 },
    { field: 'age', headerName: 'Age (Days)', flex: 0.5, minWidth: 50, type: 'number' },
];

function AsyncClinicalReportStatusList() {
    const { app } = useAceApp();
    const { data: report } = useClinicalreportGgpatientList({ queryParams: { app } });
    // Filter reports where procedure_id is not null
    const filteredReport = useMemo(() => report.filter((item) => item.procedure_id === null), [report]);

    const rows = useMemo(
        () =>
            filteredReport.map((item) => ({
                ...item,
                age: daysSinceTimestamp(item.created_at),
            })),
        [filteredReport],
    );

    return (
        <CollapsiblePaperCard title='Unassigned Clinical Reports' xs={12}>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid rows={rows} columns={columns} pageSize={10} />
            </Box>
        </CollapsiblePaperCard>
    );
}

export default function ClinicalReportStatusList() {
    return (
        <AsyncBoundary
            fallback={
                <PaperCard xs={12} title='Clinical cases'>
                    <FakeProgressBar />
                </PaperCard>
            }
        >
            <AsyncClinicalReportStatusList />
        </AsyncBoundary>
    );
}
