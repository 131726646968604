import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';

export default function ConfirmButton({
    ButtonType = Button,
    onConfirm = () => {},
    onCancel = () => {},
    action = '',
    ...props
}) {
    const [pressed, setPressed] = useState(false);

    const handleConfirm = () => {
        onConfirm();
        setPressed(false);
    };

    const handleCancel = () => {
        onCancel();
        setPressed(false);
    };

    return (
        <>
            <ButtonType className='mainButton' {...props} onClick={() => setPressed(true)} />
            <Dialog
                className='confirmDialog'
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                open={pressed}
            >
                <DialogTitle>Confirm action</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to {action ? action : 'do that'}?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='cancelButton' autoFocus onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className='confirmButton' onClick={handleConfirm}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
