class Answer {
    constructor(answer) {
        this.answer = answer;
    }

    asBool() {
        if (this.answer?.questionType !== 'YES_NO') {
            throw new Error(`Answer is not a YES_NO type: ${this.answer}`);
        }
        return this.answer.possibilities?.[0]?.toLowerCase() === 'yes';
    }

    yes() {
        return this.asBool();
    }

    no() {
        return !this.asBool();
    }

    getText() {
        if (this.answer?.questionType === 'YES_NO') {
            throw new Error(`Answer is a YES_NO type: ${this.answer}`);
        }
        return this.answer.answerStrings?.[0] || '';
    }

    getChoices() {
        return this.answer?.possibilities?.map((possibility) => possibility.toLowerCase()) || [];
    }

    choicesInclude(choice) {
        return this.getChoices().includes(choice.toLowerCase());
    }

    get questionType() {
        return this.answer?.questionType;
    }
}

class Answers {
    constructor(answers) {
        this.answers = answers;
    }

    question(questionText) {
        const answer = this.answers.find((answer) => answer.questionText.toLowerCase() === questionText.toLowerCase());
        return answer ? new Answer(answer) : null;
    }

    questionRegex(regex) {
        const answer = this.answers.find((answer) => regex.test(answer.questionText));
        return answer ? new Answer(answer) : null;
    }

    all() {
        return this.answers.map((answer) => new Answer(answer));
    }

    questionFilter(filter) {
        const newAnswers = this.answers.filter((answer) => {
            for (const [key, value] of Object.entries(filter)) {
                if (answer[key] !== value) return false;
            }
            return true;
        });
        return new Answers(newAnswers);
    }

    count() {
        return this.answers.length;
    }

    some(predicate) {
        return this.all().some(predicate);
    }

    every(predicate) {
        return this.all().every(predicate);
    }
}

export class FormsHelper {
    constructor(forms) {
        this.forms = forms;
    }

    form(formTitle) {
        const form = this.forms.find((form) => form.formTitle.toLowerCase() === formTitle.toLowerCase());
        return form ? new Answers(form.answers) : null;
    }

    filter(formTitle) {
        return this.forms
            .filter((form) => form.formTitle.toLowerCase() === formTitle.toLowerCase())
            .map((form) => new Answers(form.answers));
    }

    filterRegex(regex) {
        return this.forms.filter((form) => regex.test(form.formTitle)).map((form) => new Answers(form.answers));
    }

    getTitlesMatching(regex) {
        return this.forms.filter((form) => regex.test(form.formTitle)).map((form) => form.formTitle);
    }
}
