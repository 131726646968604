import { useMemo } from 'react';
import ReportLineChart from '../../../Charts/stats/ReportLineChart';
import { timedelta } from '../../../summary/utils';
import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgSummaryList } from '../../../../aceapi/aceComponents';

export default function GGPatientTrends() {
    const { app } = useAceApp();
    const { data: summary } = useGgSummaryList({ queryParams: { app } });

    const plotCfg = useMemo(
        () => [
            {
                id: 1,
                name: 'Patients Recruited',
                data: summary.form_metrics.recruitment_weekly_trend,
            },
            {
                id: 2,
                name: 'Completed Colonoscopy',
                data: summary.form_metrics.colonoscopy_weekly_trend,
            },
        ],
        [summary.form_metrics],
    );

    const chartData = useMemo(
        () =>
            plotCfg
                .map((cfg) =>
                    cfg.data.map((e) => ({
                        time: Date.parse(e.date),
                        [cfg.name]: e.count,
                    })),
                )
                .reduce((acc, val) => {
                    val.forEach((e) => {
                        const existing = acc.find((x) => x.time === e.time);
                        if (existing) {
                            Object.assign(existing, e);
                        } else {
                            acc.push(e);
                        }
                    });
                    return acc;
                }, []),
        [plotCfg],
    );

    return (
        <ReportLineChart
            formatted={chartData}
            groups={plotCfg}
            period={timedelta.WEEK}
            showStartOnly
            lineType='linear'
        />
    );
}
