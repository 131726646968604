import { Stack, Switch, Tooltip, Typography } from '@mui/material';
import useStickyState from '../../report/useStickyState';

export default function useMiscParams(params) {
    const [miscParams, setMiscParams] = useStickyState(
        'dashboardMiscOptions',
        Object.fromEntries(params.filter((x) => x.enabled).map((x) => [x.key, x.defaultValue])),
    );

    const toggle = (key) => {
        setMiscParams((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const set = (key, value) => {
        setMiscParams((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const render = () => {
        return (
            <Stack direction='column' spacing={1} sx={{ overflowY: 'auto', maxHeight: '12rem' }}>
                <Typography>Misc Parameters</Typography>
                {params
                    .filter((x) => x.enabled)
                    .map((x) => (
                        <Stack key={x.key} direction='row' alignItems='center'>
                            <Tooltip title={x.tooltip}>
                                <Switch checked={miscParams[x.key]} onChange={(e) => set(x.key, e.target.checked)} />
                            </Tooltip>
                            <Typography>{x.name}</Typography>
                        </Stack>
                    ))}
            </Stack>
        );
    };

    return {
        state: miscParams,
        toggle,
        render,
    };
}
