import { Alert, CircularProgress, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import PaperFloatingCard from './PaperFloatingCard';

export default function ProcedureLoading({ update }) {
    const [loadingTimedOut, setLoadingTimedOut] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingTimedOut(true);
        }, 15000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <PaperFloatingCard left='5rem' bottom='1rem'>
            <CircularProgress />
            <Typography align='center'>
                <small>Loading Procedure</small>
            </Typography>
            <Snackbar open={loadingTimedOut && update} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert severity='warning'>
                    It is taking a bit too long... You may need to refresh the page a few times. If you are still unable
                    to load this procedure, please file a report.
                </Alert>
            </Snackbar>
        </PaperFloatingCard>
    );
}
