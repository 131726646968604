import useStickyState from '../../components/report/useStickyState';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { clearSiteCache } from '../../components/report/utils';

const apiUrl = `${process.env.REACT_APP_REST_API_ADDRESS}/api`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const refresh = async (refreshToken) => {
    const response = await fetch(`${apiUrl}/token/refresh/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh: refreshToken }),
    });
    const { access } = await response.json();
    return access;
};

export const secureReload = (minDelay = 1500, maxConsecutiveReloads = 3) => {
    // This uses localStorage to store the current timestamp and make sure the page is reloaded without being
    // in a reload loop.
    const now = Date.now();
    const lastReload = localStorage.getItem('lastReload');
    if (lastReload && now - parseInt(lastReload) < minDelay) {
        const consecutiveReloads = localStorage.getItem('consecutiveReloads');
        if (consecutiveReloads && parseInt(consecutiveReloads) >= maxConsecutiveReloads) {
            console.warn('Too many reloads in a row, not reloading.');
            return;
        }
        const newConsecutiveReloads = consecutiveReloads ? parseInt(consecutiveReloads) + 1 : 1;
        localStorage.setItem('consecutiveReloads', newConsecutiveReloads.toString());
        console.warn('Now at ' + newConsecutiveReloads + ' consecutive reloads.');
    } else {
        localStorage.removeItem('consecutiveReloads');
    }
    localStorage.setItem('lastReload', now.toString());
    window.location.reload();
};

export default function useToken() {
    const [accessToken, setAccessToken] = useStickyState('accessToken', '');
    const [refreshToken, setRefreshToken] = useStickyState('refreshToken', '');
    const [needsRefresh, setNeedsRefresh] = useState(false);

    // This callback is used to access data about the user, including his rights.
    // It is not costly to call this function multiple times since the results are memoized
    // once the access token is set.
    const parseToken = useCallback(() => {
        try {
            return JSON.parse(atob(accessToken.split('.')[1]));
        } catch (e) {
            return { user: { is_active: false } };
        }
    }, [accessToken]);

    const expired = useCallback(() => {
        const token = parseToken();
        return token.exp ? Date.now() >= token.exp * 1000 : true;
    }, [parseToken]);

    const isValid = useCallback(() => {
        if (!accessToken) return false;
        return !expired();
    }, [accessToken, expired]);

    const login = useCallback(
        async (username, password) => {
            const response = await fetch(`${apiUrl}/token/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });
            const { access, refresh, detail } = await response.json();
            if (access) {
                setAccessToken(access);
                setRefreshToken(refresh);
            } else {
                throw new Error('Login failed: ' + detail);
            }
        },
        [setAccessToken, setRefreshToken],
    );

    const logout = useCallback(
        (clearCache = true) => {
            setAccessToken('');
            setRefreshToken('');
            if (clearCache) clearSiteCache();
        },
        [setAccessToken, setRefreshToken],
    );

    useEffect(() => {
        if (needsRefresh) {
            refresh(refreshToken).then((access) => {
                if (access) {
                    console.log('Token refreshed.');
                    setAccessToken(access);
                    setNeedsRefresh(false);
                    secureReload();
                } else {
                    console.log('Refresh token expired.');
                    logout(false);
                }
            });
        }
    }, [logout, needsRefresh, refreshToken, setAccessToken]);

    const refreshNow = useCallback(() => {
        setNeedsRefresh(true);
    }, [setNeedsRefresh]);

    useEffect(() => {
        if (accessToken && expired()) {
            refreshNow();
        }
    }, [accessToken, expired, refreshNow]);

    return useMemo(
        () => ({
            login,
            logout,
            access: {
                state: accessToken,
                setState: setAccessToken,
                expired,
                isValid,
            },
            refresh: {
                state: refreshToken,
                setState: setRefreshToken,
                now: refreshNow,
            },
            parse: parseToken,
        }),
        [
            accessToken,
            expired,
            isValid,
            login,
            logout,
            parseToken,
            refreshNow,
            refreshToken,
            setAccessToken,
            setRefreshToken,
        ],
    );
}
