import { createContext, SetStateAction } from 'react';
import { Dispatch } from 'd3';

interface Token {
    login: Function;
    logout: Function;
    access: {
        state: string;
        setState: Function;
        expired: Function;
        isValid: Function;
    };
    refresh: {
        state: string;
        setState: Function;
        now: Function;
    };
    parse: Function;
}

export const TokenContext = createContext<Token | null>(null);
export const TokenProvider = TokenContext.Provider;

export const appContext = createContext<string | null>(null);
export const AppProvider = appContext.Provider;

interface StreamInfo {
    app: string;
    uuid: string;
}

// value is a useState of StreamInfo
export const ExpandedStreamContext = createContext<[StreamInfo, Dispatch<SetStateAction<StreamInfo>>] | null>(null);
export const ExpandedStreamProvider = ExpandedStreamContext.Provider;

interface ComputeAnnotationContextType {
    isComputingAnnotations: boolean;
    setIsComputingAnnotations: () => void;
}

export const ComputeAnnotationContext = createContext<ComputeAnnotationContextType>({} as ComputeAnnotationContextType);
export const ComputeAnnotationProvider = ComputeAnnotationContext.Provider;
