import { AsyncBoundary } from '../../../../aceapi/utils';
import PaperCard from '../../../report/PaperCard';
import FakeProgressBar from '../../../placeholders/FakeProgressBar';
import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgOldestCachedDataList, useGgSummaryList } from '../../../../aceapi/aceComponents';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { Stack, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import GGPatientTrends from './GGPatientTrends';
import GGPolypLocation from './GGPolypLocation';
import GGRectosigmoidBreakdown from './GGRectosigmoidBreakdown';
import GGEndoscopistTrends from './GGEndoscopistTrends';
import { ErrorBoundary } from 'react-error-boundary';
import GGSummaryForceRefresh from './GGSummaryForceRefresh';

const METRICS_MAP = {
    patients_recruited: { name: 'Patients Recruited', metric_type: 'status' },
    patients_with_colonoscopy: { name: 'Patients with Colonoscopy', metric_type: 'status' },
    number_of_endoscopists: { name: 'Number of Endoscopists', metric_type: 'status' },
    number_of_polyps_resected: { name: 'Number of Polyps Resected', metric_type: 'extended' },
    number_of_polyps_with_histology: { name: 'Number of Polyps with Histology', metric_type: 'extended' },
    number_of_polyps_awaiting_histology: { name: 'Number of Polyps Awaiting Histology', metric_type: 'extended' },
    drsp_resected: { name: 'Number of DRSP Resected', metric_type: 'extended' },
    drsp_waiting_for_histology: { name: 'Number of DRSP Awaiting Histology', metric_type: 'extended' },
    drsp_with_histology: { name: 'Number of DRSP with Histology', metric_type: 'extended' },
    estimate_ppc_drsp: { name: 'Estimated DRSP Per Colonoscopy (PPC)', metric_type: 'extended' },
    actual_ppc_drsp: { name: 'Actual DRSP Per Colonoscopy (PPC)', metric_type: 'extended' },
    estimate_apc_drsp: { name: 'Estimated DRSP Adenoma Per Colonoscopy (APC)', metric_type: 'extended' },
    actual_apc_drsp: { name: 'Actual DRSP Adenoma Per Colonoscopy (APC)', metric_type: 'extended' },
    average_ndpc: { name: 'Average Non-diagnostic polyps Per Colonoscopy (NDPC)', metric_type: 'extended' },
};

const METRICS_COLS = [
    { field: 'name', headerName: 'Metric', flex: 0.7 },
    { field: 'Total', headerName: 'Total', flex: 0.5 },
    { field: 'AI Arm', headerName: 'AI Arm', flex: 0.5 },
    { field: 'Non-AI Arm', headerName: 'Non-AI Arm', flex: 0.5 },
].map((col) => ({
    ...col,
    valueFormatter: (params) => {
        const value = params.value;
        if (typeof value === 'number' && value % 1 !== 0) return (Math.round(value * 100) / 100).toFixed(2);
        return value;
    },
}));

function MetricsGrid({ name, rows }) {
    return (
        <DataGrid
            columns={METRICS_COLS}
            rows={rows}
            autoHeight
            getRowClassName={(params) => `gg-summary-data-from-${params.row.source.toLowerCase()}`}
            components={{
                Toolbar: () => (
                    <GridToolbarContainer>
                        <GridToolbarExport
                            csvOptions={{
                                fileName: `GG_${name}_Summary_${new Date().toISOString().split('T')[0]}`,
                            }}
                        />
                    </GridToolbarContainer>
                ),
            }}
            sx={{
                '& .gg-summary-data-from-ace': {
                    backgroundColor: '#ccccff',
                    '&:hover': { backgroundColor: '#bbbbff' },
                    '&.Mui-selected': {
                        backgroundColor: '#aaaaff',
                        '&:hover': { backgroundColor: '#bbbbff' },
                    },
                },
            }}
        />
    );
}

function AsyncGGTableSummary() {
    const { app } = useAceApp();

    const { data: oldestData } = useGgOldestCachedDataList({});
    const { data: summary } = useGgSummaryList({ queryParams: { app } });

    const [toggleAceMetrics, setToggleAceMetrics] = useState(false);

    const allRows = useMemo(
        () =>
            Object.entries(summary.form_metrics)
                .filter(([key]) => key in METRICS_MAP)
                .map(([key, value]) => {
                    const fromACE = toggleAceMetrics && key in summary.ace_metrics;
                    const output = fromACE ? summary.ace_metrics[key] : value;
                    return {
                        id: key,
                        ...METRICS_MAP[key],
                        source: fromACE ? 'ace' : 'gg',
                        ...(typeof output === 'object' ? output : { Total: output }),
                    };
                }),
        [summary.ace_metrics, summary.form_metrics, toggleAceMetrics],
    );

    const statusRows = allRows.filter((row) => row.metric_type === 'status');
    const extendedRows = allRows.filter((row) => row.metric_type === 'extended');

    return (
        <PaperCard xs={12}>
            <Stack spacing={2}>
                <Typography variant='h6'>
                    Oldest Cached Data:{' '}
                    {oldestData ? new Date(oldestData + 'Z').toLocaleString('en-GB') : 'No data found'}
                </Typography>
                <Typography variant='h4'>Status</Typography>
                <Stack spacing={2} direction='row' alignItems='center' justifyContent='space-between'>
                    <Stack spacing={2} direction='row' alignItems='center'>
                        <Switch checked={toggleAceMetrics} onChange={(e) => setToggleAceMetrics(e.target.checked)} />
                        <Typography variant='body2'>
                            Show estimates using ACE data and annotations (shows in blue)
                        </Typography>
                    </Stack>
                    <GGSummaryForceRefresh />
                </Stack>
                <MetricsGrid name='Status' rows={statusRows} />
                <GGPatientTrends />
                <Typography variant='h4'>Extended Status</Typography>
                <Stack spacing={2} direction='row' alignItems='center'>
                    <Switch checked={toggleAceMetrics} onChange={(e) => setToggleAceMetrics(e.target.checked)} />
                    <Typography variant='body2'>
                        Show estimates using ACE data and annotations (shows in blue)
                    </Typography>
                </Stack>
                <MetricsGrid name='ExtendedStatus' rows={extendedRows} />
                <GGEndoscopistTrends />
                <GGPolypLocation />
                <GGRectosigmoidBreakdown />
            </Stack>
        </PaperCard>
    );
}

export default function GGTableSummary() {
    return (
        <AsyncBoundary
            fallback={
                <PaperCard xs={12} title='Clinical Summary'>
                    <FakeProgressBar />
                </PaperCard>
            }
        >
            <ErrorBoundary
                fallback={
                    <PaperCard xs={12} title='Error Loading Summary'>
                        <GGSummaryForceRefresh />
                    </PaperCard>
                }
            >
                <AsyncGGTableSummary />
            </ErrorBoundary>
        </AsyncBoundary>
    );
}
