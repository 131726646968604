import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgSummaryList } from '../../../../aceapi/aceComponents';
import { useMemo, useState } from 'react';
import { Stack, Switch, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { chartColor } from '../../../Charts/ChartConfig';
import { CustomTooltip } from './GGPolypLocation';

function getSizeCategory(size) {
    if (size <= 5) return '0 - ≤5mm';
    if (size <= 10) return '5 - ≤10mm';
    return '10mm+';
}

const SIZE_ORDER = ['0 - ≤5mm', '5 - ≤10mm', '10mm+'];

export default function GGRectosigmoidBreakdown() {
    const { app } = useAceApp();
    const { data: summary } = useGgSummaryList({ queryParams: { app } });

    const [arm, setArm] = useState();
    const [showEstimate, setShowEstimate] = useState(false);
    const [showTotal, setShowTotal] = useState(false);

    const all_polyps = useMemo(
        () =>
            (showEstimate
                ? summary.form_metrics.rectosigmoid_diagnosis
                : summary.form_metrics.rectosigmoid_histology
            ).map((p) => ({ ...p, size: getSizeCategory(p.size) })),
        [showEstimate, summary.form_metrics.rectosigmoid_diagnosis, summary.form_metrics.rectosigmoid_histology],
    );

    const polyps = useMemo(() => all_polyps.filter((p) => !arm || p.arm === arm), [all_polyps, arm]);

    const all_arms = useMemo(() => [...new Set(all_polyps.map((p) => p.arm))], [all_polyps]);
    const all_diagnoses = useMemo(() => [...new Set(all_polyps.map((p) => p.diagnosis))], [all_polyps]);
    const all_sizes = useMemo(
        () => [...new Set(all_polyps.map((p) => p.size))].sort((a, b) => SIZE_ORDER.indexOf(a) - SIZE_ORDER.indexOf(b)),
        [all_polyps],
    );

    // count polyps per diagnosis
    const data = useMemo(() => {
        const data = [];
        for (const diagnosis of all_diagnoses) {
            const row = { diagnosis };
            for (const size of all_sizes) {
                row[size] = polyps.filter((p) => p.diagnosis === diagnosis && p.size === size).length;
            }
            data.push(row);
        }
        if (showTotal) {
            const row = { diagnosis: 'Total' };
            for (const size of all_sizes) {
                row[size] = polyps.filter((p) => p.size === size).length;
            }
            data.push(row);
        }
        return data;
    }, [all_diagnoses, polyps, all_sizes, showTotal]);

    return (
        <Stack spacing={2}>
            <Typography variant='h5'>Rectosigmoid Polyp Breakdown</Typography>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Typography>Arm: </Typography>
                <TextField value={arm} onChange={(e) => setArm(e.target.value)} select SelectProps={{ native: true }}>
                    <option value=''>All</option>
                    {all_arms.map((a) => (
                        <option key={a} value={a}>
                            {a}
                        </option>
                    ))}
                </TextField>
                <Typography>Show estimates: </Typography>
                <Switch checked={showEstimate} onChange={(e) => setShowEstimate(e.target.checked)} />
                <Typography>Show total: </Typography>
                <Switch checked={showTotal} onChange={(e) => setShowTotal(e.target.checked)} />
            </Stack>
            <ResponsiveContainer width='100%' height={800}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='diagnosis' />
                    <YAxis type='number' />
                    <Tooltip content={<CustomTooltip dataKey='diagnosis' arms={all_arms} polyps={polyps} />} />
                    <Legend />
                    {all_sizes.map((size, i) => (
                        <Bar key={size} dataKey={size} stackId='a' fill={chartColor(i)} />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </Stack>
    );
}
