import {
    Box,
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Snackbar,
    Stack,
    Typography,
} from '@mui/material';
import PaperCard from '../components/report/PaperCard';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { AsyncBoundary } from '../aceapi/utils';
import { useForm } from 'react-hook-form';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useContext, useState } from 'react';
import { Alert } from '@mui/lab';
import WarningIcon from '@mui/icons-material/Warning';
import { TokenContext } from '../config/contexts';
import { useUserSettingsRead, useUserSettingsUpdate } from '../aceapi/aceComponents';

function AsyncAccountSettings() {
    const { data: account } = useUserSettingsRead({});
    const { mutateAsync: updateSettings } = useUserSettingsUpdate();

    const [updated, setUpdated] = useState(false);

    const form_fields = {
        first_name: account.first_name,
        last_name: account.last_name,
        email: account.email,
    };

    const formContext = useForm({ defaultValues: form_fields, mode: 'onChange' });
    const {
        formState: { isDirty, isValid },
        reset,
    } = formContext;
    const disabled = !isDirty || !isValid;

    const handleSubmit = (data) => {
        updateSettings({ body: data })
            .then(() => {
                reset(data);
                setUpdated(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <FormContainer formContext={formContext} onSuccess={handleSubmit}>
            <Stack spacing={3}>
                <TextFieldElement name='first_name' label='First Name' required />
                <TextFieldElement name='last_name' label='Last Name' required />
                <TextFieldElement name='email' label='Email' required />
                <Button type='submit' variant='contained' disabled={disabled}>
                    Save
                </Button>
                {disabled && (
                    <Typography variant='caption' color='error'>
                        You need to make a valid change to update your account settings.
                    </Typography>
                )}
                <Snackbar open={updated} autoHideDuration={10000} onClose={() => setUpdated(false)}>
                    <Alert severity='success' onClose={() => setUpdated(false)} variant='filled'>
                        Account settings updated successfully!
                    </Alert>
                </Snackbar>
            </Stack>
        </FormContainer>
    );
}

export default function AccountSettings() {
    const token = useContext(TokenContext);
    const user = token.parse().user;

    return (
        <Grid container spacing={3} justifyContent='center'>
            <PaperCard xs={8} title='Account Settings' variant='h3' align='center'>
                <Divider />
                <Stack m={2} spacing={3}>
                    <Typography variant='h5'>Read-only Info</Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary='Username' secondary={user.username} />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary='Last Login'
                                secondary={new Date(user.last_login).toLocaleString('en-GB')}
                            />
                        </ListItem>
                    </List>
                    {user.is_staff && (
                        <>
                            <Box>
                                <Typography variant='h5'>Admin Settings</Typography>
                                <Stack direction='row' alignItems='center' spacing={1}>
                                    <WarningIcon color='secondary' fontSize='small' />
                                    <Typography variant='subtitle2' color='secondary'>
                                        2FA is required to access the admin page
                                    </Typography>
                                </Stack>
                            </Box>
                            <Box>
                                <Button variant='contained' href='/admin' target='_blank' endIcon={<OpenInNewIcon />}>
                                    Open the django admin page
                                </Button>
                            </Box>
                        </>
                    )}
                    <Typography variant='h5'>Authentication Settings</Typography>
                    <Stack direction='row' spacing={2}>
                        <Button
                            variant='contained'
                            href='account/password_change'
                            target='_blank'
                            endIcon={<OpenInNewIcon />}
                        >
                            Change Password
                        </Button>
                        <Button
                            variant='contained'
                            href='account/two_factor'
                            target='_blank'
                            endIcon={<OpenInNewIcon />}
                        >
                            Manage 2FA
                        </Button>
                    </Stack>
                    <Typography variant='h5'>User Settings</Typography>
                    <AsyncBoundary
                        fallback={
                            <Stack spacing={1}>
                                {Array(3)
                                    .fill()
                                    .map((_, i) => (
                                        <Skeleton key={i} variant='rounded' animation='wave' height={72} />
                                    ))}
                            </Stack>
                        }
                    >
                        <AsyncAccountSettings />
                    </AsyncBoundary>
                </Stack>
            </PaperCard>
        </Grid>
    );
}
