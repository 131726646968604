import { useCallback } from 'react';
import { useHospitalsList } from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

export default function useHospitalNames() {
    const { app } = useAceApp();
    const { data: hospitals } = useHospitalsList({ queryParams: { app } });

    const getHospitalAcronym = useCallback((username) => {
        const match = username.match(/^\w+-(\w+)-\d+$/);
        return match?.[1] ?? null;
    }, []);

    const getHospitalName = useCallback(
        (username) => {
            const acronym = getHospitalAcronym(username);
            if (acronym) {
                const hospital = hospitals.find((x) => x.acronym === acronym);
                if (hospital) {
                    return hospital.name;
                }
            }

            return null;
        },
        [getHospitalAcronym, hospitals],
    );

    return {
        hospitals,
        getHospitalAcronym,
        getHospitalName,
    };
}
