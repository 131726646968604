import { Paper, Typography, useTheme } from '@mui/material';

export default function ReportPaper({ title = null, p = 2, children, paperProps = {}, paperStyle = {}, ...props }) {
    const theme = useTheme();

    return (
        <Paper
            elevation={theme.custom.elevation}
            sx={{
                p,
                margin: 'auto',
                flexGrow: 1,
                height: '100%',
                ...paperStyle,
            }}
            {...paperProps}
        >
            {title && (
                <Typography variant='h5' align='left' {...props}>
                    {title}
                </Typography>
            )}
            {children}
        </Paper>
    );
}
