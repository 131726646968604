import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import useCommentHelper from '../../report/useCommentHelper';
import { debounce } from 'lodash';
import YAML from 'yaml';

function LiveAnswer({ actions, answer, onAnswerChange, isSaving }) {
    const [selectedActionKey, setSelectedActionKey] = useState(answer?.key || '');
    const [comment, setComment] = useState(answer?.comment || '');

    useEffect(() => {
        setSelectedActionKey(answer?.key || '');
        setComment(answer?.comment || '');
    }, [answer?.key, answer?.comment]);

    const selectedAction = actions.find((action) => action.key === selectedActionKey);
    const selectedSubActions = selectedAction?.children;

    const handleSelection = (action) => {
        setSelectedActionKey(action.key);
        setComment('');
        const newAnswer = {
            key: action.key,
            text: action.display_text,
            // Initialize nested answer if sub-actions exist
            ...(Array.isArray(action.children) && action.children.length > 0 ? { answer: {} } : {}),
        };
        onAnswerChange(newAnswer);
    };

    const handleCommentChange = (event) => {
        const newComment = event.target.value;
        setComment(newComment);
        // Do not trigger onAnswerChange here to prevent saving on every keystroke
    };

    const handleCommentBlur = () => {
        // Trigger save when the comment field loses focus
        onAnswerChange({
            ...answer,
            comment: comment,
        });
    };

    const handleCommentKeyDown = (event) => {
        if (event.key === 'Enter') {
            // Prevent default behavior (e.g., adding a newline)
            event.preventDefault();
            // Trigger save when Enter key is pressed
            onAnswerChange({
                ...answer,
                comment: comment,
            });
            // Optionally remove focus from the comment field
            event.target.blur();
        }
    };

    const handleSubAnswerChange = (subAnswer) => {
        onAnswerChange({
            ...answer,
            answer: subAnswer,
        });
    };

    return (
        <>
            <Grid item xs={12} container spacing={2}>
                {actions.map((action) => (
                    <Grid item key={action.key} xs={3}>
                        <Button
                            variant='contained'
                            color={selectedActionKey === action.key ? 'primary' : 'secondary'}
                            fullWidth
                            sx={{ minHeight: '4rem' }}
                            onClick={() => handleSelection(action)}
                            // Remove disabled={isSaving} to keep the form interactive
                        >
                            {action.display_text}
                        </Button>
                    </Grid>
                ))}
            </Grid>

            {/* Display comment field if allow_comment is true */}
            {selectedAction?.allow_comment && (
                <Grid item xs={12}>
                    <TextField
                        label='Comment'
                        value={comment}
                        onChange={handleCommentChange}
                        onBlur={handleCommentBlur}
                        onKeyDown={handleCommentKeyDown}
                        fullWidth
                    />
                </Grid>
            )}

            {Array.isArray(selectedSubActions) && selectedActionKey && (
                <>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <LiveAnswer
                        actions={selectedSubActions}
                        answer={answer?.answer || {}}
                        onAnswerChange={handleSubAnswerChange}
                        isSaving={isSaving}
                    />
                </>
            )}
        </>
    );
}

export default function LiveForm({ form, selectedComment }) {
    const { editComment } = useCommentHelper();

    const [answer, setAnswer] = useState(selectedComment?.answer || {});
    const [isSaving, setIsSaving] = useState(false);

    const saveCountRef = useRef(0);
    const isAnswerDirty = useRef(false);

    useEffect(() => {
        setAnswer(selectedComment?.answer || {});
        isAnswerDirty.current = false;
    }, [selectedComment]);

    const debouncedSave = useMemo(
        () =>
            debounce(async (answerToSave, saveCount) => {
                if (selectedComment?.id && answerToSave) {
                    try {
                        await editComment(
                            selectedComment.id,
                            YAML.stringify({
                                form: form.name,
                                answer: answerToSave,
                            }),
                        );
                    } finally {
                        if (saveCount === saveCountRef.current) {
                            setIsSaving(false);
                            isAnswerDirty.current = false;
                        }
                    }
                }
            }, 250),
        [selectedComment.id, editComment, form.name],
    );

    useEffect(() => {
        if (isAnswerDirty.current) {
            setIsSaving(true);
            saveCountRef.current += 1;
            const currentSaveCount = saveCountRef.current;
            debouncedSave(answer, currentSaveCount);
        }

        return () => {
            debouncedSave.cancel();
        };
    }, [answer, debouncedSave]);

    const handleAnswerChange = (newAnswer) => {
        isAnswerDirty.current = true;
        setAnswer(newAnswer);
    };

    return (
        <Grid item xs={12} container spacing={4}>
            <LiveAnswer
                actions={form.actions}
                answer={answer}
                onAnswerChange={handleAnswerChange}
                isSaving={isSaving}
            />
            <Grid item xs={12}>
                {isSaving ? (
                    <Typography variant='body2'>Saving...</Typography>
                ) : (
                    <Typography variant='body2'>All changes saved.</Typography>
                )}
            </Grid>
        </Grid>
    );
}
