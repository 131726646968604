import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import ConfirmButton from '../dialogs/ConfirmButton';
import {
    useExtraList,
    useSummaryProceduresMaxDuration,
    useSummaryProceduresWithAdenoma,
} from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

export default function SummaryDownload() {
    const { app } = useAceApp();

    const { refetch: fetchProcs } = useSummaryProceduresMaxDuration({ queryParams: { app } }, { enabled: false });
    const { refetch: fetchExtra } = useExtraList({ queryParams: { app } }, { enabled: false });
    // const {refetch: fetchDet} = useSummaryProceduresWithDetection(
    //     {queryParams: {app}},
    //     {enabled: false}
    // );
    const { refetch: fetchAdenoma } = useSummaryProceduresWithAdenoma({ queryParams: { app } }, { enabled: false });

    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
        setLoading(true);
        // ]);
        const { data: procs } = await fetchProcs();
        const { data: extra } = await fetchExtra();
        // const det = await fetchDet();
        const { data: adenoma } = await fetchAdenoma();

        // const det_id = det.map(x => x.procedure_id);
        const adenoma_id = adenoma.map((x) => x.procedure_id);

        const data = procs
            // .map(p => ({...p, detection: det_id.includes(p.procedure_id)}))
            .map((p) => ({ ...p, adenoma: adenoma_id.includes(p.procedure_id) }))
            .map((p) => ({
                ...p,
                ...Object.fromEntries(
                    extra.filter((x) => x.procedure_id === p.procedure_id).map((x) => [x.annotation, x.value]),
                ),
            }));

        const fields = Object.keys(data[0]);
        const replacer = (key, value) => (value === null ? '' : value);
        let csv = data.map((row) => fields.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(fields.join(','));
        csv = csv.join('\r\n');
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${app}_summary.csv`;
        setLoading(false);
        link.click();
    };

    return (
        <ConfirmButton
            ButtonType={LoadingButton}
            action='download all procedure data as csv (it might take a while, beware: the web ui filters WILL NOT be applied)'
            variant='contained'
            onConfirm={handleDownload}
            loading={loading}
            startIcon={<DownloadIcon />}
            loadingPosition='start'
        >
            Download as CSV
        </ConfirmButton>
    );
}
