import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgSummaryList } from '../../../../aceapi/aceComponents';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useMemo, useState } from 'react';
import { Stack, Switch, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { chartColor } from '../../../Charts/ChartConfig';
import Box from '@mui/material/Box';

export const CustomTooltip = ({ active, payload, label, dataKey, polyps, arms }) => {
    if (active && payload && payload.length) {
        const totalPerArm = arms.reduce((acc, arm) => {
            acc[arm] = polyps.filter((p) => {
                if ([null, undefined, '_invalid'].includes(p.id)) return false;
                if (p.arm !== arm) return false;
                if (label === 'Total') return true;
                if (dataKey === 'site' && ['Total RS', 'Total Non-RS'].includes(label)) {
                    return isRectosigmoid(p.site) === (label === 'Total RS');
                }
                return p[dataKey] === label;
            }).length;
            return acc;
        }, {});

        return (
            <Box sx={{ backgroundColor: 'background.paper', padding: 1, borderRadius: 1, boxShadow: 1 }}>
                <Typography variant='h6'>{`Site: ${label}`}</Typography>
                {payload.map((entry, index) => (
                    <Typography key={`item-${index}`} style={{ color: entry.color }}>
                        {`${entry.name}: ${entry.value}`}
                    </Typography>
                ))}
                {Object.entries(totalPerArm).map(([arm, count], index) => (
                    <Typography key={`total-${index}`}>{`Total in ${arm}: ${count}`}</Typography>
                ))}
                <Typography>{`Total: ${payload.reduce((acc, p) => acc + p.value, 0)}`}</Typography>
            </Box>
        );
    }

    return null;
};

const isRectosigmoid = (site) => ['rectum', 'sigmoid', 'rectosigmoid'].includes(site?.toLowerCase());
const isDiminutive = (size) => size > 0 && size <= 5;

export default function GGPolypLocation() {
    const { app } = useAceApp();
    const { data: summary } = useGgSummaryList({ queryParams: { app } });

    const [arm, setArm] = useState();
    const [showRsTotals, setShowRsTotals] = useState(false);
    const [endoscopist, setEndoscopist] = useState();
    const [showTotal, setShowTotal] = useState(false);
    const [sizeGroup, setSizeGroup] = useState();

    const all_polyps = useMemo(
        () => summary.form_metrics.types_of_polyp_per_location,
        [summary.form_metrics.types_of_polyp_per_location],
    );

    const all_arms = useMemo(() => [...new Set(all_polyps.map((p) => p.arm))], [all_polyps]);
    const all_endoscopists = useMemo(() => [...new Set(all_polyps.map((p) => p.endoscopist))], [all_polyps]);
    const all_histologies = useMemo(() => [...new Set(all_polyps.map((p) => p.histology))], [all_polyps]);
    const all_sites = useMemo(
        () =>
            [...new Set(all_polyps.map((p) => p.site))].sort((a, b) =>
                a.localeCompare(b, 'en', { sensitivity: 'base' }),
            ),
        [all_polyps],
    );

    const polyps = useMemo(
        () =>
            all_polyps.filter(
                (p) =>
                    (!arm || p.arm === arm) &&
                    (!endoscopist || p.endoscopist === endoscopist) &&
                    (!sizeGroup || isDiminutive(p.size) === (sizeGroup === 'Diminutive')),
            ),
        [all_polyps, arm, endoscopist, sizeGroup],
    );

    // count polyps per histology
    const data = useMemo(() => {
        const data = [];
        if (!showRsTotals) {
            for (const site of all_sites) {
                const row = { site };
                for (const histology of all_histologies) {
                    row[histology] = polyps.filter((p) => p.site === site && p.histology === histology).length;
                }
                data.push(row);
            }
        } else {
            data.push({
                site: 'Total RS',
                ...all_histologies.reduce((acc, histology) => {
                    acc[histology] = polyps.filter((p) => isRectosigmoid(p.site) && p.histology === histology).length;
                    return acc;
                }, {}),
            });
            data.push({
                site: 'Total Non-RS',
                ...all_histologies.reduce((acc, histology) => {
                    acc[histology] = polyps.filter((p) => !isRectosigmoid(p.site) && p.histology === histology).length;
                    return acc;
                }, {}),
            });
        }
        if (showTotal)
            data.push({
                site: 'Total',
                ...all_histologies.reduce((acc, histology) => {
                    acc[histology] = polyps.filter((p) => p.histology === histology).length;
                    return acc;
                }, {}),
            });
        return data;
    }, [all_histologies, all_sites, polyps, showRsTotals, showTotal]);

    return (
        <Stack spacing={2}>
            <Typography variant='h5'>Polyp Location</Typography>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Typography>Arm: </Typography>
                <TextField value={arm} onChange={(e) => setArm(e.target.value)} select SelectProps={{ native: true }}>
                    <option value=''>All</option>
                    {all_arms.map((a) => (
                        <option key={a} value={a}>
                            {a}
                        </option>
                    ))}
                </TextField>
                <Typography>Endoscopist: </Typography>
                <TextField
                    value={endoscopist}
                    onChange={(e) => setEndoscopist(e.target.value)}
                    select
                    SelectProps={{ native: true }}
                >
                    <option value=''>All</option>
                    {all_endoscopists.map((a) => (
                        <option key={a} value={a}>
                            {a}
                        </option>
                    ))}
                </TextField>
                <Typography>Size: </Typography>
                <TextField
                    value={sizeGroup}
                    onChange={(e) => setSizeGroup(e.target.value)}
                    select
                    SelectProps={{ native: true }}
                >
                    <option value=''>All</option>
                    {['Diminutive', 'Non-Diminutive'].map((a) => (
                        <option key={a} value={a}>
                            {a}
                        </option>
                    ))}
                </TextField>
                <Typography>Show RS/Non-RS totals</Typography>
                <Switch checked={showRsTotals} onChange={(e) => setShowRsTotals(e.target.checked)} />
                <Typography>Show total: </Typography>
                <Switch checked={showTotal} onChange={(e) => setShowTotal(e.target.checked)} />
            </Stack>
            <ResponsiveContainer width='100%' height={800}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='site' />
                    <YAxis type='number' />
                    <Tooltip content={<CustomTooltip dataKey='site' arms={all_arms} polyps={polyps} />} />
                    <Legend />
                    {all_histologies.map((histology, i) => (
                        <Bar key={histology} dataKey={histology} stackId='a' fill={chartColor(i)} />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </Stack>
    );
}
