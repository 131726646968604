import Grid from '@mui/material/Grid';
import { Button, Collapse, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

export default function CollapsiblePaperCard({ xs = 12, title = null, children, expand = true }) {
    const theme = useTheme();
    const [expanded, setExpanded] = useState(expand);
    return (
        <Grid item xs={xs}>
            <Paper
                elevation={theme.custom.elevation}
                sx={{
                    p: 2,
                    margin: 'auto',
                    flexGrow: 1,
                    height: '100%',
                }}
            >
                <Stack direction='row' spacing={2} justifyContent='space-between' alignItems='flex-start' sx={{ p: 2 }}>
                    <Typography variant='h5' align='left' sx={{ p: 2 }}>
                        {title}
                    </Typography>
                    <Button
                        variant='outlined'
                        size='small'
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >
                        Show/Hide
                    </Button>
                </Stack>
                <Collapse in={expanded} timeout='auto' unmountOnExit>
                    {children}
                </Collapse>
            </Paper>
        </Grid>
    );
}
