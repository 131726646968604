import { AsyncBoundary } from '../../aceapi/utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAceApp } from './ReportAppSelector';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import HubIcon from '@mui/icons-material/Hub';
import { useState } from 'react';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InsightsIcon from '@mui/icons-material/Insights';
import BarChartIcon from '@mui/icons-material/BarChart';
import SpeedIcon from '@mui/icons-material/Speed';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import GamepadIcon from '@mui/icons-material/Gamepad';
import TheatersIcon from '@mui/icons-material/Theaters';

const actions = [
    {
        name: 'Detailed View',
        icon: AssignmentIcon,
        path: '/procedures',
    },
    {
        name: 'Compact View',
        icon: InsightsIcon,
        path: '/p',
    },
    {
        name: 'CAQ view',
        icon: BarChartIcon,
        path: '/caq',
    },
    {
        name: 'Performance Annotation',
        icon: SpeedIcon,
        path: '/perfedit',
    },
    {
        name: 'Greenlight Guru Patient Report',
        icon: MedicalInformationIcon,
        path: '/patient',
    },
    {
        name: 'Clinical Review',
        icon: ScatterPlotIcon,
        path: '/review',
    },
    {
        name: 'Live Report',
        icon: GamepadIcon,
        path: '/live',
    },
    {
        name: 'Edit Timeline',
        icon: TheatersIcon,
        path: '/edit',
    },
];

function AsyncProcedureSpeedDial() {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        app &&
        uuid && (
            <SpeedDial
                ariaLabel='Procedure Speed Dial'
                direction='up'
                sx={{ position: 'absolute', bottom: 16, right: 32 }}
                icon={<SpeedDialIcon icon={<HubIcon />} />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={
                            <action.icon color={pathname.startsWith(`${action.path}/${uuid}`) ? 'info' : 'inherit'} />
                        }
                        tooltipTitle={action.name}
                        onClick={() => navigate(`${action.path}/${uuid}`)}
                    />
                ))}
            </SpeedDial>
        )
    );
}

export default function ProcedureSpeedDial(props) {
    return (
        <AsyncBoundary fallback={null}>
            <AsyncProcedureSpeedDial {...props} />
        </AsyncBoundary>
    );
}
