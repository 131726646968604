import { Skeleton, Stack } from '@mui/material';

export function RoutePlaceholder() {
    return (
        <Stack width='100%' spacing={2}>
            <Skeleton variant='rounded' height={80} />
            <Skeleton variant='rounded' height={360} />
            <Stack direction='row' spacing={2}>
                <Skeleton width='25%' variant='rounded' height={360} />
                <Skeleton width='75%' variant='rounded' height={360} />
            </Stack>
            <Stack direction='row' spacing={2}>
                <Skeleton width='33.33%' variant='rounded' height={360} />
                <Skeleton width='33.33%' variant='rounded' height={360} />
                <Skeleton width='33.33%' variant='rounded' height={360} />
            </Stack>
        </Stack>
    );
}

export function ChangelogPlaceholder() {
    return (
        <Stack width='100%' spacing={2}>
            <Skeleton variant='rounded' height={50} width='80%' />
            <Skeleton variant='rounded' height={40} width='40%' />
            {Array(4)
                .fill()
                .map((_, i) => (
                    <Skeleton key={i} variant='rounded' height={20} width='100%' />
                ))}
        </Stack>
    );
}

export function TimelinePlaceholder() {
    return (
        <Stack width='100%' spacing={1}>
            <Skeleton variant='rounded' height={128} width='100%' />
            {Array(3)
                .fill()
                .map((_, i) => (
                    <Skeleton key={i} variant='rounded' height={40} width='100%' />
                ))}
        </Stack>
    );
}
