import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Menu from './components/Menu/MenuContent';
import { useRoutes } from 'react-router-dom';
import routeConfig from './config/routeConfig';
import { themeOptions } from './config/themes/default';
import { AsyncBoundary, CustomErrorBoundary } from './aceapi/utils';
import packageJson from '../package.json';
import { clearSiteCache } from './components/report/utils';
import AuthBarrier from './components/Login/AuthBarrier';
import usePinnedProcedures from './components/dashboard/hooks/usePinnedProcedures';
import { RoutePlaceholder } from './components/placeholders/placeholders';
import ChangelogPopup from './components/dialogs/ChangelogPopup';
import {
    AppProvider,
    ExpandedStreamProvider,
    TokenContext,
    TokenProvider,
    ComputeAnnotationProvider,
} from './config/contexts';
import useStickyState from './components/report/useStickyState';
import ExpandedStreamModal from './components/stream/ExpandedStreamModal';
import { useCheckRights } from './aceapi/hooks/useCheckRights';
import ProcedureSpeedDial from './components/Menu/ProcedureSpeedDial';

function Copyright(props) {
    return (
        <Typography variant='body2' color='text.secondary' align='center' {...props}>
            {'Copyright © '}
            <Link color='inherit' href='https://odin-vision.com/'>
                Odin Vision (Odin Medical Ltd.)
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const mdTheme = createTheme(themeOptions);

function AppContent() {
    const token = useContext(TokenContext);
    const aceApp = useStickyState('aceApp', 'caddie');
    const expandedStream = useState(null);
    const [isComputingAnnotations, setIsComputingAnnotations] = useState(false);
    useCheckRights({ token });

    useEffect(() => {
        const version = localStorage.getItem('version');
        if (version !== packageJson.version) {
            console.log('Version mismatch, browser is', version, 'but website is', packageJson, ', clearing cache...');
            clearSiteCache();
            localStorage.clear();
            localStorage.setItem('version', packageJson.version);
        } else console.log('Browser cache is up-to-date with remote site version.');
    }, []);

    const pin = usePinnedProcedures();

    const routes = useRoutes(
        routeConfig.map((route) => ({
            path: route.path,
            element: (
                <AuthBarrier token={token} isProtected={route.protected} redirectTo='/login'>
                    <TokenProvider value={token}>
                        <route.component pin={pin} />
                        <ProcedureSpeedDial />
                    </TokenProvider>
                </AuthBarrier>
            ),
        })),
    );

    return (
        <ThemeProvider theme={mdTheme}>
            <AppProvider value={aceApp}>
                <ExpandedStreamProvider value={expandedStream}>
                    <ComputeAnnotationProvider value={{ isComputingAnnotations, setIsComputingAnnotations }}>
                        <Box sx={{ display: 'flex' }}>
                            <CssBaseline />
                            <Menu token={token} />
                            <Box
                                component='main'
                                sx={{
                                    backgroundColor: 'background.default',
                                    flexGrow: 1,
                                    height: '100vh',
                                    overflow: 'auto',
                                }}
                            >
                                <Toolbar />
                                <Container maxWidth='xl' sx={{ mt: 4, mb: 4, bgcolor: 'inherit', overflow: 'hidden' }}>
                                    <CustomErrorBoundary>
                                        <AsyncBoundary fallback={<RoutePlaceholder />}>{token && routes}</AsyncBoundary>
                                    </CustomErrorBoundary>
                                    {token.parse().user.is_active && <ChangelogPopup />}
                                    <Copyright sx={{ pt: 4 }} />
                                </Container>
                            </Box>
                        </Box>
                    </ComputeAnnotationProvider>
                    <ExpandedStreamModal />
                </ExpandedStreamProvider>
            </AppProvider>
        </ThemeProvider>
    );
}

export default function Main() {
    return <AppContent />;
}
