import * as d3 from 'd3';
import useD3 from '../d3/useD3';
import { useCallback } from 'react';
import useProcTime from '../../../aceapi/hooks/useProcTime';
import { useTheme } from '@mui/material';
import { renderToString } from 'react-dom/server';
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';

export default function TimelineFrames(props) {
    const { size = 96, labelHeight = 16, layout, height, frames, hoveredFrame, demiSpace, ...rest } = props;

    const { width, padding } = layout;

    const { top: pt, right: pr, bottom: pb, left: pl } = padding;

    const theme = useTheme();
    const time = useProcTime();
    const timespan = time.end - time.start;

    // display selected frames at their position in the timeline (using the timestamp)
    // show the diagnosis as a label on top of the frame
    // show the frame as a rectangle on the right time position or left if the frame is too close to the right border
    const drawFrames = useCallback(
        (chart) => {
            function drawFrame(frame) {
                const frameView = d3.select(this);
                let polypID = frame?.full_frame?.value ?? 'ERR';
                const mouseFrame = polypID.length !== 3;
                if (mouseFrame) polypID = 'N/A';
                frameView
                    .append('rect')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', size)
                    .attr('height', labelHeight)
                    .attr('fill', mouseFrame ? theme.palette.primary.main : theme.palette.secondary.dark)
                    .attr('stroke', mouseFrame ? theme.palette.primary.main : theme.palette.secondary.dark)
                    .attr('stroke-width', 1);
                frameView
                    .append('text')
                    .attr('x', size / 2)
                    .attr('y', labelHeight / 2)
                    .attr('text-anchor', 'middle')
                    .attr('dominant-baseline', 'middle')
                    .attr('font-size', '0.7em')
                    .attr('fill', 'white')
                    .text(frame.diagnosis);
                frameView
                    .append('rect')
                    .attr('x', 0)
                    .attr('y', labelHeight)
                    .attr('width', size)
                    .attr('height', size)
                    .attr('fill', 'black')
                    .attr('stroke', (frame) => (frame.selected ? 'blue' : 'black'))
                    .attr('stroke-width', 1);
                // add a vertical line connecting the frame to the row
                frameView
                    .append('line')
                    .attr('x1', (frame) => (frame.x > width - size ? size : 0))
                    .attr('y1', labelHeight + size)
                    .attr('x2', (frame) => (frame.x > width - size ? size : 0))
                    .attr('y2', pt - demiSpace)
                    .attr('stroke', 'black')
                    .attr('stroke-width', 1);
                const show_box = frame.full_frame && frame.detection;
                const show_polygon =
                    Array.isArray(frame.detection_polygon?.coordinates) &&
                    frame.detection_polygon.coordinates.length > 0;
                const frame_url = show_polygon ? frame.url : frame.full_frame?.url;
                if (frame_url && (show_box || show_polygon)) {
                    const shape = show_polygon ? frame.detection_polygon : frame.detection;
                    frameView
                        .append('image')
                        .attr('xlink:href', frame_url)
                        .attr('x', 0)
                        .attr('y', labelHeight)
                        .attr('width', size)
                        .attr('height', size);
                    // download the image to get its size and draw the shapes to the correct coordinates
                    const img = new Image();
                    img.onload = () => {
                        const ar = img.width / img.height;
                        let dw = 0;
                        let dh = 0;
                        let imw = size;
                        let imh = size;
                        if (ar > 1) {
                            imh = size / ar;
                            dh = (size - imh) / 2;
                        } else {
                            imw = size * ar;
                            dw = (size - imw) / 2;
                        }
                        const get_x = (x) => Math.round(dw + (x * imw) / 512);
                        const get_y = (y) => Math.round(dh + (y * imh) / 512);
                        const coords = show_polygon
                            ? shape.coordinates.map((c) => [get_x(c[0]), get_y(c[1])])
                            : [
                                  [get_x(shape.x1), get_y(shape.y1)],
                                  [get_x(shape.x2), get_y(shape.y1)],
                                  [get_x(shape.x2), get_y(shape.y2)],
                                  [get_x(shape.x1), get_y(shape.y2)],
                              ];
                        frameView
                            .append('polygon')
                            .attr('transform', `translate(0, ${labelHeight})`)
                            .attr('points', coords.map((c) => c.join(',')).join(' '))
                            .attr('fill', 'none')
                            .attr('stroke', '#0f0')
                            .attr('stroke-width', 1);
                    };
                    img.src = frame_url;
                } else {
                    // show a placeholder icon
                    const iconString = renderToString(
                        <ImageNotSupportedOutlinedIcon
                            color='info'
                            style={{
                                width: size,
                                height: size,
                                fill: theme.palette.info.main,
                            }}
                        />,
                    );
                    // if we remove the color attribute, the icon is not sized correctly and the color is not applied
                    // so whatever dark magic is going on here, we need to keep the color attribute
                    frameView
                        .append('foreignObject')
                        .attr('x', 0)
                        .attr('y', labelHeight)
                        .attr('width', size)
                        .attr('height', size)
                        .attr('fill', theme.palette.info.main)
                        .html(iconString);
                }
                // show frame.polypID as a label on the bottom right of the image
                frameView
                    .append('text')
                    .attr('x', size - 4)
                    .attr('y', labelHeight + size - 4)
                    .attr('text-anchor', 'end')
                    .attr('dominant-baseline', 'end')
                    .attr('font-size', '0.7em')
                    .attr('fill', 'white')
                    .text(polypID)
                    .style('text-shadow', '1px 1px 1px black');
            }

            const frameView = chart
                .attr('viewBox', [0, 0, width + pl + pr, height + pt + pb])
                .attr('pointer-events', 'none')
                .append('g')
                .attr('transform', `translate(${pl}, ${0})`);
            let displayFrames = frames.filter((f) => f.selected);
            if (hoveredFrame.state?.timestamp && !hoveredFrame.state?.selected)
                displayFrames = [...displayFrames, hoveredFrame.state];
            displayFrames = displayFrames.map((f) => ({ ...f, x: ((f.timestamp - time.start) * width) / timespan }));
            frameView
                .selectAll('g')
                .data(displayFrames)
                .join('g')
                .attr('transform', (frame) => `translate(${frame.x}, 0)`)
                .attr('transform', (frame) => `translate(${frame.x > width - size ? frame.x - size : frame.x}, 0)`)
                .each(drawFrame);
        },
        [
            demiSpace,
            frames,
            height,
            hoveredFrame.state,
            labelHeight,
            pb,
            pl,
            pr,
            pt,
            size,
            theme.palette,
            time.start,
            timespan,
            width,
        ],
    );

    const ref = useD3(
        (chart) => {
            if (Array.isArray(frames)) drawFrames(chart);
            return () => chart.selectAll('*').remove();
        },
        [drawFrames],
    );

    return <svg ref={ref} {...rest} />;
}
