import {
    useClinicalreportGgpatientFromProcedureId,
    useClinicalreportGgpatientPolypDiagnosis,
} from '../../../aceapi/aceComponents';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

function toSnakeCase(str) {
    return str.replace(/ /g, '_').toLowerCase();
}

export default function useGGPolypData() {
    const { uuid } = useParams();
    const { data: report } = useClinicalreportGgpatientFromProcedureId(
        { queryParams: { procedure_id: uuid } },
        { retry: false, useErrorBoundary: false },
    );
    const { data: table } = useClinicalreportGgpatientPolypDiagnosis(
        { pathParams: { id: report?.id } },
        { enabled: !!report },
    );

    const { cols, rows } = useMemo(() => table ?? { cols: {}, rows: [] }, [table]);

    const polypIdKey = useMemo(
        () => Object.keys(cols).find((key) => cols[key].toLowerCase().includes('polyp id')),
        [cols],
    );

    const polypIdChoices = useMemo(() => rows.map((row) => row[polypIdKey]), [rows, polypIdKey]);

    // Make an object with the polyp IDs as keys and the values is a dictionary of the row data with decoded column names
    const polypData = useMemo(() => {
        return Object.fromEntries(
            rows.map((row) => {
                const polypId = row[polypIdKey];
                const rowData = Object.fromEntries(
                    Object.entries(row).map(([key, value]) => [toSnakeCase(cols[key]), value]),
                );
                return [polypId, rowData];
            }),
        );
    }, [rows, cols, polypIdKey]);

    return {
        ids: polypIdChoices,
        data: polypData,
    };
}
