import React, { useState, useEffect, useMemo } from 'react';
import { useClinicalreportGgpatientList, useClinicalreportGgpatientPartialUpdate } from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';
import { DataGrid } from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Stack, Checkbox } from '@mui/material';
import PaperCard from '../report/PaperCard';
import Box from '@mui/material/Box';
import { debounce } from 'lodash';

export default function Exclusions() {
    const { app } = useAceApp();
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [exclusion_code, setExclusionCode] = useState(1);
    const [exclusion_subtype, setExclusionSubtype] = useState(0);
    const [comment, setComment] = useState('');

    const exclusion_code_options = [1, 2, 3];
    const exclusion_subtype_options = [0, 1, 2, 3];

    const [excludedPatients, setExcludedPatients] = useState([]);

    let { data: patients } = useClinicalreportGgpatientList({ queryParams: { app } });
    patients = patients.filter((patient) => patient.app === app);

    useEffect(() => {
        setExcludedPatients(patients.filter((patient) => patient.exclusion_code !== 0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { mutateAsync: updatePatient } = useClinicalreportGgpatientPartialUpdate();

    const UpdateGgPatient = async (id, exclusion_code, exclusion_subtype, comment) => {
        console.log(id, exclusion_code, exclusion_subtype, comment);
        const data = {
            exclusion_code: exclusion_code,
            exclusion_subtype: exclusion_subtype,
            comment: comment,
        };
        await updatePatient({ pathParams: { id: id }, queryParams: { app }, body: data });
    };

    const debouncedUpdatePatient = useMemo(
        () =>
            debounce((row) => {
                UpdateGgPatient(row.id, row.exclusion_code, row.exclusion_subtype, row.comment);
            }, 500),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const getDataAsCSVString = () => {
        const header = ['Hospital', 'Patient ID', 'Exclusion Code', 'Exclusion Subtype', 'Comment'].join(',');
        const rows = excludedPatients.map((patient) => {
            return [
                patient.site_name,
                patient.subject_id,
                patient.exclusion_code,
                patient.exclusion_subtype,
                patient.comment,
            ].join(',');
        });
        return [header, ...rows].join('\n');
    };

    const columns = [
        { field: 'subject_id', headerName: 'ID', width: 70 },
        { field: 'site_name', headerName: 'Site', width: 70 },
        {
            field: 'exclusion_code',
            headerName: 'Code',
            width: 100,
            renderCell: (params) => {
                if (params.row.editing) {
                    return (
                        <Autocomplete
                            value={params.row.exclusion_code}
                            getOptionLabel={(option) => option.toString()}
                            options={exclusion_code_options}
                            disableClearable={true}
                            onChange={(event, newValue) => {
                                params.row.exclusion_code = newValue;
                                UpdateGgPatient(params.row.id, params.row.exclusion_code, params.row.exclusion_subtype);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    );
                } else {
                    return params.value;
                }
            },
        },
        {
            field: 'exclusion_subtype',
            headerName: 'Subtype',
            width: 100,
            renderCell: (params) => {
                if (params.row.editing) {
                    return (
                        <Autocomplete
                            value={params.row.exclusion_subtype}
                            getOptionLabel={(option) => option.toString()}
                            options={exclusion_subtype_options}
                            disableClearable={true}
                            onChange={(event, newValue) => {
                                params.row.exclusion_subtype = newValue;
                                UpdateGgPatient(params.row.id, params.row.exclusion_code, params.row.exclusion_subtype);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    );
                } else {
                    return params.value;
                }
            },
        },
        {
            field: 'comment',
            headerName: 'Comment',
            width: 200,
            renderCell: (params) => {
                if (params.row.editing) {
                    return (
                        <TextField
                            defaultValue={params.row.comment}
                            onChange={(event) => {
                                params.row.comment = event.target.value;
                                debouncedUpdatePatient(params.row);
                            }}
                            onKeyDown={(event) => {
                                event.stopPropagation();
                            }}
                        />
                    );
                } else {
                    return params.value;
                }
            },
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 50,
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.editing}
                    onChange={(event) => {
                        params.row.editing = event.target.checked;
                    }}
                />
            ),
        },
        {
            field: 'remove',
            headerName: 'Remove',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <Button
                    onClick={() => {
                        console.log(excludedPatients);
                        UpdateGgPatient(params.row.id, 0, 0, '');
                        setExcludedPatients((prevPatients) =>
                            prevPatients.filter((patient) => patient.subject_id !== params.row.subject_id),
                        );
                        console.log(excludedPatients);
                    }}
                >
                    Remove
                </Button>
            ),
        },
    ];
    return (
        <PaperCard xs={12}>
            <Stack direction='column' spacing={2}>
                <Stack direction='row' spacing={2}>
                    <Autocomplete
                        options={patients}
                        getOptionLabel={(option) => `${option.site_name} - ${option.subject_id}`}
                        style={{ width: 200 }}
                        onChange={(event, value) => {
                            setSelectedPatient(value);
                        }}
                        renderInput={(params) => <TextField {...params} label='Select Patient' variant='outlined' />}
                    />
                    <Autocomplete
                        disableClearable={true}
                        options={exclusion_code_options}
                        getOptionLabel={(option) => option.toString()}
                        style={{ width: 150 }}
                        defaultValue={1}
                        onChange={(event, value) => {
                            setExclusionCode(value);
                        }}
                        renderInput={(params) => <TextField {...params} label='Exclusion Code' />}
                    />
                    <Autocomplete
                        disableClearable={true}
                        options={exclusion_subtype_options}
                        getOptionLabel={(option) => option.toString()}
                        style={{ width: 150 }}
                        defaultValue={0}
                        onChange={(event, value) => {
                            setExclusionSubtype(value);
                        }}
                        renderInput={(params) => <TextField {...params} label='Exclusion Subtype' />}
                    />
                    <TextField
                        label='Comment'
                        variant='outlined'
                        onChange={(event) => {
                            setComment(event.target.value);
                        }}
                    />
                    <Button
                        variant='contained'
                        onClick={() => {
                            if (selectedPatient) {
                                UpdateGgPatient(selectedPatient.id, exclusion_code, exclusion_subtype, comment);
                                selectedPatient.exclusion_code = exclusion_code;
                                selectedPatient.exclusion_subtype = exclusion_subtype;
                                selectedPatient.comment = comment;
                                setExcludedPatients((prevPatients) => [...prevPatients, selectedPatient]);
                            }
                        }}
                    >
                        Add Exclusion
                    </Button>
                </Stack>
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'subject_id', sort: 'asc' }],
                            },
                        }}
                        rows={excludedPatients}
                        columns={columns}
                        density='compact'
                    />
                </Box>

                <Button
                    variant='contained'
                    color='primary'
                    href={`data:text;charset=utf-8,${encodeURIComponent(getDataAsCSVString())}`}
                    download='exclusions.csv'
                >
                    Download CSV Data
                </Button>
            </Stack>
        </PaperCard>
    );
}
