import React, { useEffect, useState } from 'react';
import { Box, Skeleton, useTheme } from '@mui/material';
import { Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import format from 'format-duration';
import linspace from 'linspace';
import { AsyncBoundary } from '../../aceapi/utils';
import { useParams } from 'react-router-dom';
import { timedelta } from '../summary/utils';
import useProcTime from '../../aceapi/hooks/useProcTime';
import useProcCaecum from '../../aceapi/hooks/useProcCaecum';
import {
    useDebugGraphRead,
    useGraphRead,
    useProceduresDetections,
    useStreamGraphRead,
} from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

const possibleDeltas = [
    timedelta.SECOND,
    timedelta.SECOND * 5,
    timedelta.SECOND * 10,
    timedelta.SECOND * 20,
    timedelta.SECOND * 30,
    timedelta.MINUTE,
    timedelta.MINUTE * 5,
    timedelta.MINUTE * 10,
    timedelta.MINUTE * 20,
    timedelta.MINUTE * 30,
    timedelta.HOUR,
];

function getSmartTicks(time, targetN) {
    try {
        const start = time.start;
        const end = time.end;
        const span = (end - start) / targetN;
        const delta = possibleDeltas
            .map((x) => ({ delta: x, diff: Math.abs(x - span) }))
            .sort((a, b) => a.diff - b.diff)[0].delta;
        const ticks = [start];
        let next = start + delta;
        while (next < end) {
            if (next - start > delta / 2 && end - next > delta / 2) ticks.push(next);
            next += delta;
        }
        ticks.push(end);
        return ticks;
    } catch (error) {
        console.log('Error while computing graph ticks:', error);
        return linspace(time.start, time.end, 5);
    }
}

function ChartContent({
    showCaecum = false,
    compact = false,
    debug = false,
    dot = false,
    unit = '',
    smartTicks = false,
    showLostBoxes = false,
    stream = false,
    ...props
}) {
    const { name, plot, sampling, aggregate, aspect, setUnavailable, setShow, loaded } = props;

    const { app } = useAceApp();
    const theme = useTheme();
    const { uuid } = useParams();

    const { data: normalData } = useGraphRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: {
                app,
                plot,
                sampling,
                aggregate,
            },
        },
        { enabled: !debug },
    );

    const { data: streamData } = useStreamGraphRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: {
                app,
                plot,
                aggregate,
            },
        },
        { enabled: stream && !debug, refetchInterval: 5000 },
    );

    const { data: debugData } = useDebugGraphRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: {
                app,
                plot,
            },
        },
        { enabled: debug },
    );

    const { data: boxes } = useProceduresDetections(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: showLostBoxes },
    );
    const lostBoxes = boxes?.filter((x) => x.status === 2);

    const data = debug ? debugData : normalData;

    let time = useProcTime();
    if (stream) {
        const diff = time.end - time.start;
        const end = time.start + Math.max(Math.pow(2, Math.ceil(Math.log2(diff * 1.05))), 2 ** 18);
        time = { ...time, end };
    }
    const ticks = smartTicks ? getSmartTicks(time, 5) : linspace(time.start, time.end, 5);

    const caecum = useProcCaecum(showCaecum);

    const [points, setPoints] = useState([]);

    useEffect(() => {
        loaded();
    }, [loaded]);

    useEffect(() => {
        if (!data) return;
        const plot = data?.graph_points;
        if (!plot || (Array.isArray(plot?.list_ts) && plot.list_ts.length === 0)) {
            setUnavailable((prevState) => ({ ...prevState, [`Cannot plot ${name}`]: 'data is missing' }));
            setShow(false);
            return;
        }
        const d = plot ? [plot.list_ts, plot.vals] : null;
        const keys = d[0];
        const values = d[1];
        const results = [...keys.map((k, i) => ({ x: k, y: values[i] }))];
        setPoints(results);
    }, [data, name, setShow, setUnavailable]);

    useEffect(() => {
        if (!stream) return;
        const point = streamData?.point;
        if (!point) return;
        setPoints((prevState) => [...prevState, { x: point.ts, y: point.val }]);
    }, [streamData?.point, stream]);

    return (
        <ResponsiveContainer aspect={aspect} width='100%' height='100%'>
            <LineChart
                margin={{
                    top: compact ? 20 : 30,
                    left: compact ? 20 : 40,
                    right: compact ? 5 : 45,
                    bottom: compact ? 0 : 30,
                }}
                data={points}
            >
                <XAxis
                    name='Time'
                    margin={{ bottom: 10 }}
                    ticks={ticks}
                    dataKey='x'
                    scale='time'
                    domain={['auto', 'auto']}
                    type='number'
                    tickFormatter={(unixTime) => format(unixTime - time.start, { leading: true })}
                />
                <YAxis
                    label={{
                        value: name,
                        angle: compact ? 90 : -90,
                        position: 'insideLeft',
                        dy: compact ? -40 : 60,
                        dx: compact ? 50 : 0,
                    }}
                    tickFormatter={(value) => `${value}${unit}`}
                    orientation={compact ? 'right' : 'left'}
                />
                <Tooltip
                    labelFormatter={(value) => [format(value - time.start, { leading: true })]}
                    formatter={(value, name) => [value.toFixed(2), name]}
                />
                <Line
                    isAnimationActive={!stream}
                    dot={dot}
                    type='monotone'
                    name={name}
                    dataKey='y'
                    stroke='#333333'
                    strokeWidth={debug ? 1 : showLostBoxes ? 2 : 1}
                    fill='#333333'
                />
                {caecum?.timestamp && (
                    <ReferenceLine
                        x={caecum.timestamp}
                        stroke={theme.palette.primary.main}
                        label={{ value: 'Caecum', position: 'top', fill: theme.palette.primary.main }}
                    />
                )}
                {caecum?.timestamp && (
                    <ReferenceLine
                        x={caecum.timestamp}
                        stroke={theme.palette.primary.main}
                        label={{
                            value: format(caecum.timestamp - time.start, { leading: true }),
                            position: 'bottom',
                            fill: theme.palette.primary.main,
                            dy: 30,
                        }}
                    />
                )}
                {showLostBoxes &&
                    Array.isArray(lostBoxes) &&
                    lostBoxes.map((box, i) => (
                        <ReferenceLine key={i} x={box.timestamp} stroke={theme.palette.error.main + '07'} />
                    ))}
            </LineChart>
        </ResponsiveContainer>
    );
}

function Chart({ aspect = 40 / 10, name, ...props }) {
    const [show, setShow] = useState(true);

    return (
        <AsyncBoundary
            fallback={
                <Box position='relative'>
                    <Box position='absolute' width='100%' ml={10} mt={3.5} pr={15}>
                        <Skeleton
                            variant='rectangular'
                            animation='wave'
                            width='100%'
                            height={0}
                            sx={{
                                pb: `${(1 / aspect) * 82.5}%`,
                            }}
                        />
                    </Box>
                    <ResponsiveContainer aspect={aspect} width='100%' height='100%'>
                        <LineChart
                            margin={{ top: 30, left: 20, right: 40, bottom: 30 }}
                            data={[
                                { x: 0, y: 0 },
                                { x: 100, y: 100 },
                            ]}
                        >
                            <XAxis />
                            <YAxis
                                domain={[0, 100]}
                                label={{ value: name, angle: -90, position: 'insideLeft', dy: 60 }}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            }
        >
            {show && <ChartContent setShow={setShow} name={name} aspect={aspect} {...props} />}
        </AsyncBoundary>
    );
}

export default Chart;
