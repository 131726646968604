import { useAceApp } from '../Menu/ReportAppSelector';
import { useEffect, useState } from 'react';
import { AsyncBoundary } from '../../aceapi/utils';
import {
    useProceduresDownloadMergedHistologyDataCreate,
    useProceduresDownloadMergedHistologyDataRead,
} from '../../aceapi/aceComponents';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { LoadingButton } from '@mui/lab';

function AsyncDownloadMergedHistologyData() {
    const { app } = useAceApp();

    const [job, setJob] = useState(null);

    const { mutateAsync: fetchMergeData } = useProceduresDownloadMergedHistologyDataCreate({
        onSuccess: (res) => {
            setJob(res.job);
        },
    });

    const { data: result } = useProceduresDownloadMergedHistologyDataRead(
        {
            queryParams: { app, job: job },
        },
        {
            enabled: !!job,
            refetchInterval: 2000,
        },
    );

    useEffect(() => {
        if (result?.state === 'SUCCESS') {
            setJob(null);
            const csv_str = result?.merged_csv;
            const blob = new Blob([csv_str], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `merged_data.csv`;
            link.click();
        } else if (result?.state === 'ERROR') {
            setJob(null);
            alert('Unexpected error occurred while generating the merged data CSV');
        }
    }, [app, result]);

    return (
        <LoadingButton
            onClick={() => fetchMergeData({ queryParams: { app } })}
            loading={!!job}
            loadingPosition='start'
            endIcon={<FileDownloadIcon />}
            variant='contained'
        >
            Download Merged Histology Data
        </LoadingButton>
    );
}

export default function DownloadMergedHistologyData() {
    return (
        <AsyncBoundary>
            <AsyncDownloadMergedHistologyData />
        </AsyncBoundary>
    );
}
