import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useTheme } from '@mui/material';

export default function ReportBarChart({ data, nameKey, valueKey, itemHeight = 30, minHeight = 80, yFormat }) {
    const formatted = data.map((e) => ({
        name: e[nameKey],
        [valueKey]: e[valueKey],
    }));

    const theme = useTheme();

    let chartHeight = itemHeight * data.length;
    if (chartHeight < minHeight) chartHeight = minHeight;

    return (
        <ResponsiveContainer width='100%' height={chartHeight}>
            <BarChart data={formatted} layout='vertical' margin={{ top: 0, right: 20, bottom: 0, left: 80 }}>
                <CartesianGrid strokeDasharray='3 3' horizontal={false} />
                <XAxis type='number' />
                <YAxis type='category' interval={0} width={100} dataKey={nameKey} tickFormatter={yFormat} />
                <Tooltip />
                <Bar dataKey={valueKey} fill={theme.palette.primary.main} />
            </BarChart>
        </ResponsiveContainer>
    );
}
