import { secureReload } from '../../aceapi/hooks/useToken';

export const csvDataColors = ['#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c'];

export const CADDIE_STATUS = [
    { name: 'Sent', description: 'Waiting for client to upload end-of-procedure annotations.' },
    { name: 'Received', description: 'The client received and displayed the detection box in time.' },
    { name: 'Lost', description: 'The detection result was not displayed because it was lost or arrived too late.' },
    {
        name: 'Recovered',
        description:
            'The detection has been received by the client but was not properly recorded on' + ' the server side.',
    },
];

export function hslGradient(value) {
    const hue = (value * 120).toString(10);
    return ['hsl(', hue, ',100%,50%)'].join('');
}

export function colorIsLight(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
    return brightness > 155;
}

export function clearSiteCache() {
    if ('caches' in window) {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches
                    .delete(name)
                    .then(() => console.log('Cache successfully cleared.'))
                    .catch((err) => console.log(err));
            });
        });
        secureReload();
    }
}

export function polypCountPerDiagnosis(frames) {
    const diagnoses = Array.isArray(frames) ? frames.map((x) => x.diagnosis.toLowerCase()) : [];
    return Array.from(new Set(diagnoses)).map((x) => [x, diagnoses.filter((y) => y === x).length]);
}
