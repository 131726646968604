import PaperCard from './PaperCard';
import { List, ListItem, Skeleton, Tooltip, Typography } from '@mui/material';
import { AsyncBoundary } from '../../aceapi/utils';
import { CADDIE_STATUS } from './utils';
import HelpIcon from '@mui/icons-material/Help';
import { useProceduresDetections } from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

// Display the list with names and counts of detection statuses, with name in bold
function AsyncDetectionStatusStats({ uuid }) {
    const { app } = useAceApp();
    const { data: detections } = useProceduresDetections({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });

    const statuses_counts = CADDIE_STATUS.map((status, idx) => ({
        name: status.name,
        description: status.description,
        count: detections.filter((detection) => detection.status === idx).length,
    }));

    return detections.length > 0 ? (
        <List dense>
            {statuses_counts.map((status) => (
                <ListItem key={status.name}>
                    <Typography variant='body1'>
                        <b>{status.name}</b>
                        <Tooltip title={status.description}>
                            <HelpIcon color='primary' fontSize='small' />
                        </Tooltip>
                        : {status.count} ({Math.round((status.count / detections.length) * 10000) / 100}%)
                    </Typography>
                </ListItem>
            ))}
            <ListItem>
                <Typography variant='body1'>
                    <b>Total</b>: {detections.length}
                </Typography>
            </ListItem>
        </List>
    ) : (
        'No detections'
    );
}

export default function DetectionStatusStats(props) {
    return (
        <PaperCard title='Detection Status Stats'>
            <AsyncBoundary
                fallback={
                    <List dense>
                        {Array(5)
                            .fill()
                            .map((_, i) => (
                                <ListItem key={i}>
                                    <Typography variant='body1' width='100%'>
                                        <Skeleton animation='wave' />
                                    </Typography>
                                </ListItem>
                            ))}
                    </List>
                }
            >
                <AsyncDetectionStatusStats {...props} />
            </AsyncBoundary>
        </PaperCard>
    );
}
