import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Button, Divider, Link, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import ChangelogPopup from '../components/dialogs/ChangelogPopup';

export default function About() {
    const currentVersion = localStorage.getItem('version');
    const [showChangelog, setShowChangelog] = useState(false);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
                <Paper>
                    <Typography variant='h3' align='center'>
                        About
                    </Typography>
                    <Divider />
                    <Box p={2}>
                        <Typography variant='p'>
                            Welcome to CADDIE Report! This project aims to provide comprehensive ways to show the data
                            collected by CADDIE during procedures. It is still in development and new features are added
                            regularly; but here are some of the main features. You can:
                            <ul>
                                <li>See what was seen in the procedure via the video recording (if available)</li>
                                <li>Review all the diagnoses and other procedure events recorded via CADDIE</li>
                                <li>
                                    Navigate all past procedures of your site(s) (using{' '}
                                    <Link href='/dashboard'>quicksearch</Link> or{' '}
                                    <Link href='/search'>normal search</Link>)
                                </li>
                                <li>
                                    See all <Link href='/dashboard'>ongoing and latest</Link> procedures of your site(s)
                                </li>
                            </ul>
                            To accomplish this, this project is using a RESTful API to communicate with our databases,
                            if you wish to use the API directly, you can consult the documentation here:
                            <ul>
                                <li>
                                    <Link href='/docs/swagger'>With swagger</Link>
                                </li>
                                or
                                <li>
                                    <Link href='/docs/redoc'>With redoc</Link>
                                </li>
                            </ul>
                        </Typography>
                    </Box>
                    <Divider />
                    <Stack p={2} direction='row' spacing={2} alignItems='center'>
                        <Typography variant='h5'>
                            Current version: <b>{currentVersion}</b>
                        </Typography>
                        <Button variant='contained' onClick={() => setShowChangelog(true)}>
                            Show Changelog
                        </Button>
                        <ChangelogPopup open={showChangelog} onClose={() => setShowChangelog(false)} />
                    </Stack>
                </Paper>
            </Grid>
        </Grid>
    );
}
