import { AsyncBoundary } from '../../aceapi/utils';
import useExtraData from './useExtraData';
import ExtraDataLegend from './ExtraDataLegend';
import PaperCard from './PaperCard';
import useShow from '../../aceapi/hooks/useShow';
import { Box, Skeleton } from '@mui/material';

function AsyncExtraLegendCard() {
    const show = useShow();
    const { extraModels } = useExtraData({
        show: show.extra_models,
    });

    return <ExtraDataLegend extraModels={extraModels} />;
}

export default function ExtraLegendCard(props) {
    return (
        <PaperCard title='Legend'>
            <AsyncBoundary
                fallback={
                    <Box display='flex' flexWrap='wrap'>
                        {Array(20)
                            .fill(0)
                            .map((_, i) => (
                                <Skeleton
                                    key={i}
                                    variant='rounded'
                                    animation='wave'
                                    width={((i * 1224) % 50) + 50}
                                    height={20}
                                    sx={{ m: '2px' }}
                                />
                            ))}
                    </Box>
                }
            >
                <AsyncExtraLegendCard {...props} />
            </AsyncBoundary>
        </PaperCard>
    );
}
