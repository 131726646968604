import { Alert, Box, Grid, Skeleton, Snackbar, Stack, Tooltip, Typography } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PaperCard from './PaperCard';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AsyncBoundary } from '../../aceapi/utils';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useHospitalsList, useProceduresRead } from '../../aceapi/aceComponents';

function AsyncProcedureTitle({ pin }) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const [updated, setUpdated] = useState(false);
    const { data: procedure } = useProceduresRead({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });
    const { data: hospitals } = useHospitalsList({ queryParams: { app } });

    const getHospitalName = (username) => {
        const match = username.match(/^\w+-(\w+)-\d+$/);
        if (match) {
            const acronym = match[1];
            const hospital = hospitals.find((x) => x.acronym === acronym);
            if (hospital) {
                return hospital.name;
            }
        }

        return null;
    };

    const handlePin = () => {
        pin.addProcedure(procedure);
        setUpdated(true);
    };

    const handleUnpin = () => {
        pin.removeProcedure(uuid);
        setUpdated(true);
    };

    const pinned = pin.procs.find((p) => p.procedure_id === uuid) !== undefined;

    return (
        <>
            <Grid container alignItems='center'>
                <Grid item xs={4} height='8rem'>
                    <img
                        src={
                            procedure.hospital_logo ||
                            'https://odin-vision.com/wp-content/webp-express/webp-images/uploads/2022/03/logo-1-181x115.png.webp'
                        }
                        alt='hospital_logo'
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <Stack direction='row' justifyContent='center' alignItems='center' spacing={2}>
                        <Stack direction='column'>
                            <Typography variant='h3' align='center'>
                                {procedure.hospital_room ?? 'Room unknown'}
                                {procedure.patient_id && ` - ${procedure.patient_id}`}
                            </Typography>
                            <Typography variant='subtitle1'>
                                <i>from:</i> {procedure.username} ({getHospitalName(procedure.username) ?? 'Unknown'})
                            </Typography>
                        </Stack>
                        {pinned ? (
                            <Tooltip title='Unpin from the dashboard'>
                                <PushPinIcon onClick={handleUnpin} />
                            </Tooltip>
                        ) : (
                            <Tooltip title='Pin to the dashboard'>
                                <PushPinOutlinedIcon onClick={handlePin} />
                            </Tooltip>
                        )}
                    </Stack>
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={updated}
                onClose={() => setUpdated(false)}
                autoHideDuration={2000}
            >
                <Alert severity={pinned ? 'success' : 'info'}>
                    Procedure {procedure.patient_id ? procedure.patient_id : uuid} {pinned ? 'pinned' : 'unpinned'}
                </Alert>
            </Snackbar>
        </>
    );
}

export default function ProcedureTitle(props) {
    return (
        <PaperCard xs={12}>
            <AsyncBoundary
                fallback={
                    <Stack direction='row' spacing={2}>
                        <Skeleton variant='rounded' width='35%' height={128} animation='wave' />
                        <Box width='10%' />
                        <Stack width='55%' justifyContent='center'>
                            <Typography variant='h3' align='center'>
                                <Skeleton width='80%' animation='wave' />
                            </Typography>
                            <Typography variant='subtitle1'>
                                <Skeleton width='30%' animation='wave' />
                            </Typography>
                        </Stack>
                    </Stack>
                }
            >
                <AsyncProcedureTitle {...props} />
            </AsyncBoundary>
        </PaperCard>
    );
}
