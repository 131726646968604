import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExploreList } from '../../../../aceapi/aceComponents';
import { Button } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function RawExploreData() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });
    const navigate = useNavigate();
    const columnNames = Object.keys(explore.all[0]);
    const columns = columnNames.reduce((acc, key) => {
        const r = { field: key, headerName: key };
        if (key === 'procedure_id') {
            r.renderCell = (params) => {
                return (
                    <strong>
                        <Button
                            variant='contained'
                            size='small'
                            style={{ marginLeft: 16 }}
                            tabIndex={params.hasFocus ? 0 : -1}
                            onClick={() => navigate(`/procedures/${params.value}`)}
                        >
                            Open video
                        </Button>
                    </strong>
                );
            };
        }
        acc.push(r);
        return acc;
    }, []);
    const rows = useMemo(() => {
        const rows = [];
        explore.all.forEach((polyp, id) => {
            rows[id] = { id };
            columnNames.forEach((name) => {
                rows[id][name] = polyp[name];
            });
        });
        return rows;
    }, [columnNames, explore.all]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector slotProps={{ tooltip: { title: 'Change density' } }} />
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <>
            <Typography variant='h4'>Raw ACE combined data</Typography>
            <DataGrid
                autoHeight
                name='Raw Explore Data'
                rows={rows}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            endo_id: false,
                            mode: false,
                            endo_tool: false,
                            image_quality: false,
                            procedure_id: false,
                        },
                    },
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </>
    );
}
