import useWebSocket from 'react-use-websocket';
import { useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AsyncBoundary } from '../../aceapi/utils';
import { Skeleton, Typography } from '@mui/material';
import { useProceduresStream } from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';
import { ExpandedStreamContext } from '../../config/contexts';

const Canvas = ({ boxes, ...props }) => {
    const canvasRef = useRef(null);

    const draw = useCallback(
        (canvas, ctx) => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const small = Math.min(canvas.width, canvas.height);
            const imw = small;
            const imh = small;
            const dw = (canvas.width - imw) / 2;
            const dh = (canvas.height - imh) / 2;

            const get_x = (x) => (x * imw) / 512 + dw;
            const get_y = (y) => (y * imh) / 512 + dh;

            if (Math.trunc(Date.now() / 1e3) % 2 === 0) {
                ctx.font = '14px Arial';
                ctx.fillStyle = '#a62dfb';
                ctx.fillText('Live', 10, 20);
            }
            ctx.strokeStyle = '#a62dfb';
            ctx.beginPath();
            ctx.rect(get_x(0), get_y(0), get_x(512) - get_x(0), get_y(512) - get_y(0));
            ctx.closePath();
            ctx.stroke();

            for (const bbox of boxes) {
                const box = bbox?.['box'];
                const tl = box?.['tl'];
                const br = box?.['br'];
                const x1 = get_x(tl?.x ?? 10);
                const y1 = get_y(tl?.y ?? 10);
                const x2 = get_x(br?.x ?? 502);
                const y2 = get_y(br?.y ?? 502);
                // Draw the bounding box in red its status is "Lost"
                ctx.strokeStyle = '#00ff00';
                ctx.beginPath();
                ctx.rect(x1, y1, x2 - x1, y2 - y1);
                ctx.closePath();
                ctx.stroke();
            }
        },
        [boxes],
    );

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        draw(canvas, context);
    }, [draw]);

    return <canvas ref={canvasRef} {...props} />;
};

function BlobImage({ message, imgRef, maxHeight = '14rem' }) {
    const [url, setUrl] = useState(null);

    useEffect(() => {
        const obj = URL.createObjectURL(message.data);
        setUrl(obj);
        return () => URL.revokeObjectURL(obj);
    }, [message]);

    return (
        url && (
            <img
                src={url}
                alt='blob'
                ref={imgRef}
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    maxHeight,
                    position: 'absolute',
                    zIndex: 1,
                }}
            />
        )
    );
}

function AsyncCaddieStream({ setStreamUnavailable, app, uuid, maxHeight }) {
    const ace = useAceApp();
    const params = useParams();
    app = app || ace.app;
    uuid = uuid || params.uuid;
    const { data: streamToken } = useProceduresStream(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { refetchInterval: 20000 },
    );

    if (streamToken.error !== 'OK') {
        setStreamUnavailable(true);
        console.log('Could not obtain websocket token:', streamToken.error);
    }

    if (!streamToken.websocket_url) {
        setStreamUnavailable(true);
        console.log('Could not obtain websocket url:', streamToken);
    }

    if (!streamToken.token) {
        setStreamUnavailable(true);
        console.log('Could not obtain websocket token:', streamToken);
    }

    const websocketUrl = `${streamToken.websocket_url}/streaming/${streamToken.token}`;
    const { lastMessage } = useWebSocket(websocketUrl);
    const [message, setMessage] = useState(null);
    const [boxes, setBoxes] = useState([]);

    useEffect(() => {
        if (lastMessage !== null) {
            if (lastMessage.data instanceof Blob && lastMessage.data.size > 0) setMessage(lastMessage);
            else {
                try {
                    const parsed = JSON.parse(lastMessage.data);
                    setBoxes(parsed);
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }, [lastMessage]);

    const imgRef = useRef();

    return (
        message && (
            <div style={{ position: 'relative' }}>
                <BlobImage message={message} style={{ zIndex: 1 }} imgRef={imgRef} maxHeight={maxHeight} />
                <Canvas
                    boxes={boxes}
                    width={imgRef.current?.offsetWidth ?? 512}
                    height={imgRef.current?.offsetHeight ?? 512}
                    style={{
                        zIndex: 100,
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        right: 0,
                        pointerEvents: 'none',
                    }}
                />
            </div>
        )
    );
}

export default function CaddieStream(props) {
    const [streamUnavailable, setStreamUnavailable] = useState(false);
    const expandedStream = useContext(ExpandedStreamContext);
    const expandedStreamSetState = expandedStream?.[1] ?? (() => null);

    const { app, uuid, expandOnClick = false, expanded = false } = props;

    // stream should be disabled if a stream is expanded and this stream is not the expanded one
    const isEnabled = expanded || !expandedStream?.[0];

    return streamUnavailable ? (
        <Typography variant='h6'>Procedure real-time streaming from CADDIE is currently unavailable.</Typography>
    ) : (
        <AsyncBoundary fallback={<Skeleton variant='rectangular' animation='wave' width={224} height={224} />}>
            <div
                onClick={() => {
                    if (expandOnClick) {
                        console.log('Expanding stream');
                        expandedStreamSetState({ app, uuid });
                    }
                }}
            >
                {isEnabled && <AsyncCaddieStream {...props} setStreamUnavailable={setStreamUnavailable} />}
            </div>
        </AsyncBoundary>
    );
}
