import { IconButton, Stack, Tooltip } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

/**
 * This component contains the buttons for navigating the video.
 * The brush is optional, and if it is provided, the buttons for snapping the interval to the current timestamp are
 * shown.
 */
export default function VideoNavButtons({ seeker, brush, ...props }) {
    return (
        <Stack direction='row' spacing={2} mt={2} mb={2} justifyContent='center' {...props}>
            {brush && (
                <Tooltip title='Snap interval start to current timestamp'>
                    <IconButton
                        color='error'
                        variant='contained'
                        onClick={() => brush.smartSetInterval(seeker.timestamp.state, 'start')}
                    >
                        <FirstPageIcon />
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title='-10 frames'>
                <IconButton color='primary' variant='contained' onClick={() => seeker.frameJump(-10)}>
                    <KeyboardDoubleArrowLeftIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title='-1 frames'>
                <IconButton color='primary' variant='contained' onClick={() => seeker.frameJump(-1)}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title='+1 frames'>
                <IconButton color='primary' variant='contained' onClick={() => seeker.frameJump(1)}>
                    <KeyboardArrowRightIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title='+10 frames'>
                <IconButton color='primary' variant='contained' onClick={() => seeker.frameJump(10)}>
                    <KeyboardDoubleArrowRightIcon />
                </IconButton>
            </Tooltip>
            {brush && (
                <Tooltip title='Snap interval end to current timestamp'>
                    <IconButton
                        color='error'
                        variant='contained'
                        onClick={() => brush.smartSetInterval(seeker.timestamp.state, 'end')}
                    >
                        <LastPageIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Stack>
    );
}
