import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExploreList } from '../../../../aceapi/aceComponents';
import { Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MetricsGrid, neoplasticsFilter, nonNeoplasticsFilter } from './OpticalDiagnosisUtils';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import Box from '@mui/material/Box';

const POLYP_FILTERS = [
    { name: 'Patient Id', get: (polyp) => polyp.polyp_id },
    { name: 'Second OD', get: (polyp) => polyp.expert_diagnosis },
    { name: 'CADDIE', get: (polyp) => polyp.diagnosis },
    {
        name: 'Histology',
        get: (polyp) => polyp.diag_from_histology,
    },
    {
        name: 'Video',
        get: (polyp) => polyp.procedure_id,
    },
];

/**
 * Compare Second OD (ie .expert_diagnosis) to CADDIE (ie .diagnosis) to histology
 * Note: Many 'no comment'/'UNKNOWN' in Second OD data
 */
export function AgreementInOpticalDiagnosis() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });
    const navigate = useNavigate();

    const METRICS_COLS = [
        { field: 'Patient Id', headerMetric: 'Patient Id', flex: 0.5 },
        { field: 'Second OD', headerName: 'Second OD', flex: 0.5 },
        { field: 'CADDIE', headerName: 'CADDIE', flex: 0.5 },
        { field: 'Histology', headerName: 'Histology', flex: 0.5 },
        {
            field: 'Video',
            headerName: 'Video',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            variant='contained'
                            size='small'
                            style={{ marginLeft: 16 }}
                            tabIndex={params.hasFocus ? 0 : -1}
                            onClick={() => navigate(`/procedures/${params.value}`)}
                        >
                            Open video
                        </Button>
                    </strong>
                );
            },
        },
    ].map((col) => ({
        ...col,
        valueFormatter: (params) => {
            const value = params.value;
            if (typeof value === 'number' && value % 1 !== 0) return (Math.round(value * 100) / 100).toFixed(2);
            return value;
        },
    }));

    const [rows, disagRows] = useMemo(() => {
        const rows = [];
        const disagRows = [];
        let i = 0,
            j = 0;
        const processPolyps = (filter) => {
            explore.all
                .filter(filter)
                .filter((polyp) => polyp.expert_diagnosis === polyp.diagnosis.toLowerCase())
                .forEach((polyp) => {
                    rows[i] = { id: i };
                    POLYP_FILTERS.forEach(({ name, get }) => {
                        rows[i][name] = get(polyp);
                    });
                    i++;
                });
            explore.all
                .filter(filter)
                .filter((polyp) => polyp.expert_diagnosis !== polyp.diagnosis.toLowerCase())
                .forEach((polyp) => {
                    disagRows[j] = { id: j };
                    POLYP_FILTERS.forEach(({ name, get }) => {
                        disagRows[j][name] = get(polyp);
                    });
                    j++;
                });
        };

        processPolyps(neoplasticsFilter);
        processPolyps(nonNeoplasticsFilter);
        return [rows, disagRows];
    }, [explore.all]);
    return (
        <>
            <Typography variant='h4'>Agreement in Optical Diagnosis</Typography>
            <Stack direction='row' spacing={2} width='100%' height='100%'>
                <Box alignItems='center' width='50%' height='100%'>
                    <Typography variant='h6'>Agreement</Typography>
                    <MetricsGrid name='AgreementInOD' rows={rows} columns={METRICS_COLS} />
                </Box>
                <Box alignItems='center' width='50%' height='100%'>
                    <Typography variant='h6'>Disagreement</Typography>
                    <MetricsGrid name='DisagreementInOD' rows={disagRows} columns={METRICS_COLS} />
                </Box>
            </Stack>
        </>
    );
}
