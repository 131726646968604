import Forbidden from '../components/Login/Forbidden';
import PaperCard from '../components/report/PaperCard';
import { Grid, Stack } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import useAuthorized from '../aceapi/hooks/useAuthorized';
import useShow from '../aceapi/hooks/useShow';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import { useLiveReportFormsList, useProceduresComments } from '../aceapi/aceComponents';
import CommentSection from '../components/comments/CommentSection';
import { TokenContext } from '../config/contexts';
import FlexBox from '../components/report/FlexBox';
import { useParams } from 'react-router-dom';
import YAML from 'yaml';
import LiveForm from '../components/Annotations/live/LiveForm';
import LiveAnnotationList from '../components/Annotations/live/LiveAnnotationList';
import useVideoSeeker from '../components/stream/useVideoSeeker';
import VideoOrStream from '../components/stream/VideoOrStream';
import useProcOngoing from '../aceapi/hooks/useProcOngoing';
import VideoUploadProgressCard from '../components/stream/VideoUploadProgressCard';

export default function ProcedureLiveReport() {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const authorized = useAuthorized();
    const show = useShow();
    const token = useContext(TokenContext);
    const user = token.parse().user;
    const isStaff = user.is_active && user.is_staff;
    const userInfo = { username: user.username, isStaff };

    const seeker = useVideoSeeker();
    const { streaming } = useProcOngoing();

    const { data: forms } = useLiveReportFormsList({});

    const form = useMemo(() => {
        return forms?.find((form) => form.apps.includes(app));
    }, [forms, app]);

    const { data: comments } = useProceduresComments({ pathParams: { procedureId: uuid }, queryParams: { app } });

    const liveComments = useMemo(
        () =>
            comments
                ?.filter((comment) => comment.name === 'Live Reporting')
                .map((comment) => ({ ...comment, ...YAML.parse(comment.body) })),
        [comments],
    );

    const [selectedComment, setSelectedComment] = useState(() => liveComments?.[liveComments.length - 1]);

    const [unavailable, setUnavailable] = useState(false);

    return !authorized || !show.timeline ? (
        <Forbidden />
    ) : (
        <Stack spacing={3}>
            <PaperCard xs={12} title='Live Reporting' variant='h2' align='center'>
                <FlexBox mt={2}>
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={4}>
                            <LiveAnnotationList
                                formName={form?.name}
                                comments={liveComments}
                                onSelect={setSelectedComment}
                                selectedId={selectedComment?.id}
                                seeker={seeker}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            {selectedComment && <LiveForm form={form} selectedComment={selectedComment} />}
                        </Grid>
                    </Grid>
                </FlexBox>
            </PaperCard>
            {show.video && !unavailable && (
                <PaperCard xs={12}>
                    <VideoOrStream streaming={streaming} seeker={seeker} setUnavailable={setUnavailable} />
                </PaperCard>
            )}
            {unavailable && !streaming && <VideoUploadProgressCard />}
            {show.comments && (
                <PaperCard xs={12} title='Comments' align='center'>
                    <CommentSection {...userInfo} />
                </PaperCard>
            )}
        </Stack>
    );
}
