import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { Button, Paper, Typography } from '@mui/material';

export default function Forbidden() {
    const navigate = useNavigate();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, margin: 'auto', flexGrow: 1 }}>
                    <Typography variant='h4' align='center'>
                        Forbidden
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, margin: 'auto', flexGrow: 1 }}>
                    <Typography>
                        Sorry, but unfortunately you are not authorized to see this. If you think you should, please
                        contact an admin.
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Button color='secondary' variant='outlined' onClick={() => navigate(-1)}>
                    Go back
                </Button>
            </Grid>
        </Grid>
    );
}
