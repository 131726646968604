import { IconButton, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function DateWidget({ field, control, setValue }) {
    return (
        <Controller
            name={field.name}
            control={control}
            render={(props) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label={field.label}
                        inputFormat='dd/MM/yyyy'
                        renderInput={(params) => <TextField {...params} />}
                        InputProps={{
                            startAdornment: props.field.value && (
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setValue(field.name, null, { shouldValidate: true, shouldDirty: true });
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        {...props.field}
                    />
                </LocalizationProvider>
            )}
        />
    );
}
