import { Divider, Grid, Paper, Typography } from '@mui/material';
import SummaryMain from '../components/summary/SummaryMain';
import Forbidden from '../components/Login/Forbidden';
import useShow from '../aceapi/hooks/useShow';

export default function ProcedureSummary(props) {
    const show = useShow();

    return !show.summary ? (
        <Forbidden />
    ) : (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper>
                    <Typography variant='h3' align='center'>
                        Summary
                    </Typography>
                    <Divider />
                    <SummaryMain {...props} />
                </Paper>
            </Grid>
        </Grid>
    );
}
