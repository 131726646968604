import { useCallback, useEffect, useState } from 'react';
import * as d3 from 'd3';
import useD3 from '../d3/useD3';
import { useParams } from 'react-router-dom';
import useProcTime from '../../../aceapi/hooks/useProcTime';
import { useAceApp } from '../../Menu/ReportAppSelector';
import { useVideoRead } from '../../../aceapi/aceComponents';

export default function TimelineCursor(props) {
    const { seeker, layout, height, demiSpace, ...rest } = props;

    const { width, axisHeight, padding } = layout;

    const { top: pt, right: pr, bottom: pb, left: pl } = padding;

    const { app } = useAceApp();
    const { uuid } = useParams();
    const time = useProcTime();
    const { data: video } = useVideoRead({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });
    const positionRatio = useCallback((t) => (t - time.start) / (time.end - time.start), [time.end, time.start]);
    const videoStart = video?.video?.start ?? 0;
    const videoEnd = video?.video?.end ?? 0;
    const startPos = positionRatio(videoStart) * width;
    const endPos = positionRatio(videoEnd) * width;
    const timestamp = seeker.timestamp.state;

    const [cursorPos, setCursorPos] = useState(startPos);
    const [mouseDown, setMouseDown] = useState(false);

    const handleDrag = (x) => {
        setCursorPos(x);
        const newTimestamp = (x / width) * (time.end - time.start) + time.start;
        seeker.seekTo(newTimestamp);
    };

    const drawCursor = (chart) => {
        // Make a draggable cursor to seek the video, it should be a red line with a triangle on top pointing down (grouping a line and a polygon)
        const cursor = chart
            .attr('viewBox', [0, 0, width + pl + pr, height + pt + pb])
            .attr('pointer-events', 'none')
            .append('g')
            .attr('transform', `translate(${pl}, ${pt - demiSpace})`)
            .attr('cursor', 'pointer')
            .attr('pointer-events', 'all');
        cursor
            .append('line')
            .attr('x1', cursorPos)
            .attr('y1', 0)
            .attr('x2', cursorPos)
            .attr('y2', height - axisHeight)
            .attr('stroke', 'red')
            .attr('stroke-width', 2);
        cursor
            .append('polygon')
            .attr('points', `${cursorPos - 8},${-12} ${cursorPos + 8},${-12} ${cursorPos},${2}`)
            .attr('fill', 'red');
        // add a listener to the cursor to update the position when the user drags it
        cursor.call(
            d3
                .drag()
                .on('drag', (event) => {
                    // clamp between startPos and endPos
                    const x = Math.min(Math.max(event.x - pl, startPos), endPos);
                    handleDrag(x);
                    cursor.select('line').attr('x1', x).attr('x2', x);
                    cursor.select('polygon').attr('points', `${x - 8},${-12} ${x + 8},${-12} ${x},${2}`);
                })
                .on('start', () => setMouseDown(true))
                .on('end', () => setMouseDown(false)),
        );
        // add static lines to show the start and end of the video
        cursor
            .append('line')
            .attr('x1', startPos)
            .attr('y1', 0)
            .attr('x2', startPos)
            .attr('y2', height - axisHeight)
            .attr('stroke', 'red')
            .attr('stroke-width', 1)
            .attr('stroke-dasharray', '4,4');
        cursor
            .append('line')
            .attr('x1', endPos)
            .attr('y1', 0)
            .attr('x2', endPos)
            .attr('y2', height - axisHeight)
            .attr('stroke', 'red')
            .attr('stroke-width', 1)
            .attr('stroke-dasharray', '4,4');
    };

    useEffect(() => {
        if (!mouseDown) setCursorPos(positionRatio(timestamp ?? videoStart) * width);
    }, [mouseDown, positionRatio, timestamp, videoStart, width]);

    const ref = useD3(
        (chart) => {
            drawCursor(chart);
            return () => chart.selectAll('*').remove();
        },
        [cursorPos],
    );

    return <svg ref={ref} {...rest} />;
}
