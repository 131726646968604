import { AsyncBoundary } from '../../aceapi/utils';
import { Divider, List, Stack } from '@mui/material';
import Comment, { PlaceHolderComment } from './Comment';
import CommentForm from './CommentForm';
import { useState } from 'react';
import StringSelector from '../summary/widgets/StringSelector';
import { useParams } from 'react-router-dom';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useProceduresComments } from '../../aceapi/aceComponents';

const sortOptions = [
    { key: 'default', name: 'Default (recommended)', value: 0 },
    { key: 'newest', name: 'Newest first (both root and replies)', value: 1 },
    { key: 'oldest', name: 'Oldest first (both root and replies)', value: 2 },
];

const dateComp = (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime();

const sortOptionFuncs = [
    // [rootSortOptionFunc, repliesSortOptionFunc],
    [(a, b) => dateComp(b, a), (a, b) => dateComp(a, b)],
    [(a, b) => dateComp(b, a), (a, b) => dateComp(b, a)],
    [(a, b) => dateComp(a, b), (a, b) => dateComp(a, b)],
];

function AsyncCommentSection({ sortMode, ...userInfo }) {
    const { app } = useAceApp();
    const { uuid } = useParams();

    const { data: comments } = useProceduresComments(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        {
            refetchInterval: 10000,
        },
    );

    const rootComments = comments.filter((c) => c.parent === null).sort(sortOptionFuncs[sortMode][0]);
    const getReplies = (c) => comments.filter((r) => r.parent === c.id).sort(sortOptionFuncs[sortMode][1]);
    return (
        <List>
            {rootComments.map((comment) => (
                <div key={comment.id}>
                    <Comment comment={comment} {...userInfo} getReplies={getReplies} />
                    <Divider />
                </div>
            ))}
        </List>
    );
}

export default function CommentSection(props) {
    const [sortMode, setSortMode] = useState(0);

    return (
        <>
            <CommentForm root />
            <StringSelector
                value={sortMode}
                onChange={setSortMode}
                options={sortOptions}
                extraInputProps={{
                    variant: 'standard',
                    label: 'Sort mode',
                    helperText: 'Default is newest first for root comments but oldest first for replies',
                }}
            />
            <AsyncBoundary
                fallback={
                    <Stack spacing={3}>
                        {Array(3)
                            .fill()
                            .map((_, i) => (
                                <PlaceHolderComment key={i} seed={i} />
                            ))}
                    </Stack>
                }
            >
                <AsyncCommentSection {...props} sortMode={sortMode} />
            </AsyncBoundary>
        </>
    );
}
