import { useContext, useRef } from 'react';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Link, Stack, TextField } from '@mui/material';
import { TokenContext } from '../../config/contexts';

export default function LoginForm() {
    const token = useContext(TokenContext);
    const navigate = useNavigate();
    const location = useLocation();
    const formUsername = useRef();
    const formPassword = useRef();

    const { from } = location.state || { from: { pathname: '/' } };

    const handle_login = (e) => {
        e.preventDefault();
        token
            .login(formUsername.current.value, formPassword.current.value)
            .then(() => {
                navigate(from);
            })
            .catch((reason) => {
                alert(reason);
            });
    };

    return (
        <form onSubmit={handle_login}>
            <Grid container spacing={2} justifyContent='center' alignItems='center'>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        inputRef={formUsername}
                        label='Username'
                        name='username'
                        size='small'
                        variant='outlined'
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        inputRef={formPassword}
                        label='Password'
                        name='password'
                        size='small'
                        type='password'
                        variant='outlined'
                    />
                </Grid>
                <Grid item xs={8}>
                    <Button color='secondary' fullWidth type='submit' variant='contained'>
                        Log in
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction='row' justifyContent='space-evenly'>
                        <Link href='mailto:baptisteparsy@odin-vision.com?subject=Report%20Account%20Request'>
                            Request an account
                        </Link>
                        <Link href='/account/password_reset'>Forgot password?</Link>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    );
}
