import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgSummaryList } from '../../../../aceapi/aceComponents';
import { useMemo, useState } from 'react';
import ReportLineChart from '../../../Charts/stats/ReportLineChart';
import { timedelta } from '../../../summary/utils';
import { Stack, Switch, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';

export default function GGEndoscopistTrends() {
    const { app } = useAceApp();
    const { data: summary } = useGgSummaryList({ queryParams: { app } });

    const [arm, setArm] = useState();
    const [focusPlot, setFocusPlot] = useState();
    const [withSeniority, setWithSeniority] = useState(false);

    const plotCfg = useMemo(() => {
        let entries = Object.entries(summary.form_metrics.endoscopist_weekly_trend).map(([endoscopist, data]) => ({
            id: endoscopist,
            name: endoscopist,
            seniority: data?.seniority,
            data: data?.weekly_data ?? [],
        }));

        if (withSeniority) {
            entries = entries
                .map((entry) => ({
                    ...entry,
                    id: entry.seniority,
                    name: entry.seniority,
                }))
                .reduce((acc, val) => {
                    const existing = acc.find((x) => x.id === val.id);
                    if (existing) {
                        existing.data = existing.data.concat(val.data);
                    } else {
                        acc.push(val);
                    }
                    return acc;
                }, []);
        }

        return entries.sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }));
    }, [summary.form_metrics.endoscopist_weekly_trend, withSeniority]);

    const all_plots = useMemo(
        () =>
            (withSeniority
                ? plotCfg.map((cfg) => cfg.seniority).filter((e) => e)
                : plotCfg.map((cfg) => cfg.name)
            ).sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' })),
        [plotCfg, withSeniority],
    );

    const chartData = useMemo(
        () =>
            plotCfg
                .map((cfg) =>
                    cfg.data.map((e) => ({
                        time: Date.parse(e.date),
                        [cfg.name]: arm ? e[arm] : e.Total,
                    })),
                )
                .reduce((acc, val) => {
                    val.forEach((e) => {
                        const existing = acc.find((x) => x.time === e.time);
                        if (existing) {
                            Object.assign(existing, e);
                        } else {
                            all_plots.forEach((endoscopist) => {
                                if (!e[endoscopist]) {
                                    e[endoscopist] = 0;
                                }
                            });
                            acc.push(e);
                        }
                    });
                    return acc;
                }, []),
        [all_plots, arm, plotCfg],
    );

    return (
        <Stack spacing={2}>
            <Typography variant='h5'>Endoscopist Completed Colonoscopy Rates</Typography>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Typography>Arm: </Typography>
                <TextField value={arm} onChange={(e) => setArm(e.target.value)} select SelectProps={{ native: true }}>
                    <option value=''>All</option>
                    {['AI Arm', 'Non-AI Arm'].map((a) => (
                        <option key={a} value={a}>
                            {a}
                        </option>
                    ))}
                </TextField>
                <Typography>Seniority: </Typography>
                <Switch
                    checked={withSeniority}
                    onChange={(e) => {
                        setWithSeniority(e.target.checked);
                        setFocusPlot();
                    }}
                />
                <Typography>Focus: </Typography>
                <TextField
                    value={focusPlot}
                    onChange={(e) => setFocusPlot(e.target.value)}
                    select
                    SelectProps={{ native: true }}
                >
                    <option value=''>All</option>
                    {all_plots.map((a) => (
                        <option key={a} value={a}>
                            {a}
                        </option>
                    ))}
                </TextField>
            </Stack>
            <ReportLineChart
                formatted={chartData}
                groups={plotCfg}
                period={timedelta.WEEK}
                showStartOnly
                lineType='linear'
                focus={focusPlot}
            />
        </Stack>
    );
}
