import useKeyBoardEvent from '../events/useKeyBoardEvent';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProceduresDetections, useProceduresDetectionsPolygon } from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

/**
 * Hook for handling keyboard navigation for video player
 * It includes keybindings for navigating by amount of frames, skipping to next detection, and removing detections
 * @param seeker - the seeker object from useVideoSeeker
 * @returns {{numberOfDetections: number}} - the number of detections in the video
 */
export default function useVideoKeyNav(seeker) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const [ignoredDetections, setIgnoredDetections] = useState(new Set());
    const [bookmarkTimestamp, setbookmarkTimestamp] = useState(0);

    // Predictions raw is an array of dictionaries, which can have duplicate entries for the 'track' key. Get only one of each value
    const { data: box_detections } = useProceduresDetections(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { suspense: false },
    );
    const { data: poly_detections } = useProceduresDetectionsPolygon(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { suspense: false },
    );
    const allDetections = useMemo(
        () => [...(box_detections ?? []), ...(poly_detections ?? [])],
        [box_detections, poly_detections],
    );

    const uniqueDetections = useMemo(
        () =>
            allDetections.reduce(
                (acc, cur) => {
                    if (acc.tracks.has(cur.track)) {
                        return acc;
                    } else {
                        acc.tracks.add(cur.track);
                        acc.data.push(cur);
                        return acc;
                    }
                },
                { tracks: new Set(), data: [] },
            ).data,
        [allDetections],
    );

    const detections = useMemo(
        () =>
            uniqueDetections
                .filter((detection) => !ignoredDetections.has(detection.timestamp))
                .sort((a, b) => a.timestamp - b.timestamp),
        [uniqueDetections, ignoredDetections],
    );

    const skipToNextDetection = (skipForward) => {
        // find the closest timestamp in uniqueDetections that is greater than x
        const currentTimestamp = seeker.timestamp.state;
        let closestTimestamp = null;

        if (skipForward) {
            for (let i = detections.length - 1; i >= 0; i--) {
                const timestamp = detections[i].timestamp;
                if (timestamp > currentTimestamp && (closestTimestamp === null || timestamp < closestTimestamp)) {
                    closestTimestamp = timestamp;
                }
            }
        } else {
            for (let i = 0; i < detections.length; i++) {
                const timestamp = detections[i].timestamp;
                if (timestamp < currentTimestamp && (closestTimestamp === null || timestamp > closestTimestamp)) {
                    closestTimestamp = timestamp;
                }
            }
        }
        seeker.seekTo(closestTimestamp);
    };

    const removeDetection = () => {
        const currentTimestamp = seeker.timestamp.state;

        // Check if there is an entry in uniqueDetections which has the same timestamp as the current timestamp
        if (!uniqueDetections.some((entry) => entry.timestamp === currentTimestamp)) {
            console.log('No detection at this timestamp');
            return;
        }

        setIgnoredDetections((prevDetections) => {
            prevDetections.add(currentTimestamp);
            return prevDetections;
        });

        skipToNextDetection(true);
    };

    useKeyBoardEvent({
        watchKeys: ['ArrowLeft'],
        shiftKey: true,
        onKeyDown: () => seeker.frameJump(-10),
    });

    useKeyBoardEvent({
        watchKeys: ['ArrowLeft'],
        onKeyDown: () => seeker.frameJump(-1),
    });

    useKeyBoardEvent({
        watchKeys: ['ArrowRight'],
        onKeyDown: () => seeker.frameJump(1),
    });

    useKeyBoardEvent({
        watchKeys: ['ArrowRight'],
        shiftKey: true,
        onKeyDown: () => seeker.frameJump(10),
    });

    useKeyBoardEvent({
        watchKeys: ['ArrowRight'],
        ctrlKey: true,
        onKeyDown: () => seeker.frameJump(100),
    });

    useKeyBoardEvent({
        watchKeys: ['u'],
        onKeyDown: () => skipToNextDetection(true),
    });

    useKeyBoardEvent({
        watchKeys: ['y'],
        onKeyDown: () => skipToNextDetection(false),
    });

    useKeyBoardEvent({
        watchKeys: ['x'],
        onKeyDown: () => removeDetection(),
    });

    useKeyBoardEvent({
        watchKeys: ['b'],
        onKeyDown: () => {
            setbookmarkTimestamp(seeker.timestamp.state);
        },
    });

    useKeyBoardEvent({
        watchKeys: ['n'],
        onKeyDown: () => {
            seeker.seekTo(bookmarkTimestamp);
        },
    });

    return {
        numberOfDetections: uniqueDetections.length - ignoredDetections.size,
    };
}
