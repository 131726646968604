import { AsyncBoundary } from '../../aceapi/utils';
import { Box, LinearProgress, Skeleton, Stack, Typography } from '@mui/material';
import PaperCard from '../report/PaperCard';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useParams } from 'react-router-dom';
import { useProceduresRecordings, useVideoRead } from '../../aceapi/aceComponents';
import useProcTime from '../../aceapi/hooks/useProcTime';
import FlexBox from '../report/FlexBox';
import { timedelta } from '../summary/utils';

function AsyncVideoUploadProgressCard() {
    const { app } = useAceApp();
    const { uuid } = useParams();

    const { end } = useProcTime();
    const refetchInterval = Date.now() - end < timedelta.WEEK ? 1000 : false;
    const { data: video } = useVideoRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { refetchInterval },
    );
    const { data: recordings } = useProceduresRecordings({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });

    const start = recordings?.start;
    const duration = (end - start) / 1000;
    // chunks are approximately 2s long each
    const chunk_count = video?.video?.chunk_count ?? 0;
    const status = video?.video?.reason ?? 'unknown';

    const statusMessage =
        {
            OK: 'video is ready for viewing (you should not see this message)',
            NODATA: recordings
                ? 'a recording was triggered but no chunks have been uploaded, it is possible that it was disabled'
                : 'this procedure has not been recorded',
            INCOMPLETE: 'chunks are partially uploaded',
            UPLOADED: 'all chunks are uploaded and are awaiting processing (or cannot be processed)',
            UNKNOWN: 'unknown video status',
        }?.[status.toUpperCase()] ?? status;

    const progress = Math.min(100, Math.max(0, Math.round((100 * chunk_count) / (duration / 2))));

    return (
        <Stack spacing={1} mt={1}>
            <Typography variant='body1'>
                <b>Status: </b>
                {statusMessage}
            </Typography>
            {status === 'INCOMPLETE' && (
                <FlexBox alignItems='center'>
                    <Box width='100%'>
                        <LinearProgress variant='determinate' value={progress} color='warning' />
                    </Box>
                    <Box minWidth={56} ml={1}>
                        <Typography variant='body2'>{progress}%</Typography>
                    </Box>
                </FlexBox>
            )}
        </Stack>
    );
}

export default function VideoUploadProgressCard() {
    return (
        <PaperCard title='Video Upload Status'>
            <AsyncBoundary
                fallback={
                    <Typography>
                        <Skeleton animation='wave' />
                    </Typography>
                }
            >
                <AsyncVideoUploadProgressCard />
            </AsyncBoundary>
        </PaperCard>
    );
}
