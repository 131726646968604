import { useParams } from 'react-router-dom';
import { hslGradient } from './utils';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useGraphRead } from '../../aceapi/aceComponents';

export default function useVMGradient(params) {
    const { show = false, sampling = 250 } = params;

    const { app } = useAceApp();
    const { uuid } = useParams();

    const { data: vmdata } = useGraphRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: {
                app,
                plot: 'visible_mucosa',
                sampling,
                aggregate: 'mean',
            },
        },
        { enabled: show },
    );
    const datapoints = vmdata?.graph_points?.vals ?? [];
    return datapoints.map((v) => hslGradient(v / 100));
}
