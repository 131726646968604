import { useParams } from 'react-router-dom';
import { useAceApp } from '../../components/Menu/ReportAppSelector';
import { useAuthorizedRead } from '../aceComponents';

export default function useAuthorized(overrideApp, overrideUuid) {
    const ace = useAceApp();
    const params = useParams();
    const app = overrideApp ?? ace.app;
    const uuid = overrideUuid ?? params.uuid;
    const { data: auth, error } = useAuthorizedRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app: app },
        },
        { useErrorBoundary: false, retry: false },
    );

    if (!overrideApp && error && error.stack.detail === 'Not found.') {
        console.log('Not found, setting app to auto');
        ace.setApp('auto');
    }

    if (!overrideApp && auth?.database && auth.database !== ace.app) {
        console.log('Setting app to', auth.database);
        ace.setApp(auth.database);
    }

    return auth?.authorized ?? false;
}
