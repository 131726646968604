import { AsyncBoundary, CustomErrorBoundary } from '../../aceapi/utils';
import TabPerTypes from './TabPerTypes';
import { Box, Divider, MenuItem, Stack, TextField, Typography } from '@mui/material';
import ProcedureCountStat from './stats/ProcedureCountStat';
import ProcedureTrendStat from './stats/ProcedureTrendStat';
import { useState } from 'react';
import InactivityTracker from './stats/InactivityTracker';
import ConfirmButton from '../dialogs/ConfirmButton';
import SummaryDownload from './SummaryDownload';
import FakeProgressBar from '../placeholders/FakeProgressBar';
import SummaryUpdate from './SummaryUpdate';
import ForceSelectApp from '../report/ForceSelectApp';

const stats = [
    { name: 'Procedure counts', component: ProcedureCountStat },
    { name: 'Procedure trends', component: ProcedureTrendStat },
    { name: 'Inactivity tracker', component: InactivityTracker },
];

const resetOptions = (reload) => {
    localStorage.removeItem('summaryView');
    localStorage.removeItem('summaryTab');
    localStorage.removeItem('summaryOptions');
    localStorage.removeItem('stSortModel');
    localStorage.removeItem('stPage');
    localStorage.removeItem('stExp');
    console.log('Summary options were reset after error as a safety measure');
    if (reload) window.location.reload();
};

function SafeSummaryMain({ pin }) {
    const [stat, setStat] = useState(() => localStorage.getItem('summaryView') || 'Procedure counts');

    const handleSummaryViewChange = (e) => {
        const val = e.target.value;
        localStorage.setItem('summaryView', val);
        setStat(val);
    };

    return (
        <Stack m={2} spacing={2}>
            <Stack spacing={1} direction='row' alignItems='center' justifyContent='center'>
                <Typography variant='h6'>Type: </Typography>
                <TextField select value={stat} onChange={handleSummaryViewChange}>
                    {stats.map((s) => (
                        <MenuItem key={s.name} value={s.name}>
                            {s.name}
                        </MenuItem>
                    ))}
                </TextField>
                <SummaryDownload />
                <ConfirmButton
                    onConfirm={() => resetOptions(true)}
                    variant='outlined'
                    action='reset all options and views to their defaults'
                >
                    Reset All To Defaults
                </ConfirmButton>
            </Stack>
            <SummaryUpdate />
            <Divider />
            <AsyncBoundary
                fallback={
                    <Box p={1}>
                        <Typography variant='h6'>Loading summary... This should take a few seconds.</Typography>
                        <FakeProgressBar step={0.05} />
                    </Box>
                }
            >
                <TabPerTypes name={stat} pin={pin} component={stats.find((s) => s.name === stat).component} />
            </AsyncBoundary>
        </Stack>
    );
}

export default function SummaryMain(props) {
    return (
        <CustomErrorBoundary errorCallback={() => resetOptions(false)}>
            <ForceSelectApp>
                <SafeSummaryMain {...props} />
            </ForceSelectApp>
        </CustomErrorBoundary>
    );
}
