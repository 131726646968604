import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    useProceduresRequestDetection,
    useProceduresRequestDiagnosis,
    useProceduresRequestFreezeFrame,
    useProceduresRequestNbiDetection,
} from '../../aceapi/aceComponents';
import { useQueryClient } from '@tanstack/react-query';
import { useAceApp } from '../Menu/ReportAppSelector';

export default function useMouse({ timestamp }) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const queryClient = useQueryClient();

    const [mode, setMode] = useState(null);
    const [box, setBox] = useState(null);

    const { mutateAsync: requestDetection } = useProceduresRequestDetection();
    const { mutateAsync: requestDiagnosis } = useProceduresRequestDiagnosis();
    const { mutateAsync: requestFreezeFrame } = useProceduresRequestFreezeFrame();
    const { mutateAsync: requestNbiDetection } = useProceduresRequestNbiDetection();

    const actionMap = useMemo(
        () => ({
            detection: requestDetection,
            diagnosis: requestDiagnosis,
            freeze_frame: requestFreezeFrame,
            nbi_detection: requestNbiDetection,
        }),
        [requestDetection, requestDiagnosis, requestFreezeFrame, requestNbiDetection],
    );

    const mouseRequest = useCallback(
        (action, extra, frameAction = true) => {
            const args = {
                ...(frameAction ? { timestamp } : {}),
                ...extra,
            };
            const actionFunc = actionMap?.[action];
            if (!actionFunc) {
                alert(`Unrecognized action: ${action}`);
                throw new Error(`Unrecognized action: ${action}`);
            }
            actionFunc({ pathParams: { procedureId: uuid }, body: args, queryParams: { app } })
                .then(async () => {
                    await queryClient.invalidateQueries({ queryKey: ['notifications'] });
                })
                .catch((err) => console.error(err));
        },
        [actionMap, app, queryClient, timestamp, uuid],
    );

    useEffect(() => {
        if (typeof mode === 'string' && mode !== 'draw') {
            if (mode === 'submit') {
                if (!box) {
                    alert('No diagnosis box selected');
                } else {
                    mouseRequest('diagnosis', { box });
                }
            }
            setMode(null);
            setBox(null);
        }
    }, [box, mode, mouseRequest]);

    return {
        request: mouseRequest,
        timestamp: timestamp,
        mode: {
            state: mode,
            setState: setMode,
        },
        box: {
            state: box,
            setState: setBox,
        },
    };
}
