import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExploreList } from '../../../../aceapi/aceComponents';
import { diminutiveRectosigmoid, MetricsGrid, quantitativeMetricsAgainstHistology } from './OpticalDiagnosisUtils';
import Typography from '@mui/material/Typography';

const POLYP_FILTERS = [
    { name: 'All', filter: undefined },
    // { name: 'Non-Rectosigmoid', filter: (polyp) => isRectosigmoid(polyp.site) },
    // { name: 'Rectosigmoid', filter: (polyp) => !isRectosigmoid(polyp.site) },
    { name: 'Non-Rectosigmoid', filter: (polyp) => polyp.in_rectosigmoid === 'no' },
    { name: 'Rectosigmoid', filter: (polyp) => polyp.in_rectosigmoid === 'yes' },
    {
        name: 'Diminutive Rectosigmoid',
        // filter: (polyp) => isRectosigmoid(polyp.site) && isDiminutive(polyp.size),
        filter: (polyp) => diminutiveRectosigmoid(polyp),
    },
];
const METRICS_COLS = [
    { field: 'Metric', headerName: 'Metric', flex: 0.5 },
    { field: 'All', headerName: 'All', flex: 0.5 },
    { field: 'Non-Rectosigmoid', headerName: 'Non-Rectosigmoid', flex: 0.5 },
    { field: 'Rectosigmoid', headerName: 'Rectosigmoid', flex: 0.5 },
    { field: 'Diminutive Rectosigmoid', headerName: 'Diminutive Rectosigmoid', flex: 0.5 },
].map((col) => ({
    ...col,
    valueFormatter: (params) => {
        const value = params.value;
        if (typeof value === 'number' && value % 1 !== 0) return (Math.round(value * 100) / 100).toFixed(2);
        return value;
    },
}));
const ROWS_COMPUTE = [
    {
        name: 'True positives',
        compute: ({ tp }) => tp,
    },
    {
        name: 'False positives',
        compute: ({ fp }) => fp,
    },
    {
        name: 'True negatives',
        compute: ({ tn }) => tn,
    },
    {
        name: 'False negatives',
        compute: ({ fn }) => fn,
    },
    {
        name: 'Total',
        compute: ({ total }) => total,
    },
    {
        name: 'Sensitivity',
        compute: ({ tp, fn }) => tp / (tp + fn),
    },
    {
        name: 'Specificity',
        compute: ({ tn, fp }) => tn / (tn + fp),
    },
    {
        name: 'Accuracy',
        compute: ({ tp, tn, total }) => (tp + tn) / total,
    },
];

/**
 * Compare Second decision to 'resect or leave in' (ie get_diagnosis_data's .second_decision) to histology
 */
export function QuantitativeMetrics() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });
    const rows = [];
    ROWS_COMPUTE.map(({ name, compute }, id) => {
        const row = { id, Metric: name };
        POLYP_FILTERS.forEach(({ name, filter }) => {
            row[name] = compute(
                quantitativeMetricsAgainstHistology(
                    explore.all,
                    (polyp) => polyp.second_decision,
                    'resect',
                    'leave in',
                    filter,
                ),
            );
        });
        rows.push(row);
    });
    return (
        <>
            <Typography variant='h4'>Quantitative metrics</Typography>
            <MetricsGrid name='Overall' rows={rows} columns={METRICS_COLS} />;
        </>
    );
}
