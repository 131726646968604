import { useRef } from 'react';

export default function DrawLines({ lines, boxOffset }) {
    const self = useRef();
    const width = self.current?.clientWidth ?? 0;
    const height = self.current?.clientHeight ?? 0;

    return (
        <div
            style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 15, pointerEvents: 'none' }}
            ref={self}
        >
            <svg xmlns='http://www.w3.org/2000/svg' id='svg-lines' style={{ width: '100%', height: '100%' }}>
                <defs />
                {lines
                    .filter(
                        (line) =>
                            line.src.x >= 0 &&
                            line.src.x <= width &&
                            line.src.y >= (line.isTop ? 0 : boxOffset) &&
                            line.src.y <= height,
                    )
                    .map((line) => (
                        <line
                            key={line.key}
                            x1={line.src.x}
                            y1={line.src.y}
                            x2={line.dst.x}
                            y2={line.dst.y + boxOffset}
                            stroke='black'
                        />
                    ))}
            </svg>
        </div>
    );
}
