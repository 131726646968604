import { useCallback, useEffect, useRef } from 'react';

const Canvas = ({ frame, others = [], polygon, ...props }) => {
    const shape = polygon ? frame.detection_polygon : frame.detection;
    const canvasRef = useRef(null);

    const draw = useCallback(
        (canvas, ctx) => {
            const image = new Image();
            image.onload = () => {
                const ar = image.width / image.height;
                let dw = 0;
                let dh = 0;
                let imw = canvas.width;
                let imh = canvas.height;
                if (ar > 1) {
                    imh = canvas.width / ar;
                    dh = (canvas.height - imh) / 2;
                } else if (ar < 1) {
                    imw = canvas.height * ar;
                    dw = (canvas.width - imw) / 2;
                }

                const get_x = (x) => (x * imw) / 512 + dw;
                const get_y = (y) => (y * imh) / 512 + dh;

                const coords = polygon
                    ? shape.coordinates?.map((c) => ({ x: get_x(c[0]), y: get_y(c[1]) })) ?? []
                    : [get_x(shape.x1), get_y(shape.y1), get_x(shape.x2), get_y(shape.y2)];

                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.strokeStyle = '#0000ff';
                others
                    .map((other) => other?.detection)
                    .forEach((otherbox) => {
                        if (otherbox) {
                            const x1 = get_x(otherbox.x1);
                            const y1 = get_y(otherbox.y1);
                            const x2 = get_x(otherbox.x2);
                            const y2 = get_y(otherbox.y2);
                            ctx.beginPath();
                            ctx.rect(x1, y1, x2 - x1, y2 - y1);
                            ctx.closePath();
                            ctx.stroke();
                        }
                    });
                ctx.strokeStyle = '#00ff00';
                ctx.beginPath();
                if (polygon) {
                    for (const c of coords) ctx.lineTo(c.x, c.y);
                } else ctx.rect(coords[0], coords[1], coords[2] - coords[0], coords[3] - coords[1]);
                ctx.closePath();
                ctx.stroke();
                // ctx.rect(get_x(0), get_y(0), get_x(512) - get_x(0), get_y(512) - get_y(0));
            };

            const imgUrl = polygon ? frame.url : frame.full_frame?.url;
            if (imgUrl) image.src = imgUrl;
        },
        [polygon, frame.url, frame.full_frame?.url, shape, others],
    );

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        draw(canvas, context);
    }, [draw]);

    return <canvas ref={canvasRef} {...props} />;
};

export default function BoundedFrame({ frame, others, resolution = '100%', polygon = false }) {
    return (
        <Canvas
            frame={frame}
            others={others}
            width={resolution}
            height={resolution}
            polygon={polygon}
            style={{
                zIndex: 1,
                position: 'relative',
                width: '100%',
                height: '100%',
                top: 0,
                right: 0,
            }}
        />
    );
}
