import { Skeleton, Typography } from '@mui/material';
import useProcCaecum from '../../aceapi/hooks/useProcCaecum';
import { AsyncBoundary } from '../../aceapi/utils';
import PaperCard from './PaperCard';

function AsyncCaecumCard() {
    const caecum = useProcCaecum();
    return (
        <>
            <Typography align='left'>
                {caecum.timestamp ? `Reached at ${new Date(caecum.timestamp).toLocaleTimeString()}` : 'Not reached'}
            </Typography>
            {caecum.withdrawalTime && <Typography align='left'>Withdrawal time: {caecum.withdrawalTime}</Typography>}
        </>
    );
}

export default function CaecumCard() {
    return (
        <PaperCard title='Caecum'>
            <AsyncBoundary
                fallback={
                    <Typography variant='h5'>
                        <Skeleton animation='wave' />
                    </Typography>
                }
            >
                <AsyncCaecumCard />
            </AsyncBoundary>
        </PaperCard>
    );
}
