import React from 'react';
import PaperCard from '../../report/PaperCard';
import { Typography } from '@mui/material';

export default function GGAuthErrorBox() {
    return (
        <PaperCard title={`Connection Error`} xs={12}>
            <Typography>No Greenlight Guru API Key and/or Study Id found, connection unavailable.</Typography>
        </PaperCard>
    );
}
