import PaperCard from '../report/PaperCard';
import { AsyncBoundary } from '../../aceapi/utils';
import { Box, List, ListItem, Skeleton, Stack, Typography } from '@mui/material';
import ConfirmButton from '../dialogs/ConfirmButton';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Grid from '@mui/material/Grid';
import { useSequentialTasks, useTrackedTask } from '../celery/useTrackedTask';
import {
    useProceduresRequestCatUploadCreate,
    useProceduresRequestCatUploadRead,
    useProceduresRequestVideoHashCreate,
    useProceduresRequestVideoHashRead,
    useVideoHashesRead,
} from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useQueryClient } from '@tanstack/react-query';

function CatVideoHash({ uuid, setHash, setDisplayName }) {
    const { app } = useAceApp();
    const { data: videoHash, isSuccess } = useVideoHashesRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { retry: false, useErrorBoundary: false },
    );

    useEffect(() => {
        if (isSuccess) {
            setHash(videoHash.hash);
            setDisplayName(videoHash.display_name);
        }
    }, [videoHash, setHash, setDisplayName, isSuccess]);

    return (
        <Stack>
            <Typography flexWrap='wrap' variant='body1' width='100%' sx={{ wordBreak: 'break-all' }}>
                <b>Video Hash</b>: {videoHash?.hash ?? 'Not computed'}
            </Typography>
            {videoHash?.display_name && (
                <Typography variant='body1' width='100%'>
                    <b>CAT PID</b>: {videoHash.display_name}
                </Typography>
            )}
        </Stack>
    );
}

function AsyncCatUploadCard({ uuid }) {
    const queryClient = useQueryClient();
    const [hash, setHash] = useState(null);
    const [displayName, setDisplayName] = useState(null);

    const { mutateAsync: videoHashStart } = useProceduresRequestVideoHashCreate();
    const { mutateAsync: catUploadStart } = useProceduresRequestCatUploadCreate();

    const hashTask = useTrackedTask({
        start: videoHashStart,
        useProgress: useProceduresRequestVideoHashRead,
        taskDescription: 'Computing video hash',
        progressAsPercent: true,
    });

    const uploadTask = useTrackedTask({
        start: catUploadStart,
        useProgress: useProceduresRequestCatUploadRead,
        taskDescription: 'Uploading video to CAT',
        progressAsPercent: true,
    });

    const allTasks = useSequentialTasks([hashTask, uploadTask]);

    useEffect(() => {
        if (
            (hashTask.completed && hash === null && !allTasks.running) ||
            (uploadTask.completed && displayName === null && !allTasks.running) ||
            (allTasks.completed && !hashTask.running && !uploadTask.running && displayName === null)
        ) {
            queryClient.invalidateQueries({ queryKey: ['video_hashes'] });
        }
    }, [
        allTasks.completed,
        allTasks.running,
        displayName,
        hash,
        hashTask.completed,
        hashTask.running,
        queryClient,
        uploadTask.completed,
        uploadTask.running,
        uuid,
    ]);

    return (
        <Stack direction='column' spacing={2}>
            <AsyncBoundary fallback={<Typography>Fetching Video hash...</Typography>}>
                <CatVideoHash uuid={uuid} setHash={setHash} setDisplayName={setDisplayName} />
            </AsyncBoundary>
            <Box>
                <Grid container spacing={2}>
                    {!hash && (
                        <Grid item>
                            <ConfirmButton
                                variant='contained'
                                color='secondary'
                                action='compute the video hash? This is an expensive operation and may take a while'
                                onConfirm={() => hashTask.run()}
                                ButtonType={LoadingButton}
                                loading={hashTask.running}
                                disabled={allTasks.running || uploadTask.running}
                                startIcon={<AutorenewIcon />}
                                loadingPosition='start'
                            >
                                Compute Video Hash
                            </ConfirmButton>
                        </Grid>
                    )}
                    {hash && !displayName && (
                        <Grid item>
                            <ConfirmButton
                                variant='contained'
                                color='secondary'
                                action='upload the video to CAT'
                                onConfirm={() => uploadTask.run()}
                                ButtonType={LoadingButton}
                                loading={uploadTask.running}
                                disabled={allTasks.running || hashTask.running}
                                startIcon={<CloudUploadIcon />}
                                loadingPosition='start'
                            >
                                Upload Video to CAT
                            </ConfirmButton>
                        </Grid>
                    )}
                    {!hash && !displayName && (
                        <Grid item>
                            <ConfirmButton
                                variant='contained'
                                action='compute the video hash and upload the video to CAT'
                                onConfirm={() => allTasks.run()}
                                ButtonType={LoadingButton}
                                loading={allTasks.running}
                                disabled={allTasks.completed || hashTask.running || uploadTask.running}
                                startIcon={<CloudUploadIcon />}
                                loadingPosition='start'
                            >
                                Compute Video Hash and Upload Video to CAT
                            </ConfirmButton>
                        </Grid>
                    )}
                </Grid>
            </Box>
            {allTasks.render()}
            {hashTask.render()}
            {uploadTask.render()}
        </Stack>
    );
}

export default function CatUploadCard(props) {
    return (
        <PaperCard title='CAT Upload' xs={8}>
            <AsyncBoundary
                fallback={
                    <List dense>
                        {Array(5)
                            .fill()
                            .map((_, i) => (
                                <ListItem key={i}>
                                    <Typography variant='body1' width='100%'>
                                        <Skeleton animation='wave' />
                                    </Typography>
                                </ListItem>
                            ))}
                    </List>
                }
            >
                <AsyncCatUploadCard {...props} />
            </AsyncBoundary>
        </PaperCard>
    );
}
