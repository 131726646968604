import { useViewList } from '../aceComponents';
import { useEffect } from 'react';

export function useCheckRights({ token }) {
    const { refetch: fetchView } = useViewList({}, { enabled: false, suspense: false });

    useEffect(() => {
        const parsedToken = token?.parse();
        if (parsedToken.exp) {
            const expiry = parsedToken.exp * 1000;
            console.log('Token expires at', new Date(expiry));
            fetchView({})
                .then(({ data }) => {
                    // Check if data.view contains the same components as the parsedToken.user.components (the order is not important)
                    const a1_set = new Set(data.view);
                    const a2_set = new Set(parsedToken.user.components);
                    if (a1_set.size !== a2_set.size) {
                        console.log('Token data does not match view data. Logging out.');
                        token?.logout();
                    } else {
                        for (const c of data.view) {
                            if (!a2_set.has(c)) {
                                console.log('Token data does not match view data. Logging out.');
                                token?.logout();
                                break;
                            }
                        }
                    }
                })
                .catch((err) => console.log(err));
        } else {
            console.log('No user logged in.');
            token?.logout(false);
        }
    }, [fetchView, token]);
}
