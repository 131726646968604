import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tab,
    Tabs,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { appContext, ComputeAnnotationContext } from '../../config/contexts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSourcesList } from '../../aceapi/aceComponents';

function MissingAnnotationsDialog({ open, onClose }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Ongoing task</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Missing annotations are being computed. Please wait before changing app.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose(true);
                    }}
                    color='primary'
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default function ReportAppSelector({ active }) {
    const { data: sources, isSuccess } = useSourcesList({}, { enabled: active, suspense: false });
    const [activeApp, setActiveApp] = useContext(appContext);
    const location = useLocation();
    const navigate = useNavigate();
    const hasUuid = location.pathname.match(/[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}/) !== null;
    const [openDialog, setOpenDialog] = useState(false);
    const { isComputingAnnotations } = useContext(ComputeAnnotationContext);
    const handleTabChange = (event, newValue) => {
        if (isComputingAnnotations) {
            setOpenDialog(true);
        } else {
            setActiveApp(newValue);
        }
    };
    const handleDialogClose = () => {
        setOpenDialog(false);
    };
    useEffect(() => {
        if (!sources) return;
        if (
            ![...sources.map((source) => source.nickname.toLowerCase()), 'all', 'auto'].includes(
                activeApp.toLowerCase(),
            )
        ) {
            navigate('/error/appForbidden');
        }
    }, [activeApp, navigate, sources]);

    return (
        <Box flexGrow={1} mr={8} minWidth={200} maxWidth={1000}>
            {isSuccess && (
                <Tabs
                    value={activeApp}
                    onChange={handleTabChange}
                    variant='scrollable'
                    scrollButtons='auto'
                    textColor='inherit'
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: 'lightblue',
                        },
                        '& .Mui-selected.Mui-disabled': {
                            backgroundColor: '#333333',
                            color: 'white',
                        },
                    }}
                >
                    <Tab key='all' label='all' value='all' disabled={hasUuid} />
                    {sources.map((source) => (
                        <Tab key={source.id} label={source.nickname} value={source.nickname} disabled={hasUuid} />
                    ))}
                </Tabs>
            )}
            <MissingAnnotationsDialog open={openDialog} onClose={handleDialogClose} />
        </Box>
    );
}

export function useAceApp() {
    const [app, setApp] = useContext(appContext);

    return {
        app,
        setApp,
    };
}
