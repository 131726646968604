import FrameBox from './FrameBox';
import { Stack, Typography } from '@mui/material';
import ConfirmButton from '../../dialogs/ConfirmButton';
import { useQueryClient } from '@tanstack/react-query';
import { useMouseDagrunsDelete } from '../../../aceapi/aceComponents';

export default function FrameZoom({ frame, frames }) {
    const queryClient = useQueryClient();

    const { mutateAsync: deleteDagRunAsync } = useMouseDagrunsDelete();

    const others = frames.filter(
        (f) =>
            frame?.full_frame?.value &&
            f?.full_frame?.value === frame?.full_frame?.value &&
            f?.frame_id !== frame?.frame_id,
    );

    const deleteDagRun = (id) => {
        deleteDagRunAsync({ pathParams: { id } })
            .then(() => {
                frame.diagnosis = 'Deleted';
                frame.url = null;
                queryClient.invalidateQueries({ queryKey: ['frames'] });
            })
            .catch((err) => {
                switch (err.status) {
                    case 403:
                        alert('You are not authorized to delete this frame');
                        break;
                    default:
                        console.error(err);
                }
            });
    };

    return (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Stack direction='column'>
                <Typography variant='h2' color='white' textAlign='center' bgcolor='primary.dark'>
                    {frame.diagnosis}
                </Typography>
                <Stack direction='row'>
                    <FrameBox
                        frame={frame}
                        others={others}
                        fullframe
                        resolution='400%'
                        width='40vw'
                        height='40vw'
                        borderThickness='0'
                    />
                    <FrameBox frame={frame} width='40vw' height='40vw' borderThickness='0' />
                </Stack>
                {frame.dagrun && (
                    <ConfirmButton
                        variant='contained'
                        color='error'
                        action='delete this mouse diagnosis'
                        onConfirm={() => deleteDagRun(frame.dagrun)}
                    >
                        Delete Mouse Diagnosis
                    </ConfirmButton>
                )}
            </Stack>
        </div>
    );
}
