import { Box, Grid } from '@mui/material';
import PaperCard from '../components/report/PaperCard';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useHospitalsList } from '../aceapi/aceComponents';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import ForceSelectApp from '../components/report/ForceSelectApp';

export default function HospitalDetail() {
    const { app } = useAceApp();
    const [sortModel, setSortModel] = useState([{ field: 'acronym', sort: 'asc' }]);
    const { data: hospitals } = useHospitalsList({ queryParams: { app } }, { enabled: app !== 'all' });

    const columns = [
        {
            field: 'acronym',
            headerName: 'Acronym',
            flex: 0.1,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.4,
        },
        {
            field: 'address',
            headerName: 'Address',
            flex: 0.6,
        },
    ];

    return (
        <Grid container spacing={3}>
            <PaperCard xs={12} title='Hospital Details' variant='h2' align='center'>
                <Box m={2}>
                    <ForceSelectApp>
                        <DataGrid
                            columns={columns}
                            rows={hospitals}
                            getRowId={(row) => row.id}
                            autoHeight
                            sortModel={sortModel}
                            onSortModelChange={setSortModel}
                        />
                    </ForceSelectApp>
                </Box>
            </PaperCard>
        </Grid>
    );
}
